/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-multi-str */
import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

const MyEditor = ({ onChange, initialValue, onEdit, contentValue, onInit, ...tinyMceProps }) => {
  const editorRef = useRef(null);
  // Fix GU-220
  // Set exact content on edit state change
  // https://wausolutions.atlassian.net/secure/RapidBoard.jspa?rapidView=9&projectKey=GU&modal=detail&selectedIssue=GU-220
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(contentValue);
    }
  }, [onEdit])
  return (
  <Editor
    // initialValue={initialValue}
    // eslint-disable-next-line no-return-assign
    onInit={(evt, editor) => editorRef.current = editor}
    {...tinyMceProps}
    apiKey='x7iljqh4m9dd7niy4lqcxjzf3iz5yt2t7gkw6qtgho6683br'
    init={{
      // height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor autoresize',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help',
      ],
      autoresize_bottom_margin: 5,
      branding: false,
      statusbar: false,
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help',
      setup(ed) {
        ed.on('init', (args) => {
          if (typeof onInit === 'function') {
            onInit(args);
          }
        });
      },
    }}
    onEditorChange={onChange}
  />
)};

MyEditor.defaultProps = {
  onEdit: false,
  initialValue: '',
  contentValue: '',
  onChange: (datas) => {},
  onInit: (datas) => {},
};

MyEditor.propTypes = {
  onEdit:  PropTypes.bool,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  contentValue: PropTypes.string,
  onInit: PropTypes.func,
};

export default MyEditor;
