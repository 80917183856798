import localStorage from 'local-storage';
import { get } from 'lodash-es';
import client, {
  setupClientAuth as setupHttpClient,
} from '../helpers/HttpClient';
import history from '../helpers/history';
import { USER_OBJECT } from '../constants/localStorage';

export const logout = () => {
  localStorage.clear();
  // update http client
  client.setDefaultHeader('Authorization', '');
  history.push('/');
};

export const login = (identifier, password, cb) =>
  client
    .post('auth/local', {
      identifier,
      password,
    })
    .then(({ data }) => {
      // only allow access to admin roles
      const role = get(data, 'user.role.name', 'NO_NAME');
      if (role !== 'Authenticated') {
        return cb(new Error('User is not authorized'), null);
      }

      // store credentials in localstorage
      localStorage.set(USER_OBJECT, {
        ...data,
        connected: true,
      });
      // update http client with jwt as bearer
      setupHttpClient();
      // client.setDefaultHeader('Authorization', `Bearer ${data.jwt}`);
      // Callback now that all is done
      cb(null, data);
      // change link
      return history.push('/dashboard/main');
    })
    .catch((error) => {
      cb(error, null);
    });

export const resetPassword = (email) =>
  client.post('auth/forgot-password', {
    email,
  });

export const setNewPassword = (code, password) =>
  client.post('auth/reset-password', {
    code,
    password,
    passwordConfirmation: password,
  });
