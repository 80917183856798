/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import { connect } from 'react-redux';
// import { forOwn } from 'lodash-es';
// import message from 'antd/es/message';
import Drawer from 'antd/es/drawer';
import Button from 'antd/es/button';

import history from '../../helpers/history';
import Api from '../../api/Methods';
import { loadCustomers as loadCustomersAction } from '../../redux/ActionsAndReducers/Customers';
import { actionFetch as loadStatisticsAction } from '../../redux/ActionsAndReducers/Statistics';
import { loadOrders as loadOrdersAction } from '../../redux/ActionsAndReducers/Orders';
import { loadInvoices as loadInvoicesAction } from '../../redux/ActionsAndReducers/Invoices';
import { toggleFilters } from '../../redux/ActionsAndReducers/Ui';
import PodioMenuItem from './PodioMenuItem';
import { hideSiderAction } from '../../redux/sider/actions';
import RealtimeHelper from '../../realtime/helpers';
import './styles.css';

const { Header, Content } = Layout;
const configs = Api.getConfigs();
const { logo } = configs;

const DashboardLayout = ({
  /* fieldUpdates, */ _toggleFilters,
  filtersShown,
  loadCustomers,
  loadStatistics,
  loadOrders,
  loadInvoices,
  children,
  location,
  hideSider,
  paginationFilters,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [locationState, setLocation] = useState(location);
  const prevLocationRef = useRef();
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    prevLocationRef.current = locationState;
  });
  const prevLocation = prevLocationRef.current;
  const reloadOrdersWithCurrentPaginationFunc = () =>
    loadOrders(paginationFilters);

  // handle realtime to reload customers, orders and statistics when new order arrive
  useEffect(() => {
    const isFirstMountOrFilterSet =
      didMount || (!didMount && paginationFilters.hasOwnProperty('offset'));

    if (isFirstMountOrFilterSet) {
      // If we are going to re-add the listener, delete current
      // to avoid double events processing
      RealtimeHelper.removeListener('global_realtime');
      
      // Safely add the new listener
      RealtimeHelper.handleGeneralFlowEffect(
        loadCustomers,
        reloadOrdersWithCurrentPaginationFunc,
        loadStatistics
      )();     
    }
  }, [paginationFilters]);

  useEffect(() => {
    if (prevLocation !== location) {
      hideSider();
    }
  });

  useEffect(() => {
    RealtimeHelper.removeListener('invoice_realtime');
    RealtimeHelper.handleInvoiceRealtime(loadInvoices)();
  }, []);

  const [drawerVisible, showDrawer] = useState(false);

  return (
    <Layout className="uos-layout">
      <Drawer
        title={<img src={logo} alt="Logo" style={{ width: '90%' }} />}
        placement="right"
        closable
        visible={drawerVisible}
        onClose={() => showDrawer(false)}
      >
        <Menu
          mode="vertical-right"
          style={{ borderLeft: 'none' }}
          onClick={() => showDrawer(false)}
        >
          <Menu.Item
            key="0"
            onClick={() => {
              history.push('/dashboard/main');
            }}
          >
            Ordre
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/dashboard/customers');
            }}
          >
            Kunder
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              history.push('/dashboard/lager/produkter');
            }}
          >
            Lager
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              history.push('/dashboard/brukere');
            }}
          >
            Brukere
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              history.push('/dashboard/report');
            }}
          >
            Report
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => {
              Api.logout();
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Drawer>
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          padding: '0 1rem',
          height: '60px',
          boxShadow: '0 0 20px #ddd',
        }}
      >
        <div className="logo">
          <img
            src={logo}
            alt="Logo"
            style={{ height: '45px' }}
            onClick={() => history.push('/dashboard/main')}
          />
        </div>
        <PodioMenuItem
          icon={<Icon type="file-protect" />}
          label="Ordre"
          path="/dashboard/main"
        />
        <PodioMenuItem
          icon={<Icon type="dropbox" />}
          label="Lager"
          path="/dashboard/lager/produkter"
        />
        <PodioMenuItem
          icon={<Icon type="user" />}
          label="Kunder"
          path="/dashboard/customers"
        />
        <PodioMenuItem
          icon={<Icon type="profile" />}
          label="Faktura"
          path="/dashboard/faktura"
        />

        <div
          className="hamburger-menu-container"
          onClick={() => showDrawer(true)}
        >
          <Icon type="menu" className="hamburger-icon" />
        </div>
        <Button
          className={['filters-toggle-button', 'active']
            .filter((f) => f !== 'active' || filtersShown)
            .join(' ')}
          type="link"
          icon="control"
          size="large"
          onClick={() => {
            _toggleFilters();
          }}
        />
      </Header>
      <Content style={{ padding: '40px 50px', marginTop: 64 }}>
        <div
          className="inner-layout-content"
          style={{ background: '#fff', padding: 24, minHeight: 200 }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  fieldUpdates: PropTypes.shape({}).isRequired,
  _toggleFilters: PropTypes.func.isRequired,
  loadCustomers: PropTypes.func.isRequired,
  loadStatistics: PropTypes.func.isRequired,
  loadOrders: PropTypes.func.isRequired,
  hideSider: PropTypes.func.isRequired,
  filtersShown: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paginationFilters: PropTypes.any.isRequired,
};

const mapStateToProps = ({ Orders, Ui }) => ({
  fieldUpdates: Orders.fieldsLoading,
  filtersShown: Ui.showFilters,
  paginationFilters: Orders.pagination,
});

export default connect(mapStateToProps, (dispatch) => ({
  ...bindActionCreators(
    {
      loadCustomers: loadCustomersAction,
      loadOrders: loadOrdersAction,
      loadStatistics: loadStatisticsAction,
      loadInvoices: loadInvoicesAction,
      hideSider: hideSiderAction,
    },
    dispatch
  ),
  _toggleFilters: () => dispatch(toggleFilters()),
}))(withRouter(DashboardLayout));
