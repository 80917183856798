import React, { createContext } from 'react';

export const GuardedContext = createContext([]);

const GuardedProvider = ({ guards, children }) => (
  <GuardedContext.Provider value={guards || []}>
    {children}
  </GuardedContext.Provider>
);

export default GuardedProvider;
