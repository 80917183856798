/* eslint-disable react/no-array-index-key */
import React from 'react';
import { get, has } from 'lodash-es';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import Select from 'antd/es/select';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment';

import { DATE_FORMAT } from '../../constants/defaultValues';

const renderSection = (config, datas, getFieldDecorator) => (
  <Col xs={24} md={12} lg={8} style={{ padding: '14px' }}>
    <Col md={3} className="section-icon-container">
      {get(config, 'icon', <Icon type="carry-out" />)}
    </Col>
    <Col md={21}>
      {get(config, 'datas', []).map((_conf) => {
        const val = get(datas, _conf.path, '');
        if (has(_conf, 'render') && typeof _conf.render === 'function') {
          return _conf.render(val, getFieldDecorator);
        }

        return (
          <Form.Item label={_conf.title}>
            {getFieldDecorator(_conf.path, { initialValue: val })(
              <Input type="text" />
            )}
          </Form.Item>
        );
      })}
    </Col>
    {/* {config.withBottomLine ? (
      <Col xs={24}>
        <hr style={{
          margin: '0 -1.5rem 1.5rem -1.5rem',
          borderColor: 'rgba(0, 0, 0, 0.15)',
          borderWidth: '0.5px',
        }}
        />
      </Col>
    ) : null} */}
  </Col>
);

export const renderSelect = (
  value,
  options,
  placeholder,
  getFieldDecorator,
  id = ''
) => (
  <Form.Item className="table-formitem-wrapper">
    {getFieldDecorator(id, { initialValue: value || undefined })(
      <Select style={{ width: '100%' }} placeholder={placeholder}>
        <Select.Option value="" key="null-0">
          (no value)
        </Select.Option>
        {options.map((optVal, index) => (
          <Select.Option value={optVal} key={`${optVal}-${index}`}>
            {optVal}
          </Select.Option>
        ))}
      </Select>
    )}
  </Form.Item>
);

export const renderDate = (value, title, getFieldDecorator, id = '') => {
  let val = value || undefined;
  val = val === '' ? undefined : val;

  return (
    <Form.Item label={title}>
      {getFieldDecorator(id, { initialValue: moment(val) })(
        <DatePicker format={DATE_FORMAT} style={{ width: '100%' }} />
      )}
    </Form.Item>
  );
};

export default renderSection;
