/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import { all, put, takeLatest, fork } from 'redux-saga/effects';
import moment from 'moment';
import { get } from 'lodash-es';

import handleError from './handleError';

import { createActivity } from '../ActionsAndReducers/Activities';
import {
  INVOICES_UPDATE_STATUS_START,
  INVOICES_UPDATE_STATUS_END,
} from '../ActionsAndReducers/Invoices';
import Api from '../../api/Methods';

function* updateStatus() {
  yield takeLatest(INVOICES_UPDATE_STATUS_START, function* (action) {
    try {
      yield Api.httpUpdateInvoice(action.id, { status: action.value });
      yield put({
        type: INVOICES_UPDATE_STATUS_END,
        action,
      });

      // Handle create activity
      const username = get(
        JSON.parse(localStorage.getItem('user_object')),
        'user.username',
        ''
      );
      const activity = {
        creator: username,
        date: moment().toISOString(),
        order_sku: get(action, 'orderNumber', undefined),
        stream: 'invoice',
        activity: `Faktura status updated to ${get(action, 'value', '')}`,
      };

      yield put(createActivity(activity));
    } catch (e) {
      yield handleError(e);
    }
  });
}

export default function* invoiceSaga() {
  yield all([fork(updateStatus)]);
}
