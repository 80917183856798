/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Collapse from 'antd/es/collapse';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import moment from 'moment';
import Table from 'antd/es/table';
import Typography from 'antd/es/typography';

import StringsHelper from '../../../helpers/Strings';
import { showSiderAction } from '../../../redux/sider/actions';
import { buildTableValues } from '../../../helpers/Fields';
import { tableColumns } from '../../OrderDetails/PdfView';
import Activities from '../../Activities/Activities';

import './styles.css';

const dateFormat = 'DD.MM.YYYY';

const InvoiceField = ({ label, value }) => (
  <div className="invoice-field">
    <h4>{label}</h4>
    {value}
  </div>
);

const InvoiceContent = ({ invoice, showSider }) => {
  const [windowSize, setWSize] = useState(window.innerWidth);
  useEffect(() => {
    setWSize(window.innerWidth);
  });

  const boxStylings = {
    expand: { flexGrow: 1 },
    fit: { flexBasis: 'fit-content', flexGrow: 0 },
  };

  const boxLayout = {
    md: 8,
    xs: 12,
    sm: 8,
  };

  const headerColsConfigs = [
    {
      title: 'Kunde',
      content: (
        <div className="invoice-cus-adr">
          <p>{get(invoice, 'values.customers.name', '')}</p>
          <p>{get(invoice, 'values.customers.adresse', '')}</p>
          <p>
            {get(invoice, 'values.customers.postnummer', '')},{' '}
            {get(invoice, 'values.customers.poststed', '')}
          </p>
          <p>{get(invoice, 'values.customers.country', '')}</p>
          <p>{get(invoice, 'values.customers.phone', '')}</p>
        </div>
      ),
    },
    {
      title: 'Leveringsaddresse',
      content: (
        <div className="invoice-cus-adr">
          <div
            dangerouslySetInnerHTML={{
              __html: StringsHelper.removeParenthezedValues(
                get(invoice, 'values.orders.values.leveringsAdresse', '')
              ),
            }}
          />
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <Typography.Title level={4}>Faktura </Typography.Title>
          <Button
            type="primary"
            size="small"
            icon="file-pdf"
            style={{ marginLeft: '.7rem', backgroundColor: '#db8556', borderColor: '#db8556' }}
            href={StringsHelper.urlJoin([
              process.env.REACT_APP_BACKEND_URL,
              `orders/faktura/${get(invoice, '_id', '')}?mode=pdf`,
            ])}
            target="_blank"
          />
        </div>
      ),
      content: (
        <>
          <InvoiceField
            label="Fakturanummer: "
            value={get(invoice, 'values.fakturanummer', '')}
          />
          <InvoiceField
            label="Fakturadato: "
            value={moment(get(invoice, 'values.fakturadato', '')).format(
              dateFormat
            )}
          />
          <InvoiceField
            label="Forfallsdato: "
            value={moment(get(invoice, 'values.forfallsdato', '')).format(
              dateFormat
            )}
            /* value={moment(get(invoice, 'values.createdAt', ''))
              .add(30, 'days')
              .format(dateFormat)} */
          />
          <br />
          <InvoiceField
            label="Ordernummer: "
            value={
              <Button
                key={get(invoice, 'values.order', null)}
                type="primary"
                shape="round"
                icon="file-protect"
                size="small"
                style={{ margin: '0 0.2rem' }}
                onClick={() => {
                  showSider('order', get(invoice, 'values.order', ''));
                }}
              >
                {get(invoice, 'values.orders.values.number', '')}
              </Button>
            }
          />
        </>
      ),
      fit: true,
    },
  ];

  const renderDesktopHeaderCols = (configs) =>
    configs.map((conf) => (
      <Col
        {...boxLayout}
        style={conf.fit ? boxStylings.fit : boxStylings.expand}
        className="desktop-invoice-col"
      >
        {typeof conf.title === 'string' && (
          <Typography.Title level={4}>{conf.title}</Typography.Title>
        )}
        {typeof conf.title !== 'string' && conf.title}

        {conf.content}
      </Col>
    ));

  const renderMobileHeaderCols = (configs) => (
    <Col xs={24} className="mobile-invoice-col">
      <Collapse
        accordion
        className="mobile-invoice-col-header"
        defaultActiveKey={2}
      >
        {configs.map((conf, index) => (
          <Collapse.Panel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            header={
              typeof conf.title === 'string' ? (
                <Typography.Title level={4}>{conf.title}</Typography.Title>
              ) : (
                conf.title
              )
            }
          >
            {conf.content}
          </Collapse.Panel>
        ))}
      </Collapse>
    </Col>
  );

  return (
    <div className="invoice-content">
      <br />
      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={24} lg={24} xl={19}>
          <Row gutter={16} className="invoice-cols-container">
            {renderDesktopHeaderCols(headerColsConfigs)}
            {renderMobileHeaderCols(headerColsConfigs)}
          </Row>

          <br />

          <Row gutter={16}>
            <Col xs={24} className="encadre">
              <p>Betaling: Ski Peer AS, Bankgiro: 6177.05.41912</p>
              <p>Betingelser: 30 dg. Nto. 10dg. 2%</p>
              <p>Betales ved bank overførsel</p>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} style={{ marginTop: '2rem' }}>
              <Table
                className="table-field"
                scroll={{ x: windowSize > 420 ? 700 : 500 }}
                dataSource={buildTableValues(
                  get(invoice, 'values.orders.values', [])
                )}
                columns={tableColumns(
                  () => {},
                  get(invoice, 'values.orders.values', {}),
                  () => {},
                  () => {},
                  false
                )}
                pagination={false}
                size="small"
                bordered={false}
                loading={false}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 5 }}
        >
          <Activities stream="invoice" order={get(invoice, 'values.orders', {})} />
        </Col>
      </Row>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  showSider: (...args) => dispatch(showSiderAction(...args)),
}))(InvoiceContent);
