/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

const CardLayout = ({
  active,
  title,
  renderMain,
  renderSecondary,
  item,
  ...props
}) => (
  <Card
    className={['order-card', 'active'].filter(
      (c) => c === 'order-card' || (c === 'active' && active)
    )}
    {...props}
  >
    <Row>
      <Col xs={10}>
        <Col xs={24} className="custName">
          {title}
        </Col>
        {renderMain && renderMain(item)}
      </Col>
      {renderSecondary && renderSecondary(item)}
    </Row>
  </Card>
);

CardLayout.defaultProps = {
  title: 'My Card',
  renderMain: null,
  renderSecondary: null,
  item: null,
  active: false,
};

CardLayout.propTypes = {
  title: PropTypes.string,
  renderMain: PropTypes.func,
  renderSecondary: PropTypes.func,
  item: PropTypes.any,
  active: PropTypes.bool,
};

export default CardLayout;
