/* eslint-disable no-param-reassign,max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, get } from 'lodash-es';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import SwipeableViews from 'react-swipeable-views';

import {
  loadOrders,
  realtimeUpdateOrder,
  saveOrder,
  atomicUpdate,
} from '../../redux/ActionsAndReducers/Orders';
import { notifyError } from '../../helpers/Notify';
import realtimeClient from '../../realtime/client';
import {
  requestExportData,
  showSider,
  hideSider,
  fetchOrderStatusesAction,
} from '../../redux/ActionsAndReducers/Ui';
import { loadCustomers } from '../../redux/ActionsAndReducers/Customers';
import ObjectHelpers from '../../helpers/Objects';
import AddOrderModal from '../../components/AddOrderModal';
import { showStageAction, hideStageAction } from '../../redux/staging/actions';
import RealtimeHelper from '../../realtime/helpers';
import { showSiderAction, hideSiderAction } from '../../redux/sider/actions';
import { actionFetch as loadStatisticsAction } from '../../redux/ActionsAndReducers/Statistics';
import CardLayout from '../../components/CardLayout';
import renderMixin from '../../mixins/order.render.mixin';
import './style.css';

class Main extends renderMixin(CardLayout) {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchText: '',
      extendedSearch: false,
    };
    this.toggleSearchMode = this.toggleSearchMode.bind(this);
    this.orderExists = this.orderExists.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    const {
      _loadOrders,
      orders,
      _updateOrder,
      loadStatistics,
      fetchOrderStatuses,
    } = this.props;
    if (!orders || orders.length < 1) {
      _loadOrders({
        offset: 0,
        limit: 50,
        // Show Ny by default
        filters: { 'values.status': 'Ny' },
      });
      fetchOrderStatuses();
    }

    realtimeClient.addListener({
      name: 'order_realtime',
      channel: '/orders',
      action: RealtimeHelper.reloadOrderAfterUpdate(
        _updateOrder,
        this.orderExists,
        loadStatistics
      ),
    });
  }

  componentDidUpdate(prevProps) {
    // Handle sider update when an order has updated
    // and is still shown inside a sider
    const {
      orderLineIsLoading,
      sider2IsVisible,
      sider2Type,
      orderLineUpdatedOrder,
      showSider2,
    } = this.props;
    // check if orderLine just changed
    const orderLineJustChanged =
      prevProps.orderLineIsLoading === true && orderLineIsLoading === false;
    if (orderLineJustChanged && sider2IsVisible && sider2Type === 'order') {
      showSider2('order', orderLineUpdatedOrder || {});
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    realtimeClient.removeListener('order_realtime');
  }

  orderExists(id) {
    const { orders } = this.props;
    const currentOrder = find(orders, (o) => o._id === id);

    return !!currentOrder;
  }

  toggleSearchMode() {
    this.setState(({ extendedSearch, ...state }) => ({
      ...state,
      extendedSearch: !extendedSearch,
    }));
  }

  handleSearch(e) {
    e.preventDefault();
    const searchValue = document.getElementById('searchValue').value;

    this.handleSearchInput(searchValue);
  }

  handleSearchInput(value) {
    const { _loadOrders, pagination, hideSider2, sider2IsVisible } = this.props;

    // close the sider to avoid data flipping
    if (sider2IsVisible) hideSider2();

    _loadOrders({
      limit: pagination.limit,
      offset: 0,
      filters: {
        ...pagination.filters,
        _search: encodeURIComponent(value),
      },
    });
  }

  render() {
    const {
      orders,
      isLoading,
      err,
      pagination,
      atomicUpdateState,
      sider2IsVisible,
    } = this.props;
    // Filter out orders based on filters value
    const statusFilter = get(pagination, "filters['values.status']", null);
    const fieldIsLoading =
      Object.values(atomicUpdateState || {}).filter(
        (f) => get(f, 'status.loading', false) === true
      ).length > 0;
    const filteredOrders =
      // only filter when atomic update is done
      statusFilter && !fieldIsLoading
        ? orders.filter(
            (order) => get(order, 'values.status', null) === statusFilter
          )
        : orders;

    if (err) {
      notifyError('Error on fetching datas');
    }

    const leftContent = (
      <>
        <AddOrderModal />
        {this.renderSider('mobile')}
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
          style={{
            overflowY: 'auto',
            height: `${window.innerHeight - 85}px`,
            position: 'relative',
          }}
        >
          {this.renderSideHeaderCard()}

          {isLoading ? this.renderSkeleton() : this.renderCards(filteredOrders)}

          {this.renderPaginationCard()}
        </Col>
      </>
    );

    const rightContent = (
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={16}
        xl={18}
        style={{
          position: 'relative',
          height: `${window.innerHeight - 85}px`,
          backgroundColor: 'rgb(240, 242, 245)',
          overflowY: 'auto',
        }}
      >
        {this.renderKpisAndDetails()}

        {this.isMobile() ? null : this.renderSider('desktop')}
      </Col>
    );

    return this.isMobile() ? (
      <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
        <SwipeableViews disabled={sider2IsVisible} resistance>
          {leftContent}
          {rightContent}
        </SwipeableViews>
      </Row>
    ) : (
      <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
        {leftContent}
        {rightContent}
      </Row>
    );
  }
}

Main.defaultProps = {
  err: null,
  onExport: false,
};

Main.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.shape({}),
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  kpis: PropTypes.shape({}).isRequired,
  // kpiIsLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}).isRequired,

  _loadOrders: PropTypes.func.isRequired,
  // _addOrder: PropTypes.func.isRequired,
  _updateOrder: PropTypes.func.isRequired,
  _atomicUpdate: PropTypes.func.isRequired,
  atomicUpdateState: PropTypes.shape({}).isRequired,

  total: PropTypes.number.isRequired,
  onExport: PropTypes.bool,
  _requestExportData: PropTypes.func.isRequired,
  showStage: PropTypes.func.isRequired,
  hideStage: PropTypes.func.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  statuses: PropTypes.any.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  _loadOrders: (opts) => {
    if (opts) {
      // update filters for keys inside values
      opts.filters = ObjectHelpers.renameKeys(opts.filters || {}, {
        kunde: 'values.kunde.name',
        status: 'values.status',
        leveringstype: 'values.leveringstype',
        kommentar: 'values.kommentar',
      });

      if (opts.sorters && opts.sorters.field === 'ordreDato') {
        opts.sorters.field = 'createdAt';
      }
    }
    dispatch(loadOrders(opts));
  },
  _loadCustomers: (opts) => dispatch(loadCustomers(opts)),

  _updateOrder: (id, order) => dispatch(realtimeUpdateOrder(id, order)),
  _requestExportData: (model) => dispatch(requestExportData(model)),
  rdxShowSider: (payload) => dispatch(showSider(payload)),
  rdxHideSider: () => dispatch(hideSider()),
  _saveOrder: (...args) => dispatch(saveOrder(...args)),
  _atomicUpdate: (...args) => dispatch(atomicUpdate(...args)),
  showStage: (...args) => dispatch(showStageAction(...args)),
  hideStage: (...args) => dispatch(hideStageAction(...args)),
  showSider2: (...args) => dispatch(showSiderAction(...args)),
  hideSider2: (...args) => dispatch(hideSiderAction(...args)),
  loadStatistics: (...args) => dispatch(loadStatisticsAction(...args)),
  fetchOrderStatuses: (...args) => dispatch(fetchOrderStatusesAction(...args)),
});

const mapStateToProps = ({ Orders, Ui, sider }) => ({
  isLoading: Orders.loading,
  err: Orders.error,
  orders: Orders.orders,
  columns: Orders.columns,
  total: Orders.total,
  pagination: Orders.pagination,
  onExport: Ui.onExport.orders,
  siderShown: Ui.sider.show,
  siderContent: Ui.sider.content,
  atomicUpdateState: Orders.atomicUpdates,
  filtersShown: Ui.showFilters,
  siderData: sider.data,
  sider2IsVisible: sider.visible,
  sider2Type: sider.type,
  orderLineIsLoading: Ui.editOrderLine.loading,
  orderLineUpdatedOrder: Ui.editOrderLine.orderUpdated,
  statuses: Ui.orderStatuses,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
