import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';

import { requestNewPassword } from '../../redux/ActionsAndReducers/Ui';
import NotFound from '../errors/404';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = ({ form, loading, setPassword }) => {
  const query = useQuery();
  const code = query.get('code') || null;

  if (code === null) {
    return <NotFound />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((errors, values) => {
      if (!errors) {
        setPassword({
          code,
          password: values.password,
        });
      }
    });
  };

  return (
    <>
      <img
        className="form-logo"
        src="https://mk0gledesgaveruy1v45.kinstacdn.com/wp-content/uploads/2019/01/NY-GG-logo-horisontal-tagline-2-scaled.png"
        alt="Logo"
      />
      <Card id="components-form-demo-normal-login">
        <h3 style={{ textAlign: 'center' }}>Set your password</h3>
        <Form className="login-form" onSubmit={handleSubmit}>
          <Form.Item>
            {form.getFieldDecorator('password', {
              rules: [{ required: true, message: 'Password is mandatory' }],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator('password2', {
              rules: [
                {
                  required: true,
                  message: 'Passwords must match',
                  whitespace: true,
                  validator: (rule, value, callback) => {
                    const firstPassword = form.getFieldValue('password');
                    if (firstPassword !== value) {
                      return callback('Passwords must match');
                    }

                    return callback();
                  },
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Re-enter your password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              Set password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

const mapStateToProps = ({ Ui }) => ({
  loading: Ui.setPasswordIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setPassword: (payload) => dispatch(requestNewPassword(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'resetpassword' })(ResetPassword));
