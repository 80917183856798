/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Col from 'antd/es/col';
import Autocomplete from 'antd/es/auto-complete';
import InputNumber from 'antd/es/input-number';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import { find, findIndex } from 'lodash-es';

import {
  searchProductAction,
  clearSearchProductAction,
  setProductInListAction,
  removeProductAtAction,
  removeManyProductsAction,
} from '../../redux/staging/actions';

const produceTableColumns = (
  deleteFunc,
  updateProductLine
) => [
  { title: 'SKU', dataIndex: 'product.sku', key: 'sku' },
  { title: 'Navn', dataIndex: 'product.navn', key: 'navn' },
  { title: 'Antall', dataIndex: 'amount', key: 'amount',
    render: (p, record) => (
      <InputNumber
      key={p.sku}
      className="expand-on-small"
      min={0}
      max={999999}
      value={record.amount}
      onChange={(val) => updateProductLine({ ...record, amount: val })}
    />      
      )
  },
  {
    title: '',
    dataIndex: 'product',
    key: 'belop',
    render: (p, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            size="small"
            shape="circle"
            type="danger"
            icon="close"
            onClick={() => deleteFunc(index)}
          />
        </div>
      ),
  },
];

const Inventories = ({
  searchProduct,
  searchResults,
  clearSearchResults,
  addTempProduct,
  products,
  removeProductAt,
}) => {
  const [tempLine, setTempLine] = useState({ product: null, amount: 0 });
  const [searchText, setSearchText] = useState('');
  const amountInputRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const deleteProductLine = (index) => {
    removeProductAt(index);
  };
  const cleanup = () => {
    setSearchText('');
    setTempLine({ product: null, amount: 0 });
    autoCompleteRef.current.focus();
  };

  const updateProductLine = (targetProduct) => {
    const indexOfLine = findIndex(products, (p) => {
      if (p.product._id === undefined || targetProduct.product._id === undefined) {
        return p.product.sku === targetProduct.product.sku;
      }
      return p.product._id === targetProduct.product._id;
    });

    const newProducts = [...products];
    newProducts[indexOfLine].amount = targetProduct.amount;
    // add the product line to redux, so it gets resolved eventually
    addTempProduct(newProducts[indexOfLine], indexOfLine);
  }
  const productsTableColumns = produceTableColumns(
    deleteProductLine,
    updateProductLine
  );
  const addProductLine = () => {
    if (tempLine.product === null || tempLine.amount === 0) {
      return;
    }
    // handle same products
    const sameObjectKey = findIndex(products, (p) => {
      if (p.product._id === undefined || tempLine.product._id === undefined) {
        return p.product.sku === tempLine.product.sku;
      }
      return p.product._id === tempLine.product._id;
    });
    
    const _products = [...products];
    
    // just append if no products match
    _products.push(tempLine);
    const indexOfLine =
      sameObjectKey >= 0
        ? sameObjectKey
        : _products.length - 1;

    // add the product line to redux, so it gets resolved eventually
    addTempProduct(_products[indexOfLine], indexOfLine);
    cleanup();
  };

  return (
    <Col xs={24}>
      <div className="addmodal-form" style={{ paddingBottom: '1rem' }}>
        <Table
          rowKey="product.sku"
          className="addmodal-form-product-table"
          columns={productsTableColumns}
          dataSource={products}
          size="small"
          pagination={false}
          bordered
          rowClassName={(record) => {
            if (record.errored) return 'row-errored';
            if (record.loading) return 'row-loading';

            return 'row-normal';
          }}
        />
      </div>
      <div className="product-modal action-container">
        <Autocomplete
          autoFocus
          className="expand-on-small"
          placeholder="SKU or Name"
          ref={autoCompleteRef}
          onSearch={(value) => {
            clearSearchResults(value);
            searchProduct(value);
          }}
          value={searchText}
          onChange={(text) => setSearchText(text)}
          optionLabelProp="val"
          onSelect={(value) => {
            setTempLine({
              ...tempLine,
              product: find(searchResults, (o) => o.sku === value) || {
                navn: value,
                sku: value,
              },
              amount: null,
              loading: true,
            });
            amountInputRef.current.focus();
            clearSearchResults();
          }}
        >
          {(searchResults || []).map((entry) => (
            <Autocomplete.Option key={entry.sku} val={entry.sku}>
              <span style={{ fontWeight: 'bold' }}>({entry.sku})</span>{' '}
              {entry.navn || ''}
            </Autocomplete.Option>
          ))}
        </Autocomplete>
        <InputNumber
          className="expand-on-small"
          ref={amountInputRef}
          min={0}
          max={999999}
          value={tempLine.amount}
          onChange={(val) => setTempLine({ ...tempLine, amount: val })}
          onPressEnter={addProductLine}
        />
        <Button
          className="expand-on-small"
          type="primary"
          onClick={addProductLine}
          disabled={tempLine.amount === 0 || tempLine.product === null}
        >
          Add product
        </Button>
      </div>
    </Col>
  );
};

const mapStateToProps = ({ staging }) => ({
  searchResults: staging.productsSearchResults,
  products: staging.products,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      searchProduct: searchProductAction,
      clearSearchResults: clearSearchProductAction,
      addTempProduct: setProductInListAction,
      removeProductAt: removeProductAtAction,
      removeManyProducts: removeManyProductsAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventories);
