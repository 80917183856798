/* eslint-disable camelcase,max-len */
/* eslint-disable import/prefer-default-export */
import { getOrderActivities, createActivity } from './activities';
import { logout, login, resetPassword, setNewPassword } from './auth';
import { _getCommentsForOrder, _sendComment } from './comments';
import { bookConsignment } from './consignment';
import { buildCsvData } from './csv';
import {
  _getCustomersPromiseOnly,
  _getColumnsPromiseOnly,
  getCustomersStats,
  _getCustomersAndColumns,
  updateCustomer,
  getOneCustomer,
  httpSearchCustomer,
  _getColumns,
  httpCreateCustomer,
  httpSetCoordData,
  httpSyncCustomer,
} from './customers';
import {
  _getInvoices,
  _getInvoicesPromiseOnly,
  getOneInvoice,
  httpSearchInvoice,
  httpUpdateInvoice,
} from './invoices';
import {
  getProductsCategories,
  getProducts,
  getProductsStats,
  updateProduct,
  addProduct,
  httpSearchProduct,
  updateProductCategories,
  httpUpdateInventories
} from './products';
import { _uploadFile, _removeFile } from './files';
import {
  _getOrders,
  _getOrdersPromiseOnly,
  _getOrdersAndColumns,
  updateOrder,
  updateOrderNoCb,
  getOrderKpis,
  printDocuments,
  findOneOrder,
  getOrdersStats,
  httpUpdateAmounts,
  httpEditOrderLines,
  httpDeleteOrderLine,
  httpCreateOrder,
  httpFetchOrderStatuses,
} from './orders';
import { fetchUsers, registerUser, deleteUser, updateUser } from './users';
import { getReport, httpGetTableReports } from './reports';
import getConfigs from '../helpers/config';

export default {
  _getOrdersPromiseOnly,
  _getCustomersPromiseOnly,
  _getColumnsPromiseOnly,
  getCustomersStats,
  _getOrders,
  _getColumns,
  _getOrdersAndColumns,
  _getCustomersAndColumns,
  _getCommentsForOrder,
  _sendComment,
  _uploadFile,
  _removeFile,
  getConfigs,
  logout,
  login,
  updateOrder,
  updateOrderNoCb,
  bookConsignment,
  updateCustomer,
  getReport,
  getProductsCategories,
  getProductsStats,
  getProducts,
  getOrderKpis,
  updateProduct,
  addProduct,
  fetchUsers,
  registerUser,
  deleteUser,
  updateUser,
  resetPassword,
  setNewPassword,
  getOrderActivities,
  createActivity,
  buildCsvData,
  printDocuments,
  getOneCustomer,
  findOneOrder,
  getOrdersStats,
  httpGetTableReports,
  httpUpdateAmounts,
  httpSearchProduct,
  httpEditOrderLines,
  httpDeleteOrderLine,
  httpSearchCustomer,
  httpCreateOrder,
  createCustomer: httpCreateCustomer,
  httpFetchOrderStatuses,
  updateProductCategories,
  _getInvoices,
  _getInvoicesPromiseOnly,
  getOneInvoice,
  httpSearchInvoice,
  httpUpdateInvoice,
  setCoordData: httpSetCoordData,
  syncCustomerWc: httpSyncCustomer,
  httpUpdateInventories
};
