/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'antd/es/modal';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { hideStageAction } from '../../redux/staging/actions';

import {
  renderCustomerStep,
  renderProductStep,
  renderStatusStep,
  renderStepAtPlace,
  renderValidateButtons,
} from './renderers';

import './styles.css';

const PLACEMENT_CONFIG = {
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
};

const steps = [
  {
    title: 'Products',
    icon: 'dropbox',
    render: renderProductStep,
    placement: PLACEMENT_CONFIG.RIGHT,
  },
  {
    title: 'Customer',
    icon: 'idcard',
    render: renderCustomerStep,
    renderTitle: false,
    placement: PLACEMENT_CONFIG.LEFT,
  },
  {
    title: 'Other',
    icon: 'question',
    render: renderStatusStep,
    placement: PLACEMENT_CONFIG.LEFT,
  },
  {
    title: 'Validate',
    icon: 'check',
    render: renderValidateButtons,
    placement: PLACEMENT_CONFIG.BOTTOM,
  },
];

/**
 * Render all steps at once
 * @param {*} funcs
 * @param {*} states
 * @returns
 */
const renderAllSteps = (funcs, states) => (
  <Row className="steps-container">
    <Col xs={24} md={16} className="fullscreen-scroll-container">
      <Row>
        {renderStepAtPlace(PLACEMENT_CONFIG.RIGHT, steps, { funcs, states })}
      </Row>
    </Col>
    <Col xs={24} md={8} className="fullscreen-scroll-container">
      <Row>
        {renderStepAtPlace(PLACEMENT_CONFIG.LEFT, steps, { funcs, states })}
      </Row>
    </Col>
    <Col xs={24} style={{ position: 'fixed', bottom: 0, left: 0 }}>
      <Row>
        {renderStepAtPlace(PLACEMENT_CONFIG.BOTTOM, steps, {
          funcs,
          states,
          noSpace: true,
        })}
      </Row>
    </Col>
  </Row>
);

const AddOrderModal = ({ visible, hide }) => {
  const [currentStep, setStep] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [weightAndTotal, setWaT] = useState({});
  const [other, setOther] = useState({
    status: 'Ny',
    orderType: { type: 'manual' },
  });

  const clearAllSteps = () => {
    setCustomer(null);
    setProducts([]);
    setOther({ status: 'Ny', orderType: { type: 'manual' } });
    setWaT({});
    setStep(0);
  };

  const clearAllStepsAndHide = () => {
    clearAllSteps();
    hide();
  };

  return (
    <Modal
      visible={visible}
      onCancel={clearAllStepsAndHide}
      footer={null}
      centered
      className="add-order-modal"
      destroyOnClose
    >
      <div className="screen-stage">
        {renderAllSteps(
          {
            setCustomer,
            setStep,
            setProducts,
            setWaT,
            setOther,
            hide,
            clearAllSteps,
            clearAllStepsAndHide,
          },
          {
            customer,
            products,
            weightAndTotal,
            other,
          }
        )}
      </div>
    </Modal>
  );
};

export default connect(
  ({ staging }) => ({
    visible: staging.visible,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        hide: hideStageAction,
      },
      dispatch
    ),
  })
)(AddOrderModal);
