/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import PropTypes from 'prop-types';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import { merge, cloneDeep, find, get } from 'lodash-es';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const UpdateCategoriesModal = ({
  open,
  handleOk,
  handleClose,
  categories,
  parents,
}) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const sortedCateg = Array.from(categories);
    sortedCateg.sort((a, b) => (a.metas.order || 0) - (b.metas.order || 0));
    sortedCateg.push({
      _id: `new-${Date.now()}`,
      name: '',
      metas: { parent: [], order: sortedCateg.length },
    });
    setRows(sortedCateg);

    return () => {
      // Cleanup
      setRows([]);
    };
  }, [categories]);

  /**
   * Update the row value
   * @param {*} position
   * @param {*} value
   * @param {*} baseRows
   * @param {*} updateFunc
   * @returns
   */
  const updateRowAt = (position, value) => {
    const newRows = cloneDeep(rows);
    const oldVal = { ...newRows[position] };
    newRows[position] = merge({}, oldVal, value);

    return setRows(newRows);
  };

  /**
   * Change category row order in array
   * Also change metas.order value to reflect the new order
   * Set all orders as updated
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  const reorder = (startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((category, i) => {
      // Assign the new order to be saved in DB
      category.metas.order = i;
      // Set all as changed because the order has to change in DB too
      category.metas.updated = true;

      return category;
    });
  };

  const onDragEnd = (result) => {
    // Dropped outside of droppable
    if (!result.destination) {
      return;
    }

    // Did not move
    if (result.destination.index === result.source.index) {
      return;
    }

    const newRows = reorder(result.source.index, result.destination.index);

    setRows(newRows);
  };

  const onValidate = () => {
    const pushDatas = [...rows.filter((r) => r.metas.updated && r.name)];

    // Transform datas
    handleOk(
      pushDatas.map((data) => {
        const _data = { ...data };
        delete _data.metas.updated;

        // Remove id of new elements so that backend can insert with a new ObjectId
        if (_data._id.match(/^new/gi)) {
          delete _data._id;
        }

        if (
          Array.isArray(_data.metas.parent) &&
          _data.metas.parent.length > 0
        ) {
          _data.metas.parent = [{ _id: _data.metas.parent[0]._id }];
        }

        return _data;
      })
    );
  };

  const renderCategoryRow = (cat) =>
    cat.map((c, i) =>
      c.metas.deleted === true ? null : (
        <Draggable draggableId={`row-${c._id}`} index={i} key={c._id}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Row gutter={16}>
                <Col xs={2}>
                  <Icon style={{ paddingTop: '10px' }} type="drag" />
                </Col>
                <Col xs={20} md={parents.length > 0 ? 9 : 20}>
                  <Form.Item key={`name-${c._id}`}>
                    <Input
                      placeholder="Enter new category"
                      value={c.name || ''}
                      onChange={(v) =>
                        updateRowAt(i, {
                          name: v.target.value,
                          metas: { updated: true },
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                {parents.length > 0 && (
                  <Col xs={24} md={11}>
                    <Form.Item key={`name-${c._id}`}>
                      <Select
                        value={
                          ([...(c.metas.parent || [])].pop() || { _id: null })
                            ._id
                        }
                        onChange={(val) =>
                          updateRowAt(i, {
                            metas: {
                              parent: [
                                {
                                  ...find(
                                    parents,
                                    (catego) => catego._id === val
                                  ),
                                },
                              ],
                              updated: true,
                            },
                          })
                        }
                      >
                        {parents.map((p) => (
                          <Select.Option key={`option-${p._id}`} value={p._id}>
                            {p.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={2}>
                  <Button
                    type="danger"
                    shape="circle"
                    icon="delete"
                    size="small"
                    style={{ marginTop: '7px' }}
                    onClick={() =>
                      updateRowAt(i, {
                        metas: { updated: true, deleted: true },
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          )}
        </Draggable>
      )
    );

  return (
    <Modal
      visible={open}
      onOk={onValidate}
      onCancel={handleClose}
      destroyOnClose
      title="Update categories"
    >
      <Form>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {rows.length > 0 && renderCategoryRow(rows)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          type="primary"
          onClick={() => {
            setRows([
              ...rows,
              {
                _id: `new-${Date.now()}`,
                name: '',
                metas: { parent: [], order: rows.length },
              },
            ]);
          }}
        >
          Add row
        </Button>
      </Form>
    </Modal>
  );
};

UpdateCategoriesModal.defaultProps = {
  parents: [],
};

UpdateCategoriesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  categories: PropTypes.any.isRequired,
  parents: PropTypes.any,
};

export default Form.create({ name: 'CategForms' })(UpdateCategoriesModal);
