/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types,camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import CardLayout from '../../components/CardLayout';
import {
  fetchProductsAction,
  /* openModal, */ closeModal,
  updateProduct,
} from '../../redux/ActionsAndReducers/Products';
import {
  toggle_products_filter_state_action,
  saveFilterAction,
  toggle_inventory_modal_action,
} from '../../redux/app/actions';
// import ProductsModal from '../../components/ProductsModal';
import {
  requestExportData /* showSider, hideSider */,
} from '../../redux/ActionsAndReducers/Ui';
import { showSiderAction, hideSiderAction } from '../../redux/sider/actions';
import renderMixin from '../../mixins/product.render.mixin';
import './style.css';

class ProductsScreenNew extends renderMixin(CardLayout) {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      filters: {},
      search: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    const { items, fetch, pagination, total } = this.props;
    if (!items || items.length === 0) {
      fetch({
        tableOptions: {
          pagination: { current: 1, pageSize: pagination.limit },
          filters: {},
          sorters: {
            // columnKey: 'lokasjon_new',
            // field: 'lokasjon_new',
            // order: 'ascend',
          },
        },
        range: {
          start: moment().startOf('month'),
          end: moment().endOf('month'),
        },
        total: total || 0,
      });
    }
  }
}

const mapStateToProps = ({ Products, Ui, app, sider }) => ({
  items: Products.products,
  isLoading: Products.loading,
  categories: Products.categories.data || [],
  stats: Products.stats.data || [],
  total: Products.total,
  modalShown: Products.showModal,
  inModal: Products.inModal,
  modalIsLoading: Products.modalLoading,
  onExport: Ui.onExport.products,
  siderShown: sider.visible,
  isFiltered: app.products.filtered,
  sider2IsVisible: sider.visible,
  siderData: sider.data,
  pagination: Products.pagination,
  configFilters: app.filters.products || { filters: {}, search: null },
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (payload) => dispatch(fetchProductsAction(payload)),
  close: () => dispatch(closeModal()),
  saveProduct: (...args) => {
    dispatch(updateProduct(...args));
  },
  _requestExportData: (model) => dispatch(requestExportData(model)),
  showSider2: (...args) => dispatch(showSiderAction(...args)),
  hideSider2: (...args) => dispatch(hideSiderAction(...args)),
  setAppProductFilteredStateTo: (...args) =>
    dispatch(toggle_products_filter_state_action(...args)),
  saveFilter: (...args) => dispatch(saveFilterAction(...args)),
  showInventoryModal: () => dispatch(toggle_inventory_modal_action()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsScreenNew);
