import update from 'immutability-helper';

export default class Comment {
  constructor() {
    this.comments = [];
    this.isLoading = false;
    this.isSendingAComment = false;
  }

  setComments(commentsList) {
    return update(this, {
      comments: {
        $set: commentsList,
      },
    });
  }

  toggleLoading() {
    return update(this, {
      isLoading: {
        $set: !this.isLoading,
      },
    });
  }

  toggleSendingLoading() {
    return update(this, {
      isSendingAComment: {
        $set: !this.isSendingAComment,
      },
    });
  }

  clearComments() {
    return update(this, {
      comments: {
        $set: [],
      },
    });
  }
}
