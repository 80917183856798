export default {
  customer: { filtered: false },
  products: { filtered: false },
  modals: {
    createCustomer: {
      visible: false,
    },
  },
  filters: {
    products: {
      filters: {},
      search: '',
    },
    customers: {
      search: '',
      sorters: null,
    },
    invoices: {
      search: '',
      sorters: null,
    },
  },
};
