/* eslint-disable import/prefer-default-export */
import client from '../helpers/HttpClient';

export const _getCommentsForOrder = (orderId) => {
  const query = `api/comments?order=${orderId}&_sort=createdAt:DESC`;

  return client.get(query);
};

export const _sendComment = (comment) =>
  client.post('api/comments', {
    ...comment,
  });
