import React from 'react';

export default ({ children }) => (
  <span
    className="ant-divider ant-divider-horizontal ant-divider-with-text-center"
    role="separator"
  >
    <span className="ant-divider-inner-text">{children}</span>
  </span>
);
