/* eslint-disable func-names */
import { takeLatest, all, fork, put } from 'redux-saga/effects';
import {
  ACTIVITIES_FETCH_START,
  ACTIVITIES_FETCH_END,
  ACTIVITIES_CREATE_START,
  ACTIVITIES_CREATE_END,
} from '../ActionsAndReducers/Activities';
import handleError from './handleError';
import Api from '../../api/Methods';

function* fetch() {
  yield takeLatest(ACTIVITIES_FETCH_START, function* (action) {
    try {
      const results = yield Api.getOrderActivities(action.sku);
      yield put({
        type: ACTIVITIES_FETCH_END,
        payload: results.data,
      });
    } catch (e) {
      yield put({
        type: ACTIVITIES_FETCH_END,
        payload: [],
      });
      handleError(e);
    }
  });
}

function* create() {
  yield takeLatest(ACTIVITIES_CREATE_START, function* ({ payload }) {
    try {
      yield Api.createActivity(payload);
      yield put({
        type: ACTIVITIES_CREATE_END,
        sku: payload.order_sku,
      });
    } catch (e) {
      handleError(e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch), fork(create)]);
}
