import { useState, useEffect } from 'react';
import { throttle } from 'lodash-es';

const getDeviceConfig = (width) => {
  // see in https://ant.design/components/layout/
  const segments = [0, 480, 576, 768, 992, 1200, 1600];
  const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

  const index = segments.indexOf(segments.find((s) => s >= width));

  if (index >= 1) {
    return breakpoints[index - 1];
  }

  return breakpoints[breakpoints.length - 1];
};

// https://medium.com/better-programming/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568
const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
