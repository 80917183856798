/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import { all, put, takeLatest, fork } from 'redux-saga/effects';
import { get } from 'lodash-es';

import handleError from './handleError';

import {
  CUSTOMERS_LOAD_ONE,
  CUSTOMERS_LOADING,
  CUSTOMERS_LOADED,
} from '../ActionsAndReducers/Customers';
import Api from '../../api/Methods';

function* getACustomer() {
  yield takeLatest(CUSTOMERS_LOAD_ONE, function* (action) {
    try {
      yield put({ type: CUSTOMERS_LOADING });
      const response = yield Api.getOneCustomer(action.id);
      yield put({
        type: CUSTOMERS_LOADED,
        customers: [
          {
            values: get(response, 'data', {}),
            id: get(response, 'data._id', null),
          },
        ],
      });
    } catch (e) {
      yield handleError(e);
    }
  });
}

export default function* customerSaga() {
  yield all([fork(getACustomer)]);
}
