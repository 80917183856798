/* eslint-disable react/forbid-prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'antd/es/icon';
import Empty from 'antd/es/empty';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import Tag from 'antd/es/tag';
import moment from 'moment';
import Spin from 'antd/es/spin';
import Card from 'antd/es/card';
import Avatar from 'antd/es/avatar';
import TimeAgo from 'react-timeago';
import DateUtils from '../../helpers/dates';
import { reduxFetchActivities } from '../../redux/ActionsAndReducers/Activities';

import './style.css';

const { Meta } = Card;

const renderProfile = (activity) => {
  const mapImg = {
    bring: '/assets/images/etsy_bring.png',
    nettbutikk: '/assets/images/nettbutikk.png',
    simen: '/assets/images/simen.png',
    tormod: '/assets/images/tormod.png',
    marthe: '/assets/images/marthe.png',
    arild: '/assets/images/arild.jpg',
    oyvind: '/assets/images/oyvind.jpg',
    anders: '/assets/images/anders.jpg',
    'wau print': '/assets/images/wau_print.png',
    uOS: '/assets/images/uos.png',
    sendgrid: '/assets/images/sendgrid.png',
  };

  const creator = get(activity, 'creator', '');
  const src = get(
    mapImg,
    (creator || '').toLowerCase(),
    '/assets/images/profile.png'
  );

  return <Avatar src={src} />;
};

const renderDate = (activity) => {
  // interpret moment as local date
  const refDate = moment.utc(get(activity, 'date', undefined)).local();

  const isToday = DateUtils.isToday(refDate);
  const isYesterday = DateUtils.isYesterday(refDate);

  // eslint-disable-next-line no-nested-ternary
  return isToday ? (
    <TimeAgo
      date={refDate.toDate()}
      title={refDate.format('DD.MM.YYYY HH:mm:ss')}
    />
  ) : isYesterday ? (
    'Yesterday'
  ) : (
    refDate.format('DD.MM.YYYY HH:mm:ss')
  );
};
const NewActivities = ({ order, items, fetch, loading, stream }) => {
  const [innerHeight, setHeight] = useState(window.innerHeight - 100);
  useEffect(() => {
    const sku = get(order, 'values.number', null);
    if (sku) {
      fetch(sku);
    }
  }, [order]);

  useEffect(() => {
    // scroll to bottom when got items
    const elements = document.getElementsByClassName('activities-container');
    if (elements && elements.length > 0) {
      elements[0].scrollTop = elements[0].scrollHeight;
    }
  }, [items]);

  const _items = items.map((item) => ({...item, stream: !item.stream ? 'order' : item.stream }))
  .filter((item) => item.stream === stream);

  return (
    <div
      className="activities-container"
      style={{ maxHeight: `${innerHeight}px`, minHeight: '100px' }}
    >
      <Spin spinning={loading}>
        {_items.length < 1 || loading ? (
          <Empty description="No activities yet" />
        ) : (
          _items.map((activity, index) => (
            <div key={`activity-${index}`} className="activity-class">
              <Meta
                avatar={renderProfile(activity)}
                title={
                  <>
                    {get(activity, 'creator', '')}
                    <small style={{ float: 'right', color: 'grey' }}>
                      {renderDate(activity)}
                    </small>
                  </>
                }
                // description={get(activity, 'activity', 'No activity')}
                description={
                  <div
                  dangerouslySetInnerHTML={{
                    __html: (get(activity, 'activity', 'No activity') || '').replace(/\/n/g, '<br/>'),
                  }}
                />
                }
              />
              {/* <p style={{ display: 'inline' }}>
                <Icon type="check-circle" theme="filled" style={{ color: 'green' }} />
                {' '}
                {moment(get(activity, 'date', undefined)).format('YYYY.MM.DD HH:mm:ss')}
                {'  '}
              </p>
              <Tag color="#f50">{get(activity, 'creator', '')}</Tag>
              <em>
                {get(activity, 'activity', 'No activity')}
          </em> */}
            </div>
          ))
        )}
      </Spin>
    </div>
  );
};

NewActivities.defaultProps = {
  stream: 'order'
}

NewActivities.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.shape({}).isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  stream: PropTypes.string,
};

const mapStateToProps = ({ Activities }) => ({
  loading: Activities.loading,
  items: Activities.items,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (sku) => dispatch(reduxFetchActivities(sku)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewActivities);
