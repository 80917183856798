import update from 'immutability-helper';
import { SIDER_HIDE, SIDER_SHOW } from './actions';
import defaultState from './constants';

export default {
  sider: (state = defaultState, action) => {
    switch (action.type) {
      case SIDER_SHOW:
        return update(state, {
          type: { $set: action.siderType },
          data: { $set: action.data },
          visible: { $set: true },
        });

      case SIDER_HIDE:
        return update(state, {
          type: { $set: null },
          data: { $set: null },
          visible: { $set: false },
        });

      default:
        return state;
    }
  },
};
