import { get } from 'lodash-es';
// import path from './path';
import {
  DATE_DEFAULT,
  LINKS_DEFAULT,
  BASIC_DEFAULT,
  CATEGORY_DEFAULT,
  RELATION_DEFAULT,
} from '../constants/defaultValues';

const patchValues = (order, columns) => {
  const newOrder = {
    ...order,
  };

  // re add the id inside the values
  newOrder.values._id = order.id;

  // add packinglist and order list files if not present
  const hasPackinglist =
    get(newOrder, 'values.files', []).filter(
      (f) => typeof f === 'string' && f.includes('orders/packinglist')
    ).length > 0;

  const hasOrderlist =
    get(newOrder, 'values.files', []).filter(
      (f) => typeof f === 'string' && f.includes('orders/orderlist')
    ).length > 0;

  const orderId = get(newOrder, 'id', get(newOrder, '_id', 'undefined'));

  if (!hasPackinglist) {
    newOrder.values.files.push(`orders/packinglist/${orderId}?mode=pdf`);
  }
  if (!hasOrderlist) {
    newOrder.values.files.push(`orders/orderlist/${orderId}?mode=pdf`);
  }

  // safeguard when columns is not set
  const columnsToUse = !columns ? window.columns : columns;

  // add all missing values
  columnsToUse.map((column) => {
    // do not treat orderIds
    if (column.key === 'orderId') {
      return column;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!newOrder.values.hasOwnProperty(column.key)) {
      switch (column.type) {
        case 'date':
          newOrder.values[column.key] = DATE_DEFAULT;
          break;
        case 'links':
          newOrder.values[column.key] = LINKS_DEFAULT;
          break;
        case 'category':
          newOrder.values[column.key] = CATEGORY_DEFAULT;
          break;
        case 'relation':
          newOrder.values[column.key] = RELATION_DEFAULT;
          break;
        default:
          newOrder.values[column.key] = BASIC_DEFAULT;
          break;
      }
    }

    return column;
  });

  // also add the files here if exists
  // newOrder.values.files = newOrder.files || [];
  // temp fix (add backend url if not there yet)
  // newOrder.values.files = newOrder.values.files.map((file) => {
  //   const newFileObj = { ...file };
  //   const backUrl = process.env.REACT_APP_BACKEND_URL;
  //   newFileObj.url = !newFileObj.url.includes(backUrl)
  //     ? `${path.join([backUrl, newFileObj.url])}` : newFileObj.url;

  //   return newFileObj;
  // });

  return newOrder;
};

export default patchValues;
