import NotFoundScreenComponent from '../screens/errors/404';
import LoginScreenComponent from '../screens/authentication/Login';
import ResetPasswordScreenComponent from '../screens/authentication';
import DashboardScreenComponent from '../screens/dashboard/dashboard.index';

const routes = [
  { component: LoginScreenComponent, path: '/login', meta: { auth: false } },
  {
    component: ResetPasswordScreenComponent,
    path: '/authentication',
    meta: { auth: false },
  },
  {
    component: DashboardScreenComponent,
    path: '/dashboard',
    meta: { auth: true },
  },

  // hold the catch all route when nothing matches
  { component: NotFoundScreenComponent, key: 'fallback-route-99' },
];

export default routes;
