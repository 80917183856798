/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import Pagination from 'antd/es/pagination';
import Badge from 'antd/es/badge';
import Tag from 'antd/es/tag';
import { get, omit, orderBy } from 'lodash-es';
import moment from 'moment';
import Moment from 'moment-timezone';
import MultiSearch from '../components/Invoices/MultiSearch';
import { ReactComponent as MoneyIcon } from '../components/OrderCard/money.svg';
import CardTile from '../components/CardLayout/InvoiceCardTile';
import InvoiceStatusButton from '../components/Invoices/InvoiceStatusButton/InvoiceStatusButton';

const renderMixin = (Base) =>
  class extends Base {
    renderKpisAndDetails() {
      return <></>;
    }

    renderPaginationCard() {
      const {
        isLoading,
        onExport,
        _requestExportData,
        pagination,
        total,
        _loadInvoices,
        configFilters,
      } = this.props;

      return (
        <Card
          className="card-pagination"
          style={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
            zIndex: 9,
            boxShadow: '#ccc 0px 3px 3px',
          }}
        >
          <Pagination
            defaultCurrent={7}
            total={total || 0}
            pageSize={pagination.limit}
            // size="small"
            current={pagination.offset / pagination.limit + 1}
            onChange={(page, pageSize) => {
              _loadInvoices({
                filters: { _search: configFilters.search },
                sorters: { ...configFilters.sorters },
                limit: pageSize,
                offset: (page - 1) * pageSize,
              });
            }}
          />
          {!isLoading ? (
            <Button
              type="default"
              shape="circle"
              icon={onExport ? 'loading' : 'download'}
              style={{ boxShadow: '#00000069 2px 3px 4px' }}
              onClick={() => _requestExportData('invoices', { 
                ...(configFilters.from ? { from: configFilters.from } : {}),
                ...(configFilters.to ? { to: configFilters.to } : {}),
              })}
              disabled={onExport}
            />
          ) : null}
        </Card>
      );
    }

    handleInvoiceSearch(val, sorters = null) {
      const {
        sider2IsVisible,
        hideSider2,
        pagination,
        _loadInvoices,
        saveFilter,
        configFilters,
      } = this.props;
      // Close sider if revealed
      if (sider2IsVisible) hideSider2();

      saveFilter('invoices', 'search', val);
      // Do not persist in redux if sorter is null (it means that the search is concerning the omni search)
      if (sorters !== null) saveFilter('invoices', 'sorters', sorters);

      _loadInvoices({
        limit: pagination.limit,
        offset: 0,
        filters: {
          ...pagination.filters,
          _search: encodeURIComponent(val),
        },
        sorters: {
          // If sorter is null (search from omnisearch), take the sorters saved in redux so result is correct
          // because the frontend will always show sorters from redux
          ...(sorters === null ? configFilters.sorters : {}),
          // Add the sorter from param when the value is not null because it means that user changes the sorting
          ...(sorters || {}),
        },
      });
    }

    renderSorters() {
      const { isLoading, configFilters } = this.props;

      return (
        <Col xs={24}>
          <Select
            id="sortValue"
            style={{ width: '100%' }}
            defaultValue={get(configFilters, ['sorters', 'order'], null)}
            disabled={isLoading}
            onSelect={(val) => {
              // const searchValue = document.getElementById('searchValue').value;
              const sorters =
                val !== null
                  ? {
                      field: 'ordersAmount',
                      order: val,
                    }
                  : {};
              this.handleInvoiceSearch(
                get(configFilters, ['search'], null),
                sorters
              );
            }}
          >
            <Select.Option value={null}>Sort by Forfallsdato</Select.Option>
            <Select.Option value="ascend">
              Sort by Order Volum &#9650;
            </Select.Option>
            <Select.Option value="descend">
              Sort by Order Volum &#9660;
            </Select.Option>
          </Select>
        </Col>
      );
    }

    renderSideHeaderCard() {
      const {
        isLoading,
        filtersShown,
        pagination,
        _loadInvoices,
        saveFilter,
        // showCreateModal,
        configFilters,
      } = this.props;

      return (
        <MultiSearch
          renderActions={null}
          handleOnEnterPress={(e) => {
            e.preventDefault();
            const searchValue = document.getElementById('searchValue').value;
            this.handleInvoiceSearch(searchValue);
          }}
          handleOnSearch={this.handleInvoiceSearch.bind(this)}
          isMobile={this.isMobile()}
          filtersShown={filtersShown}
          loading={isLoading}
          placeholder="Navn eller Fakturanummer"
          renderExtended={this.renderSorters.bind(this)}
          defaultSearchValue={get(configFilters, ['search'], '')}
          onStatusChange={(value) => {
            const opts = {
              limit: pagination.limit,
              offset: 0,
              filters: {
                ...omit(pagination.filters, ['status']),
              },
            };
            if (value) {
              opts.filters = { ...opts.filters, ...{ status: value || '' } };
            }
            _loadInvoices(opts);
          }}
          onDateFilterChange={(date) => {
            const dateFilters =
              date.length > 0
                ? {
                    from: Moment(date[0]).startOf('day').format('YYYY-MM-DD'),
                    to: Moment(date[1]).endOf('day').format('YYYY-MM-DD'),
                  }
                : {
                    from: undefined,
                    to: undefined,
                  };
            const opts = {
              limit: pagination.limit,
              offset: 0,
              filters: {
                ...pagination.filters,
                ...dateFilters,
              },
            };

            if(dateFilters.from && dateFilters.to) {
              saveFilter('invoices', 'from', dateFilters.from);
              saveFilter('invoices', 'to', dateFilters.to);
            }

            _loadInvoices(opts);
          }}
        />
      );
    }

    renderCardMain(item) {
      const {
        updateStatus,
        hideSider2,
        siderData,
        sider2IsVisible,
      } = this.props;

      const orderIsVisibleInSider = (i) =>
        get(siderData, '_id', 0) === i.id && sider2IsVisible;
      return (
        <>
          <Col xs={24}>
            <small>
              <strong>Dato:</strong>
            </small>{' '}
            {moment(get(item, 'values.fakturadato', '')).format('DD.MM.YYYY')}
          </Col>
          <Col
            xs={24}
            // style={{ color: '#4b860f', paddingTop: '6px', fontWeight: 'bold' }}
          >
            <InvoiceStatusButton
              value={get(item, 'values.status', '-')}
              isEditable
              btnAction={(value) => {
                if (typeof updateStatus === 'function') {
                  if (orderIsVisibleInSider(item)) {
                    hideSider2();
                  }
                  updateStatus({
                    id: get(item, 'id', ''),
                    value,
                    orderNumber: get(item, 'values.orders.values.number', ''),
                  });
                }
              }}
            />
          </Col>
        </>
      );
    }

    renderCardSecondary(item) {
      const { saveFilter } = this.props;
      let openInv = get(item, 'values.customers.open_invoices', []).filter(
        (inv) => inv.status === 'ubetalt'
      ).length;
      // Don't count self invoice as open invoice
      // Only take account status ubetalt
      openInv =
        get(item, 'values.status', '') === 'betalt'
          ? openInv
          : Math.abs(openInv);

      return (
        <Col xs={14} className="inv-details">
          <div className="custName">
            {get(item, 'values.customers.name', '-')}
          </div>
          <div className="weight">
            <MoneyIcon className="weight-icon" />
            <div className="weight-value">
              {parseFloat(
                get(item, 'values.orders.values.totalt', '0')
              ).toFixed(2)}
            </div>
          </div>
          <div>
            <small>Åpne faktura: </small>
            <Badge
              count={openInv || '-'}
              onClick={(e) => {
                if (!openInv) return;
                e.stopPropagation();
                e.preventDefault();
                saveFilter(
                  'invoices',
                  'search',
                  get(item, 'values.customers.name', '')
                );
                this.handleInvoiceSearch(
                  get(item, 'values.customers.name', '')
                );
              }}
            />
          </div>
        </Col>
      );
    }

    renderCards(_items) {
      const {
        showSider2,
        siderData,
        sider2IsVisible,
        hideSider2,
        updateStatus,
      } = this.props;
      const orderIsVisibleInSider = (item) =>
        get(siderData, '_id', 0) === item.id && sider2IsVisible;

      const itemsOrderByDesc = orderBy(_items, 'values.identifier', 'desc');

      return (
        <>
          {itemsOrderByDesc.map((item, index) => {
            const title = <Tag>{get(item, 'values.fakturanummer', '-')}</Tag>;

            return (
              <CardTile
                item={item}
                style={{ lineHeight: 2.1 }}
                active={orderIsVisibleInSider(item)}
                key={`customer-tile-${index}`}
                title={
                  <span>
                    <small>Fakturanummer:</small> {title}
                  </span>
                }
                renderMain={this.renderCardMain.bind(this)}
                renderSecondary={this.renderCardSecondary.bind(this)}
                onClick={() => {
                  if (orderIsVisibleInSider(item)) {
                    return hideSider2();
                  }
                  return showSider2('invoice', {
                    _id: item.id,
                    ...item,
                    updateStatus,
                  });
                }}
              />
            );
          })}
        </>
      );
    }
  };

export default renderMixin;
