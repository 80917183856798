import React from 'react';
import DashboardLayout from '../../components/DashboardLayout/Layout';
import dashboardRoutes from '../../routes/dashboard.routes';
import renderRoutesFromMap from '../../helpers/Routes/renderRoutesFromMap';

const DashboardScreenComponent = () => (
  <DashboardLayout>{renderRoutesFromMap(dashboardRoutes)}</DashboardLayout>
);

export default DashboardScreenComponent;
