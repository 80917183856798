/* eslint-disable import/prefer-default-export */
import Axios from 'axios';
import QueryHelper from '../helpers/query';
import client from '../helpers/HttpClient';

export const _getInvoicesPromiseOnly = (
  opts = {
    limit: 50,
    offset: 0,
    filters: {},
    sorters: {},
  }
) => {
  const query =
    `api/invoices_optimized?_limit=${opts.limit}` +
    `&_skip=${opts.offset}` +
    `${QueryHelper.buildFilters(opts.filters)}` +
    `${QueryHelper.buildSorters(opts.sorters)}`;

  return client.get(query);
}

export const _getInvoices = (cb, opts) =>
  Axios.all([_getInvoicesPromiseOnly(opts)])
    .then(
      Axios.spread((customers) => {
        cb(null, customers);
      })
    )
    .catch(cb);

export const getOneInvoice = (id) => client.get(`api/invoices/${id}`);

export const httpSearchInvoice = (query) =>
  client.get(`api/invoices/search?q=${query}`);

export const httpUpdateInvoice = (id, datas) =>
  client.put(`api/invoices/${id}`, datas);