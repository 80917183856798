import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';

import './styles.css';

const { RangePicker } = DatePicker;

const functionIsSet = (f) => typeof f === 'function';

const MultiSearch = ({
  renderActions,
  bottomActions,
  isMobile,
  filtersShown,
  handleOnEnterPress,
  handleOnSearch,
  placeholder,
  loading,
  onStatusChange,
  onDeliverMethodChange,
  onDateFilterChange,
  noExtend,
  renderExtended,
  renderFilterExtended,
  defaultSearchValue,
  size,
  statusList,
}) => {
  const [extendedSearch, showExtSearch] = useState(false);
  const cardSize = size || 'default';
  const statusOrder = [
    'Ny',
    'Pakkes',
    'Pakket',
    'Sendt',
    'Levert',
    'Kanselert',
    'Rest',
  ];
  const statuses = statusList || [
    { _id: 'Ny', count: 0 },
    { _id: 'Pakkes', count: 0 },
    { _id: 'Pakket', count: 0 },
    { _id: 'Sendt', count: 0 },
    { _id: 'Levert', count: 0 },
    { _id: 'Kanselert', count: 0 },
    { _id: 'Rest', count: 0 },
  ];

  useEffect(() => {
    if ((size || 'default') === 'small') showExtSearch(true);
  }, [size]);

  return (
    <Card
      style={{
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
      className={['filter-card', 'visible', 'card-small']
        .filter(
          (f) =>
            (f === 'card-small' && cardSize === 'small') ||
            (f !== 'card-small' && f !== 'visible') ||
            (f !== 'card-small' && !isMobile) ||
            (f !== 'card-small' && filtersShown)
        )
        .join(' ')}
    >
      {functionIsSet(renderActions) && renderActions()}
      {!extendedSearch ? (
        <Row gutter={24}>
          <Col xs={24}>
            <Form>
              <Input.Search
                style={{ margin: '0' }}
                defaultValue={defaultSearchValue || ''}
                name="search-text"
                disabled={loading}
                allowClear
                id="searchValue"
                placeholder={placeholder || 'Order number, name, email'}
                onSearch={(val) => handleOnSearch(val)}
                suffix={
                  !noExtend ? (
                    <Button
                      className="btn-search-mode"
                      type="link"
                      shape="circle"
                      onClick={() => showExtSearch(!extendedSearch)}
                    >
                      <Icon type="sliders" />
                    </Button>
                  ) : null
                }
              />
            </Form>
          </Col>
        </Row>
      ) : (
        <Row gutter={24}>
          {functionIsSet(onStatusChange) && (
            <Col xs={12}>
              <Select
                style={{ width: '100%' }}
                defaultValue="Ny"
                disabled={loading}
                onChange={onStatusChange}
              >
                <Select.Option value={null}>All statuses</Select.Option>
                {statuses
                  // Add order
                  .map((status) => {
                    const newStatus = { ...status };
                    newStatus.order = statusOrder.indexOf(status._id);
                    return newStatus;
                  })
                  // Sort by order
                  .sort((a, b) => a.order - b.order)
                  // Render the select options
                  .map((status, i) => (
                    <Select.Option
                      value={status._id}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`status-${i}`}
                    >{`${status._id} (${status.count})`}</Select.Option>
                  ))}
              </Select>
            </Col>
          )}
          {functionIsSet(onDeliverMethodChange) && (
            <Col xs={12}>
              <Select
                style={{ width: '100%' }}
                defaultValue={null}
                disabled={loading}
                onChange={onDeliverMethodChange}
              >
                <Select.Option value={null}>All Leveringsmetode</Select.Option>
                <Select.Option value="RFID">RFID</Select.Option>
                <Select.Option value="Sporingspakke">
                  Sporingspakke
                </Select.Option>
                <Select.Option value="Kaffekruset">Kaffekruset</Select.Option>
              </Select>
            </Col>
          )}
          {functionIsSet(onDateFilterChange) && (
            <Col xs={12}>
              <RangePicker
                style={{ marginTop: '1rem', width: '100%' }}
                placeholder={['From', 'To']}
                disabled={loading}
                onChange={onDateFilterChange}
              />
            </Col>
          )}
          {!noExtend && functionIsSet(renderExtended) && renderExtended()}
          {!noExtend && functionIsSet(renderFilterExtended) && renderFilterExtended()}
          <Col xs={24}>
            <Form onSubmit={handleOnEnterPress}>
              <Row style={{ marginTop: '1rem' }} gutter={24}>
                <Col xs={24}>
                  <Input.Search
                    disabled={loading}
                    style={{ margin: '0' }}
                    defaultValue={defaultSearchValue || ''}
                    name="search-text"
                    allowClear
                    id="searchValue"
                    placeholder={placeholder || 'Order number, name, email'}
                    onSearch={(val) => handleOnSearch(val)}
                  />
                </Col>

                <Col xs={24}>
                  <Button
                    type="link"
                    onClick={() => showExtSearch(!extendedSearch)}
                    style={{
                      fontSize: '80%',
                      textAlign: 'right',
                      width: '100%',
                    }}
                  >
                    Utvid søk
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {functionIsSet(bottomActions) && bottomActions()}
    </Card>
  );
};

MultiSearch.defaultProps = {
  renderActions: () => console.log('actions'),
  bottomActions: () => console.log('actions'),
  isMobile: false,
  filtersShown: true,
  placeholder: '',
  loading: false,
  onStatusChange: null,
  onDeliverMethodChange: null,
  onDateFilterChange: null,
};

MultiSearch.propTypes = {
  renderActions: PropTypes.func,
  bottomActions: PropTypes.func,
  isMobile: PropTypes.bool,
  filtersShown: PropTypes.bool,
  handleOnEnterPress: PropTypes.func.isRequired,
  handleOnSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onStatusChange: PropTypes.func,
  onDeliverMethodChange: PropTypes.func,
  onDateFilterChange: PropTypes.func,
};

export default MultiSearch;
