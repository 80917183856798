/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Icon from 'antd/es/icon';
import Col from 'antd/es/col';
import Table from 'antd/es/table';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import { get, uniq } from 'lodash-es';

import WysiwygEditor from '../../components/WysiwygEditor';
import StringsHelper from '../Strings';
import { renderSelect, renderDate } from './index';

const status = {
  icon: <Icon type="carry-out" className="section-icon" />,
  datas: [
    {
      title: '',
      path: 'number',
      render: (value) => (
        <h3
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          {value}
        </h3>
      ),
    },
    {
      title: 'Status',
      path: 'status',
      render: (value, getFieldDecorator) => (
        <Col xs={12} style={{ padding: '0px 1rem' }}>
          {renderSelect(
            value,
            ['Ny', 'Pakkes', 'Sendt', 'Levert', 'Kanselert', 'Rest'],
            'status',
            getFieldDecorator,
            'status'
          )}
        </Col>
      ),
    },
    {
      title: 'Leveringsmetode',
      path: 'leveringstype',
      render: (value, getFieldDecorator) => (
        <Col xs={12} style={{ padding: '0px 1rem' }}>
          {renderSelect(
            value,
            ['RFID', 'Sporingspakke', 'Kaffekruset'],
            'leveringsmetode',
            getFieldDecorator,
            'leveringstype'
          )}
        </Col>
      ),
    },
  ],
  withBottomLine: true,
};

const customer = {
  icon: <Icon type="user" className="section-icon" />,
  datas: [
    {
      title: 'Navn',
      path: 'kunde.name',
    },
    {
      title: 'Kommentar',
      path: 'kommentar',
      render: (value, getFieldDecorator) => (
        <div>
          <h3 style={{ fontSize: '14px', fontWeight: 'bold' }}>Kommentar:</h3>
          <Form.Item className="table-formitem-wrapper">
            {getFieldDecorator('kommentar', { initialValue: value })(
              <WysiwygEditor />
            )}
          </Form.Item>
        </div>
      ),
    },
  ],
  withBottomLine: true,
};

const store = {
  icon: <Icon type="shop" className="section-icon" />,
  withBottomLine: true,
  datas: [
    {
      title: 'Varelinjer',
      path: 'varelinjer',
      render: (value) => (
        <Form.Item className="table-formitem-wrapper">
          <Table
            size="small"
            columns={[
              { title: 'Artikkel', dataIndex: 'artikkel', key: 'artikkel' },
              { title: 'Antall', dataIndex: 'antall', key: 'antall' },
              { title: 'Tittel', dataIndex: 'tittel', key: 'tittel' },
            ]}
            dataSource={Object.entries(value).map(([key, val]) => ({
              artikkel: key,
              antall: val.amount || 0,
              tittel: val.title || '',
            }))}
            pagination={false}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Delsum',
      path: 'delsumInkMva',
    },
    {
      title: 'MVA',
      path: 'mva',
    },
    {
      title: 'Frakt',
      path: 'frakt',
    },
    {
      title: 'Rabatt',
      path: 'rabatt',
    },
    {
      title: 'Totalt',
      path: 'totalt',
    },
    {
      title: 'OrdreDato',
      path: 'ordreDato',
      render: (value, getFieldDecorator) =>
        renderDate(value, 'OrdreDato', getFieldDecorator, 'ordreDato'),
    },
  ],
};

const location = {
  icon: <Icon type="environment" className="section-icon" />,
  withBottomLine: true,
  datas: [
    {
      title: 'Fakturaadresse',
      path: 'fakturaAdresse',
      render: (value, getFieldDecorator) => (
        <div>
          <h3 style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Fakturaaddresse:
          </h3>
          <Form.Item className="table-formitem-wrapper">
            {getFieldDecorator('fakturaAdresse', { initialValue: value })(
              <WysiwygEditor />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Telefonnummer',
      path: 'telefonnummer',
    },
    {
      title: 'Epostadresse',
      path: 'kunde.email',
    },
    {
      title: 'Bedrift',
      path: 'kunde.bedrift',
    },
  ],
};

const delivery = {
  icon: <Icon type="car" className="section-icon" />,
  withBottomLine: false,
  datas: [
    {
      title: 'Leveringsadresse',
      path: 'leveringsAdresse',
      render: (value, getFieldDecorator) => (
        <div>
          <h3 style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Leveringsadresse:
          </h3>
          <Form.Item className="table-formitem-wrapper">
            {getFieldDecorator('leveringsAdresse', { initialValue: value })(
              <WysiwygEditor
                onInit={() => {
                  // recalculate the masonry layout when editor finish loading
                  if (window.masonryLayout) {
                    setTimeout(() => {
                      window.masonryLayout.layout();
                    }, 300);
                  }
                }}
              />
            )}
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Sporingsnummer',
      path: 'sporingsnummer',
    },
    {
      title: 'Sporingslink',
      path: 'sporingslink',
      render: (value) => {
        let val = Array.isArray(value) ? value : [];
        val = val.length > 0 ? val[0] : '';

        return (
          <Form.Item label="Sporingslink">
            {val === '' ? (
              'No Link'
            ) : (
              <a href={val} target="_blank">
                {val}
              </a>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'SendtDato',
      path: 'sendtDato',
      render: (value, getFieldDecorator) =>
        renderDate(value, 'SendtDato', getFieldDecorator, 'sendtDato'),
    },
    {
      title: 'LeveringsDato',
      path: 'leveringsDato',
      render: (value, getFieldDecorator) =>
        renderDate(value, 'LeveringsDato', getFieldDecorator, 'leveringsDato'),
    },
  ],
};

const delivery2 = {
  icon: <Icon type="car" className="section-icon" />,
  withBottomLine: false,
  datas: [
    {
      title: 'AlternativLevering',
      path: 'alternativLevering',
    },
    {
      title: 'Vekt',
      path: 'vekt',
    },
    {
      title: 'Rabattkode',
      path: 'nowhere',
    },
  ],
};

export const renderMediaFileLink = (
  link,
  key,
  size = 'default',
  order = null
) => {
  let _linkText = link;
  let htmlLink = link;

  if (link.includes('packinglist')) {
    _linkText = 'Packing List';
    htmlLink = StringsHelper.urlJoin([process.env.REACT_APP_BACKEND_URL, link]);
  }

  if (link.includes('orderlist')) {
    _linkText = `Order ${get(order, 'values.number', '#')}`;
    htmlLink = StringsHelper.urlJoin([process.env.REACT_APP_BACKEND_URL, link]);
  }

  if (link.includes('bring.com')) {
    _linkText = 'Bring Label';
  }

  if (link.includes('kaffelabel')) {
    _linkText = 'Kaffekruset Label';
  }
  
  if (link.includes('uploads/')) {
    _linkText = 'Shipment Label';
    htmlLink = StringsHelper.urlJoin([process.env.REACT_APP_BACKEND_URL, link]);
  }

  return (
    <Button
      size={size}
      type="primary"
      key={key}
      href={htmlLink}
      target="_blank"
      style={{ margin: '.2rem .5rem' }}
      icon="paper-clip"
    >
      {StringsHelper.truncate(_linkText, 20)}
    </Button>
  );
};

const mediaFiles = {
  icon: <Icon type="camera" className="section-icon" />,
  withBottomLine: false,
  datas: [
    {
      title: 'Media Files',
      path: 'files',
      render: (values) => {
        if (!Array.isArray(values)) {
          return null;
        }

        const valuesLinks = uniq(values).map((value, index) =>
          renderMediaFileLink(value, `media_files-${index}`)
        );

        return (
          <div>
            <h3 style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Media files :
            </h3>
            {valuesLinks}
          </div>
        );
      },
    },
  ],
};

export default {
  status,
  customer,
  store,
  location,
  delivery,
  delivery2,
  mediaFiles,
};
