import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
// import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
// import Icon from 'antd/es/icon';
import DatePicker from 'antd/es/date-picker';

import './styles.css';
import InvoiceStatusButton from '../InvoiceStatusButton/InvoiceStatusButton';

const { RangePicker } = DatePicker;
const functionIsSet = (f) => typeof f === 'function';

const MultiSearch = ({
  renderActions,
  isMobile,
  filtersShown,
  handleOnEnterPress,
  handleOnSearch,
  placeholder,
  loading,
  onStatusChange,
  noExtend,
  renderExtended,
  defaultSearchValue,
  onDateFilterChange,
  size,
}) => {
  const [extendedSearch, showExtSearch] = useState(false);
  const cardSize = size || 'default';
  const [activeStatus, setActiveStatus] = useState('');
  const [defaultValue, setDefaultValue] = useState(defaultSearchValue);

  useEffect(() => {
    if ((size || 'default') === 'small') showExtSearch(true);
  }, [size]);

  useEffect(() => {
    setDefaultValue(defaultSearchValue)
  }, [defaultSearchValue]);

  const handleChange = (val) => {
    setDefaultValue(val.target.value);
  }

  return (
    <Card
      style={{
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
      className={['filter-card', 'visible', 'card-small']
        .filter(
          (f) =>
            (f === 'card-small' && cardSize === 'small') ||
            (f !== 'card-small' && f !== 'visible') ||
            (f !== 'card-small' && !isMobile) ||
            (f !== 'card-small' && filtersShown)
        )
        .join(' ')}
    >
      {functionIsSet(renderActions) && renderActions()}
        <Row gutter={24}>
          {extendedSearch && !noExtend && functionIsSet(renderExtended) && renderExtended()}
          
          {functionIsSet(onStatusChange) && (
            <Col xs={7} style={{ marginTop: '5%' }}>
              <InvoiceStatusButton 
              isEditable
              value={activeStatus}
              btnAction={(value) => {
                setActiveStatus(value)
                onStatusChange(value);
              }}
              />
            </Col>
          )}
          
          <Col xs={17}>
            <Form onSubmit={handleOnEnterPress}>
              <Row style={{ marginTop: '1rem' }} gutter={24}>
                <Col xs={24}>
                  <Input.Search
                    disabled={loading}
                    style={{ margin: '0' }}
                    value={defaultValue}
                    name="search-text"
                    allowClear
                    id="searchValue"
                    placeholder={placeholder || 'Order number, name, email'}
                    onChange={((val) => handleChange(val))}
                    onSearch={(val) => handleOnSearch(val)}
                    /* suffix={
                      !extendedSearch && !noExtend ? (
                        <Button
                          className="btn-search-mode"
                          type="link"
                          shape="circle"
                          onClick={() => showExtSearch(!extendedSearch)}
                        >
                          <Icon type="sliders" />
                        </Button>
                      ) : null
                    } */
                  />
                </Col>

                {functionIsSet(onDateFilterChange) && (
                  <Col xs={24}>
                    <RangePicker
                      style={{ marginTop: '10px', width: '100%' }}
                      placeholder={['From', 'To']}
                      disabled={loading}
                      onChange={onDateFilterChange}
                    />
                  </Col>
                )}

               {/*  <Col xs={24}>
                  <Button
                    type="link"
                    onClick={() => extendedSearch ? showExtSearch(!extendedSearch) : () => {}}
                    style={{
                      fontSize: '80%',
                      textAlign: 'right',
                      width: '100%',
                    }}
                  >
                    Utvid søk
                  </Button>
                  </Col> */}
              </Row>
            </Form>
          </Col>
        </Row>
    </Card>
  );
};

MultiSearch.defaultProps = {
  renderActions: () => console.log('actions'),
  isMobile: false,
  filtersShown: true,
  placeholder: '',
  loading: false,
  onStatusChange: null,
};

MultiSearch.propTypes = {
  renderActions: PropTypes.func,
  isMobile: PropTypes.bool,
  filtersShown: PropTypes.bool,
  handleOnEnterPress: PropTypes.func.isRequired,
  handleOnSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onStatusChange: PropTypes.func,
};

export default MultiSearch;
