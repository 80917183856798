/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import Moment from 'moment-timezone';
import SelectableList from '../SelectableList';
import './styles.css';

const { RangePicker } = DatePicker;

const renderFooter = (changeFunc) => () => (
  <SelectableList
    style={{ width: '145px' }}
    options={[
      {
        label: 'Today',
        value: [Moment().startOf('day'), Moment().endOf('day')],
      },
      {
        label: 'Yesterday',
        value: [
          Moment().subtract(1, 'day').startOf('day'),
          Moment().subtract(1, 'day').endOf('day'),
        ],
      },
      {
        label: 'This week',
        value: [Moment().startOf('isoWeek'), Moment().endOf('isoWeek')],
      },
      {
        label: 'Last week',
        value: [
          Moment().subtract(7, 'days').startOf('isoWeek'),
          Moment().subtract(7, 'days').endOf('isoWeek'),
        ],
      },
      {
        label: 'This month',
        value: [Moment().startOf('month'), Moment().endOf('month')],
      },
      {
        label: 'Last month',
        value: [
          Moment().subtract(1, 'month').startOf('month'),
          Moment().subtract(1, 'month').endOf('month'),
        ],
      },
      {
        label: 'This year',
        value: [Moment().startOf('year'), Moment().endOf('year')],
      },
      {
        label: 'Last Year',
        value: [
          Moment().subtract(1, 'year').startOf('year'),
          Moment().subtract(1, 'year').endOf('year'),
        ],
      },
      {
        label: 'So far this week',
        value: [Moment().startOf('isoWeek'), Moment().endOf('day')],
      },
      {
        label: 'So far this month',
        value: [Moment().startOf('month'), Moment().endOf('day')],
      },
      {
        label: 'So far this year',
        value: [Moment().startOf('year'), Moment().endOf('day')],
      },
    ]}
    onChange={changeFunc}
  />
);

const RangePicker2 = ({ onChange, defaultValue, ...props }) => {
  const [pickerVisible, showPicker] = useState(false);
  const [selectedRange, setRange] = useState(defaultValue);
  const handleChange = (value) => {
    onChange(value);
    setRange(value);
    showPicker(false);
  };

  return (
    <div className="range-picker-2-container" {...props}>
      <Button
        icon="calendar"
        type="link"
        className="range-picker-2-button"
        onClick={() => showPicker(!pickerVisible)}
      />
      <RangePicker
        className="range-picker-2-picker"
        dropdownClassName="range-picker-2-picker-popup"
        size="large"
        renderExtraFooter={renderFooter(handleChange)}
        open={pickerVisible}
        onChange={handleChange}
        onBlur={() => showPicker(false)}
        value={selectedRange}
      />
    </div>
  );
};

RangePicker2.defaultProps = {
  onChange: (...args) => console.log(args),
  defaultValue: [Moment().startOf('day'), Moment().endOf('day')],
};

export default RangePicker2;
