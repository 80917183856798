import React from 'react';

import './styles.css';

const SelectableList = ({ options, onChange, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className="selectable-list-container" {...props}>
    {options.map((opt) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className="selectable-option" onClick={() => onChange(opt.value)}>
        {opt.label}
      </div>
    ))}
  </div>
);

SelectableList.defaultProps = {
  onChange: (val) => console.log(val),
  options: [],
};

export default SelectableList;
