/* eslint-disable camelcase */
import update from 'immutability-helper';
import {
  APP_TOGGLE_CUSTOMER_FILTER_STATE,
  APP_TOGGLE_PRODUCTS_FILTER_STATE,
  APP_TOGGLE_INVOICE_FILTER_STATE,
  APP_MODALS_CUSTOMER_TOGGLE,
  APP_SAVE_FILTERS,
} from './actions';
import default_state from './constants';

export default {
  app: (state = default_state, action) => {
    switch (action.type) {
      case APP_TOGGLE_CUSTOMER_FILTER_STATE:
        return update(state, {
          customer: {
            filtered: {
              $set:
                action.val !== undefined
                  ? action.val
                  : !state.customer.filtered,
            },
          },
        });
      
      case APP_TOGGLE_INVOICE_FILTER_STATE:
        return update(state, {
          invoice: {
            filtered: {
              $set:
                action.val !== undefined
                  ? action.val
                  : !state.invoice.filtered,
            },
          },
        });

      case APP_TOGGLE_PRODUCTS_FILTER_STATE:
        return update(state, {
          products: {
            filtered: {
              $set:
                action.val !== undefined
                  ? action.val
                  : !state.products.filtered,
            },
          },
        });

      case APP_MODALS_CUSTOMER_TOGGLE:
        return update(state, {
          modals: {
            createCustomer: {
              $toggle: ['visible'],
            },
          },
        });

      case APP_SAVE_FILTERS:
        return update(state, {
          filters: {
            [action.domain]: {
              [action.key]: { $set: action.filter },
            },
          },
        });

      default:
        return state;
    }
  },
};
