import update from 'immutability-helper';

export default class Customer {
  constructor() {
    this.customers = [];
    this.columns = [];
    this.isLoading = false;
    this.customerModalShow = false;
    this.customerInModal = null;
    this.total = 0;
    this.stats = {
      loaded: false,
      data: {
        count: 0,
        ordersCount: 0,
        ordersAmount: 0,
        returnRate: 0,
        avgOrdersNum: 0,
        avgAmount: 0,
      },
    };
    this.pagination = {
      limit: 50,
      offset: 0,
    };
  }

  setPagination(data) {
    return update(this, {
      pagination: {
        $set: data,
      },
    });
  }

  setStatsValues(data) {
    return update(this, {
      stats: {
        loaded: {
          $set: true,
        },
        data: {
          $set: data,
        },
      },
    });
  }

  setCurrentCustomers(customersList, total) {
    return update(this, {
      customers: {
        $set: customersList,
      },
      total: {
        $set: total,
      },
    });
  }

  setCurrentColumns(columns) {
    return update(this, {
      columns: {
        $set: columns,
      },
    });
  }

  toggleLoading() {
    return update(this, {
      isLoading: {
        $set: !this.isLoading,
      },
    });
  }

  showDetails(id) {
    return update(this, {
      customerModalShow: {
        $set: true,
      },
      customerInModal: {
        $set: id,
      },
    });
  }

  hideDetails() {
    return update(this, {
      customerModalShow: {
        $set: false,
      },
      customerInModal: {
        $set: null,
      },
    });
  }
}
