import localStorage from 'local-storage';
import { USER_OBJECT } from '../constants/localStorage';

// handle route going to /
// if connected redirect to dashboard/main
// else redirect to login
const guardSlashRoute = (to, next) => {
  if (to.path === '/') {
    const user = localStorage.get(USER_OBJECT);
    const userIsConnected = user && user.connected;
    return userIsConnected
      ? next.redirect('/dashboard/main')
      : next.redirect('/login');
  }

  return next();
};

// handle redirection from /login
// if user is already connected, just go to dashboard
const guardLoginRedirection = (to, next) => {
  const user = localStorage.get(USER_OBJECT);
  const userIsConnected = user && user.connected;
  if (to.path === '/login' && userIsConnected) {
    return next.redirect('/dashboard/main');
  }

  return next();
};

// handle guarded routes that need the user to be connected
// to be accessed
const guardAuthenticatedRoute = (to, next) => {
  const user = localStorage.get(USER_OBJECT);
  const userIsConnected = user && user.connected;
  const routeIsGuarded = to.meta && to.meta.auth;
  if (routeIsGuarded) {
    if (!userIsConnected) {
      return next.redirect('/login');
    }
  }

  return next();
};

export default [
  guardSlashRoute,
  guardLoginRedirection,
  guardAuthenticatedRoute,
];
