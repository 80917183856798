/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Upload from 'antd/es/upload';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import localStorage from 'local-storage';
import get from 'lodash-es/get';
import { USER_OBJECT } from '../../constants/localStorage';
import { notifyError } from '../../helpers/Notify';
import Strings from '../../helpers/Strings';

import './styles.css';

const imageUrl = (url) => {
  if (url.includes('http')) {
    return url;
  }

  return Strings.removeDoubleSlash(
    `${process.env.REACT_APP_BACKEND_URL}/${url}`
  );
};

const EditableAvatar = ({ imgStyles, onChange, value }) => {
  const [image, setImage] = useState(value);
  const [status, setStatus] = useState('init');

  useEffect(() => {
    setImage(value);
  }, [value]);
  // console.log('IMAGE', image, value);

  const uploadOptions = {
    name: 'files',
    action: Strings.removeDoubleSlash(
      `${process.env.REACT_APP_BACKEND_URL}/upload`
    ),
    headers: {
      authorization: `Bearer ${localStorage.get(USER_OBJECT).jwt}`,
    },
    onChange(info) {
      setStatus(info.file.status);

      if (info.file.status === 'done') {
        if (typeof onChange === 'function') {
          onChange(get(info, 'file.response[0].url', info.file));
          setImage(imageUrl(`${get(info, 'file.response[0].url', image)}`));
        }
        // console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        notifyError('Error', 'File upload failed');
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        notifyError('You can only upload JPG/PNG file!');
        return false;
      }

      return true;
    },
  };

  return (
    <div className="editable-avatar-container">
      {image ? (
        <img
          style={{ ...(imgStyles || {}), display: 'block' }}
          src={imageUrl(image)}
          alt="Avatar"
        />
      ) : (
        <div className="editable-avatar-placeholder" />
      )}
      <Upload className="editable-avatar-upload" {...uploadOptions}>
        <Button
          style={{ ...(imgStyles || {}) }}
          loading={status === 'uploading'}
        >
          Upload
          <Icon type="upload" />
        </Button>
      </Upload>
    </div>
  );
};

export default EditableAvatar;
