/* eslint-disable no-case-declarations */
import { set, cloneDeep } from 'lodash-es';
import Api from '../../api/Methods';
import { ORDER_SET } from './Orders';

export const BOOKING_REQUEST_START = 'Booking/request_start';
export const BOOKING_REQUEST_SUCCESS = 'Booking/request_success';
export const BOOKING_REQUEST_FAILED = 'Booking/request_failed';

const shippingState = {
  errored: false,
};

export default (state = shippingState, action) => {
  switch (action.type) {
    case BOOKING_REQUEST_START:
      const newState1 = cloneDeep(state);
      set(newState1, `${action.id}.loading`, true);
      set(newState1, `${action.id}.errored`, false);

      return newState1;

    case BOOKING_REQUEST_SUCCESS:
      const newState2 = cloneDeep(state);
      set(newState2, `${action.id}.loading`, false);
      set(newState2, `${action.id}.errored`, false);

      return newState2;

    case BOOKING_REQUEST_FAILED:
      const newState3 = cloneDeep(state);
      set(newState3, `${action.id}.loading`, false);
      set(newState3, `${action.id}.errored`, true);

      return newState3;

    default:
      return state;
  }
};

export const bookConsignment = (orderId) => (dispatch) => {
  dispatch({ id: orderId, type: BOOKING_REQUEST_START });
  Api.bookConsignment(orderId)
    .then(({ data }) => {
      dispatch({ id: orderId, type: BOOKING_REQUEST_SUCCESS });
      dispatch({ type: ORDER_SET, value: data, id: orderId });
    })
    .catch((err) => dispatch({ id: orderId, type: BOOKING_REQUEST_FAILED }));
};
