/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import { mapValues } from 'lodash-es';

const formLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
};

const selectStyle = { margin: '8px auto' };
const showIfFeil = (type) => (type === 'feil' ? 'block' : 'none');

const handleFieldsChange = (props, changedFields, allFields) => {
  if (props.onChange && typeof props.onChange === 'function') {
    props.onChange(
      allFields.type.value === 'feil'
        ? mapValues(allFields, (value) => value.value)
        : { type: 'manual' }
    );
  }
};

const OrderNumberField = ({ form, initialValues }) => {
  const [orderType, setType] = useState(initialValues.type || 'manual');
  const [reason, setReason] = useState(initialValues.reason || 'feilpakk');
  const [originalNumber, setNumber] = useState(
    initialValues.number || undefined
  );

  useEffect(() => {
    let changesDone = false;
    if (orderType !== initialValues.type) {
      setType(initialValues.type);
      changesDone = true;
    }
    if (reason !== initialValues.reason) {
      setReason(initialValues.reason || 'feilpakk');
      changesDone = true;
    }
    if (originalNumber !== initialValues.number) {
      setNumber(initialValues.number);
      changesDone = true;
    }
  }, [initialValues]);

  return (
    <Form {...formLayout}>
      <Form.Item className="form-line" label="Order Type">
        {form.getFieldDecorator('type', { initialValue: orderType })(
          <Select
            onChange={setType}
            placeholder="Order Type"
            style={selectStyle}
          >
            <Select.Option value="manual">Manual</Select.Option>
            <Select.Option value="feil">Feil</Select.Option>
          </Select>
        )}
      </Form.Item>

      <Form.Item
        className="form-line hide-gracefully"
        label="Reason"
        style={{ display: showIfFeil(orderType) }}
      >
        {form.getFieldDecorator('reason', { initialValue: reason })(
          <Select placeholder="Reason" style={selectStyle}>
            <Select.Option value="feilpakk">Feilpakk</Select.Option>
            <Select.Option value="ødelagt">Ødelagt</Select.Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item
        className="form-line hide-gracefully"
        label="Original Order"
        style={{ display: showIfFeil(orderType) }}
      >
        {form.getFieldDecorator('number', {
          initialValue: originalNumber,
          rules: [{ required: true }],
        })(<Input type="text" placeholder="GG123456" style={selectStyle} />)}
      </Form.Item>
    </Form>
  );
};

OrderNumberField.defaultProps = {
  initialValues: {
    type: 'manual',
    reason: 'feilpakk',
    number: undefined,
  },
};

export default Form.create({
  name: 'order_number',
  onFieldsChange: handleFieldsChange,
})(OrderNumberField);
