import { get } from 'lodash-es';
// import QueryHelper from '../helpers/query';
import client from '../helpers/HttpClient';

export const getProductsCategories = () => client.post('api/categories/find');

export const getProductsStats = () => client.get('api/products/stats');

export const updateProductCategories = (categories) =>
  client.post('api/categories/upsert', categories);

export const getProducts = async (payload) => {
  const filters = get(payload, 'filters', {});
  const sorters = get(payload, 'sorters', false);
  const pagination = get(payload, 'pagination', false);
  const search = get(payload, 'search', null);
  let basicUrl = `api/products_optimized?start=${payload.start}&end=${payload.end}`;

  if (filters) {
    Object.keys(filters).map((k) => {
      const val = get(filters, `[${k}][0]`, null);
      if (val) {
        basicUrl += `&filterBy=${k}&filterValue=${val}`;
      }

      return k;
    });
  }

  if (sorters && sorters.order) {
    basicUrl += `&orderBy=${sorters.field}&sort=${sorters.order}`;
  }

  if (pagination) {
    basicUrl += `&skip=${
      (pagination.current - 1) * pagination.pageSize
    }&limit=${pagination.pageSize}`;
  }

  return client.post(basicUrl, {
    filters,
    sorters,
    pagination: {
      skip: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
    search,
  });
};

export const updateProduct = (id, payload) =>
  client.put(`api/products/${id}`, payload);

export const addProduct = (payload) => client.post('api/products', payload);

export const httpSearchProduct = (query) =>
  client.get(`api/products/search?q=${query}`);

export const httpUpdateInventories = (payload) => client.post(`api/products/inventories`, payload);
