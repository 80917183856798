import moment from 'moment';
import Moment from 'moment-timezone';

const today = moment();
const yesterday = moment().subtract(1, 'day');

const isToday = (date) => moment(date).isSame(today, 'day');
const isYesterday = (date) => moment(date).isSame(yesterday, 'day');

const isRangeTodayOnly = (range) =>
  Moment(range[0]).isSame(Moment(), 'day') &&
  Moment(range[1]).isSame(Moment(), 'day');

export default {
  isYesterday,
  isToday,
  isRangeTodayOnly,
};
