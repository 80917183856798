import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'antd/es/button';

import './styles.css';

const functionIsSet = (f) => typeof f === 'function';

const InvoiceStatusButton = ({ value, size, isEditable, btnAction, allowUnset }) => {
  const [current, setCurrent] = useState(value);

  const toggleStatus = (v) => {
    if(current === v && allowUnset) {
      setCurrent(null);
      btnAction(null)
      return;
    }
    if(functionIsSet(btnAction)) {
      setCurrent(v);
      btnAction(v)
    }
  }

  useEffect(() => {
    setCurrent(value)
  }, [value])


  const activeUbetalt = current === 'ubetalt';
  const activeBetalt = current === 'betalt';

  return (
    <div className="invoice-status">
      <Button
        size={size}
        className={`status-btn ${activeUbetalt ? 'ubetalt' : ''}`}
        disabled={!isEditable}
        onClick={(e) =>  {
          e.preventDefault();
          e.stopPropagation();
          toggleStatus('ubetalt');
        }}
      >
        Ubetalt
      </Button>
      <Button
        className={`status-btn ml-5 ${activeBetalt ? 'betalt' : ''}`}
        size={size}
        disabled={!isEditable}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleStatus('betalt');
        }}
      >
        Betalt
      </Button>
    </div>
  );
};

InvoiceStatusButton.defaultProps = {
  isEditable: false,
  size: 'small',
  allowUnset: true
};

InvoiceStatusButton.propTypes = {
  value: PropTypes.string.isRequired,
  btnAction: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  size: PropTypes.string,
  allowUnset: PropTypes.bool,
};

export default InvoiceStatusButton;
