/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import SwipeableViews from 'react-swipeable-views';

import CardTile from './CardTile';
import Sider2 from '../Sider/Sider2';
import ResponsiveLayout from '../ResponsiveLayout';

import './style.css';
import AddInventoryModal from '../AddInventoryModal/AddInventoryModal';

class CardLayout extends ResponsiveLayout {
  constructor(props) {
    super(props);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  renderCards(items) {
    return (
      <>
        {items.map((item, index) => (
          <CardTile />
        ))}
      </>
    );
  }

  renderSkeleton(howMany = 10) {
    const content = [];

    for (let index = 0; index <= howMany; index++) {
      content.push(
        <Card
          key={`skeleton-${index}`}
          className="order-card loader-card"
          loading
          style={{ height: '93px' }}
        />
      );
    }

    return content;
  }

  renderSider(device = 'desktop') {
    if (device === 'mobile') {
      return this.isMobile() ? (
        <Sider2
          getContainer={false}
          style={{ position: 'absolute' }}
          width="100%"
        />
      ) : null;
    }
    return !this.isMobile() ? (
      <Sider2
        getContainer={false}
        style={{ position: 'absolute' }}
        width="100%"
      />
    ) : null;
  }

  render() {
    const { items, isLoading } = this.props;
    const leftContent = (
      <>
        <AddInventoryModal />
        {this.renderSider('mobile')}
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
          style={{
            overflowY: 'auto',
            height: `${window.innerHeight - 85}px`,
            position: 'relative',
          }}
        >
          {this.renderSideHeaderCard()}
          {isLoading ? this.renderSkeleton() : this.renderCards(items)}
          {this.renderPaginationCard()}
        </Col>
      </>
    );

    const rightContent = (
      <>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={16}
          xl={18}
          style={{
            position: 'relative',
            height: `${window.innerHeight - 85}px`,
            backgroundColor: 'rgb(240, 242, 245)',
            overflowY: 'auto',
          }}
        >
          {this.renderKpisAndDetails()}
          {!this.isMobile() && this.renderSider('desktop')}
        </Col>
      </>
    );

    return this.isMobile() ? (
      <>
        <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
          <SwipeableViews>
            {leftContent}
            {rightContent}
          </SwipeableViews>
        </Row>
      </>
    ) : (
      <>
        <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
          {leftContent}
          {rightContent}
        </Row>
      </>
    );
  }
}

CardLayout.defaultProps = {
  items: [],
  isLoading: true,
};

export default CardLayout;
