import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';
import allReducers from './ActionsAndReducers';
import stagingReducer from './staging/reducer';
import siderReducer from './sider/reducer';
import appReducer from './app/reducer';
import { eventHooks } from './middlewares';

const configureStore = () => {
  const middlewares = [thunk, eventHooks];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
  }

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();
  middlewares.push(sagaMiddleware);
  const middlewaresEnhancers = applyMiddleware(...middlewares);
  const store = createStore(
    combineReducers({
      ...allReducers,
      ...stagingReducer,
      ...siderReducer,
      ...appReducer,
    }),
    middlewaresEnhancers
  );

  // run the saga
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
