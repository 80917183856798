/* eslint-disable max-classes-per-file */
import axios from 'axios';
import localStorage from 'local-storage';
import { USER_OBJECT } from '../constants/localStorage';

export class HttpClient {
  constructor() {
    this.client = axios.create({
      baseURL:
        process.env.REACT_APP_BACKEND_URL || 'http://localhost:1337/dashboard',
    });

    // Redirect to login when session has expired
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if(error.response) {
          if ([401, 403].includes(error.response.status)) {
            localStorage.clear();
            // update http client
            this.setDefaultHeader('Authorization', '');
            window.location.href = '/';
          }
        }
        return Promise.reject(error);
      }
    );
  }

  setDefaultHeader(name, value) {
    if (!name) {
      throw new Error('Name and value are required');
    }

    this.client.defaults.headers.common[name] = value;
  }

  async get(...args) {
    return this.client.get(...args);
  }

  async post(...args) {
    return this.client.post(...args);
  }

  async put(...args) {
    return this.client.put(...args);
  }

  async delete(...args) {
    return this.client.delete(...args);
  }

  async patch(...args) {
    return this.client.patch(...args);
  }
}

const client = new HttpClient();
export const setupClientAuth = () => {
  const userObject = localStorage.get(USER_OBJECT);
  const userIsConnected = userObject && userObject.connected;

  if (userIsConnected && userObject.jwt) {
    client.setDefaultHeader('Authorization', `Bearer ${userObject.jwt}`);
  }
};

export default client;

export class RawHttpClient {
  constructor() {
    this.client = axios.create();
  }

  async post(...args) {
    return this.client.post(...args);
  }

  async get(...args) {
    return this.client.get(...args);
  }
}
