/* eslint-disable func-names */
import { takeLatest, fork, all, put, delay } from 'redux-saga/effects';
import { get } from 'lodash-es';
import {
  REQUEST_SET_PASSWORD_START,
  REQUEST_SET_PASSWORD_END,
  EXPORT_DATA_END,
  EXPORT_DATA_START,
  PRINT_PDFS_START,
  PRINT_PDFS_END,
  UPDATE_ORDER_LINE_START,
  UPDATE_ORDER_LINE_END,
  SEARCH_PRODUCT_END,
  SEARCH_PRODUCT_START,
  ORDER_LINE_ADD,
  ORDER_LINE_ADD_END,
  ORDER_LINE_DELETE,
  ORDER_LINE_DELETE_END,
  FETCH_ORDER_STATUSES,
  FETCH_ORDER_STATUSES_END,
} from '../ActionsAndReducers/Ui';
import { STATISTICS_FETCH_START } from '../ActionsAndReducers/Statistics';
import history from '../../helpers/history';

import Api from '../../api/Methods';
import handleError from './handleError';

function* setPassword() {
  yield takeLatest(REQUEST_SET_PASSWORD_START, function* ({ payload }) {
    try {
      yield Api.setNewPassword(payload.code, payload.password);
      yield put({ type: REQUEST_SET_PASSWORD_END });
      history.push('/');
    } catch (e) {
      handleError(e);
      yield put({ type: REQUEST_SET_PASSWORD_END });
    }
  });
}

function* exportData() {
  yield takeLatest(EXPORT_DATA_START, function* ({ model, filter }) {
    try {
      yield Api.buildCsvData(model, filter);
      yield put({ type: EXPORT_DATA_END, model });
      // Download
      window.location.href = `${process.env.REACT_APP_BACKEND_URL.replace(
        /\/?$/,
        '/'
      )}downloads/${model}.csv`;
    } catch (e) {
      handleError(e);
      yield put({ type: EXPORT_DATA_END, model });
    }
  });
}

function* printDocumentSaga() {
  yield takeLatest(PRINT_PDFS_START, function* ({ payload }) {
    try {
      // yield new Promise((r) => setTimeout(r, 4000));
      yield Api.printDocuments(payload.id);
    } catch (e) {
      handleError(e);
    } finally {
      yield put({ type: PRINT_PDFS_END });
    }
  });
}

function* handleUpdateOrderLine() {
  yield takeLatest(UPDATE_ORDER_LINE_START, function* ({ payload }) {
    try {
      const results = yield Api.httpUpdateAmounts(payload.id, payload.datas);
      const newOrder = get(results, 'data', {});
      // const newOrder = yield findOneOrder(orderId);
      yield put({ type: UPDATE_ORDER_LINE_END, newOrder });
      // Refetch the stats
      yield put({ type: STATISTICS_FETCH_START });
    } catch (e) {
      handleError(e);
      yield put({ type: UPDATE_ORDER_LINE_END, newOrder: null });
    }
  });
}

function* handleProductSearch({ query }) {
  yield delay(700);

  try {
    const results = yield Api.httpSearchProduct(query);
    yield put({
      type: SEARCH_PRODUCT_END,
      productInfo: get(results, 'data', []),
    });
  } catch (e) {
    handleError(e);
    yield put({ type: SEARCH_PRODUCT_END, productInfo: null });
  }
}

function* watchProductSearch() {
  yield takeLatest(SEARCH_PRODUCT_START, handleProductSearch);
}

function* handleOrderLineAdd() {
  yield takeLatest(ORDER_LINE_ADD, function* ({ type, ...rest }) {
    try {
      yield Api.httpEditOrderLines({ ...rest });
      const newOrder = yield Api.findOneOrder(rest.id);
      yield put({
        type: UPDATE_ORDER_LINE_END,
        newOrder: get(newOrder, 'data', null),
      });
      yield put({ type: ORDER_LINE_ADD_END });
      yield put({ type: STATISTICS_FETCH_START });
    } catch (e) {
      handleError(e);
      yield put({ type: SEARCH_PRODUCT_END, productInfo: null });
    }
  });
}

function* handleOrderLineDelete() {
  yield takeLatest(ORDER_LINE_DELETE, function* ({ type, ...rest }) {
    try {
      yield Api.httpDeleteOrderLine({ ...rest });
      const newOrder = yield Api.findOneOrder(rest.id);
      yield put({
        type: ORDER_LINE_DELETE_END,
        newOrder: get(newOrder, 'data', null),
      });
      yield put({ type: STATISTICS_FETCH_START });
    } catch (e) {
      handleError(e);
      yield put({ type: ORDER_LINE_DELETE_END, newOrder: null });
    }
  });
}

function* handleFetchOrderStatuses() {
  yield takeLatest(FETCH_ORDER_STATUSES, function* () {
    try {
      const { data } = yield Api.httpFetchOrderStatuses();
      yield put({ type: FETCH_ORDER_STATUSES_END, payload: data });
    } catch (e) {
      handleError(e);
      yield put({ type: FETCH_ORDER_STATUSES_END, payload: [] });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(setPassword),
    fork(exportData),
    fork(printDocumentSaga),
    fork(handleUpdateOrderLine),
    fork(watchProductSearch),
    fork(handleOrderLineAdd),
    fork(handleOrderLineDelete),
    fork(handleFetchOrderStatuses),
  ]);
}
