import { isEmpty } from 'lodash-es';

const buildFiltersQuery = (opts) => {
  let str = [];

  if (!opts || isEmpty(opts)) {
    return '';
  }

  str = Object.keys(opts).map((key) => `${key}=${opts[key]}`);

  return `&${str.join('&')}`;
};

const buildSortersQUery = (opts) => {
  if (!opts || isEmpty(opts) || !opts.order) {
    return '';
  }

  return `&_sort=${opts.field}&_sort_by=${opts.order === 'ascend' ? 1 : -1}`;
};

export default {
  buildFilters: buildFiltersQuery,
  buildSorters: buildSortersQUery,
};
