/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'antd/es/modal';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Inventories from './Inventories';
import { hideStageAction } from '../../redux/staging/actions';
import { renderStepAtPlace } from '../AddOrderModal/renderers';

import './styles.css';
import ValidateInventoryButtons from './ValidateInventoryButtons';

const renderInventories = (funcs, states) => (
  <Row className="addmodal-row">
    <Inventories
      setProductsState={funcs.setProducts}
      productsState={states.products}
    />
  </Row>
);

export const renderValidateButtons = (funcs, states) => (
  <ValidateInventoryButtons
    allStates={states}
    resetAllAndClose={funcs.clearAllStepsAndHide}
  />
);


const steps = [
  {
    title: 'Products',
    icon: 'dropbox',
    render: renderInventories,
    placement: 'right',
  },
  {
    title: 'Validate',
    icon: 'check',
    render: renderValidateButtons,
    placement: 'bottom',
  },
];

/**
 * Render all steps at once
 * @param {*} funcs
 * @param {*} states
 * @returns
 */
const renderAllSteps = (funcs, states) => (
  <Row className="steps-container">
    <Col xs={24} className="fullscreen-scroll-container">
      <Row>
        {renderStepAtPlace('right', steps, { funcs, states })}
      </Row>
    </Col>
    <Col xs={24} style={{ position: 'fixed', bottom: 0, left: 0 }}>
      <Row>
        {renderStepAtPlace('bottom', steps, {
          funcs,
          states,
          noSpace: true,
        })}
      </Row>
    </Col>
  </Row>
);

const AddInventoryModal = ({ visible, hide }) => {
  const [products, setProducts] = useState([]);

  const clearAllSteps = () => {
    setProducts([]);
  };

  const cancel = () => {
    setProducts([]);
    hide();
  };

  return (
    <Modal
      visible={visible}
      onCancel={cancel}
      footer={null}
      centered
      className="add-order-modal"
      destroyOnClose
    >
      <div className="screen-stage">
        {renderAllSteps(
          {
            setProducts,
            clearAllSteps,
            clearAllStepsAndHide: cancel,
            hide,
          },
          {
            products,
          }
        )}
      </div>
    </Modal>
  );
};

export default connect(
  ({ staging }) => ({
    visible: staging.visible,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        hide: hideStageAction,
      },
      dispatch
    ),
  })
)(AddInventoryModal);
