import client from '../helpers/HttpClient';

export const fetchUsers = () => client.get('users');

export const registerUser = ({ name, email, role }) =>
  client.post('users', {
    username: name,
    email,
    role,
    password: Date.now(),
  });

export const deleteUser = (id) => client.delete(`users/${id}`);

export const updateUser = (id, payload) =>
  client.put(`users/${id}`, {
    username: payload.name,
    email: payload.email,
    role: payload.role,
  });
