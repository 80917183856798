/* eslint-disable func-names */
import { takeLatest, all, fork, put, delay } from 'redux-saga/effects';
import { get, has } from 'lodash-es';
import handleError from '../sagas/handleError';
import handleSuccess from '../sagas/handleSuccess';
import {
  SEARCH_CUSTOMER_START,
  SEARCH_CUSTOMER_END,
  SEARCH_PRODUCT_START,
  SEARCH_PRODUCT_END,
  CREATE_ORDER_START,
  CREATE_ORDER_END,
  PRODUCT_LIST_SET_PRODUCT,
  PRODUCT_LIST_SET_RESOLVED_PRODUCT,
  UPDATE_INVENTORY_START,
  UPDATE_INVENTORY_END,
} from './actions';
import Api from '../../api/Methods';

function* handleCustomerSearch({ email }) {
  yield delay(700);

  try {
    const results = yield Api.httpSearchCustomer(email);
    yield put({
      type: SEARCH_CUSTOMER_END,
      customers: get(results, 'data', []),
    });
  } catch (e) {
    handleError(e);
    yield put({ type: SEARCH_CUSTOMER_END, customers: [] });
  }
}

function* watchCustomerSearch() {
  yield takeLatest(SEARCH_CUSTOMER_START, handleCustomerSearch);
}

function* handleProductSearch({ query }) {
  yield delay(700);

  try {
    const results = yield Api.httpSearchProduct(query);
    yield put({ type: SEARCH_PRODUCT_END, products: results.data || [] });
  } catch (e) {
    handleError(e);
    yield put({ type: SEARCH_PRODUCT_END, products: [] });
  }
}

function* watchProductsSearch() {
  yield takeLatest(SEARCH_PRODUCT_START, handleProductSearch);
}

function* handleOrderCreate() {
  yield takeLatest(CREATE_ORDER_START, function* ({ payload, afterCreate }) {
    try {
      yield Api.httpCreateOrder(payload);
    } catch (e) {
      handleError(e);
    } finally {
      yield put({ type: CREATE_ORDER_END });
      if (afterCreate && typeof afterCreate === 'function') {
        afterCreate();
      }
    }
  });
}

function* handleProductAdd() {
  yield takeLatest(PRODUCT_LIST_SET_PRODUCT, function* ({ line, index }) {
    if (has(line, 'product._id')) {
      yield put({
        type: PRODUCT_LIST_SET_RESOLVED_PRODUCT,
        line: { ...line, loading: false },
        index,
      });
      return;
    }
    try {
      const results = yield Api.httpSearchProduct(line.product.navn);
      if (results.data.length === 1) {
        yield put({
          type: PRODUCT_LIST_SET_RESOLVED_PRODUCT,
          line: { ...line, product: results.data[0], loading: false },
          index,
        });
        return;
      }
      const errorMessage =
        results.data.length > 1
          ? 'Product is matching more than one'
          : 'Product is matching none';

      throw new Error(errorMessage);
    } catch (e) {
      handleError(e);
      yield put({
        type: PRODUCT_LIST_SET_RESOLVED_PRODUCT,
        line: { ...line, loading: false, errored: true },
        index,
      });
    }
  });
}

function* handleUpdateInventories() {
  yield takeLatest(UPDATE_INVENTORY_START, function* ({ payload, afterUpdate }) {
    try {
      yield Api.httpUpdateInventories(payload);
    } catch (e) {
      handleError(e);
    } finally {
      yield put({ type: UPDATE_INVENTORY_END });
      handleSuccess("Inventories successfully updated");
      if (afterUpdate && typeof afterUpdate === 'function') {
        afterUpdate();
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(watchCustomerSearch),
    fork(watchProductsSearch),
    fork(handleOrderCreate),
    fork(handleProductAdd),
    fork(handleUpdateInventories),
  ]);
}
