/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import get from 'lodash-es/get';
import each from 'lodash-es/each';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import AutocompleteLocation from '../AutocompleteLocation/AutocompleteLocation';

const CustomerLeveringAddress = (props) => {
  const { form, customer, setFieldHasChanged } = props;
  const [values, setValues] = useState({});

  const handlePlaceSelected = (place) => {
    const { address_components } = place;
    let leveringadresse = '';
    let code = '';
    let leveringpostnummer = '';
    let leveringpoststed = '';
    each(address_components, (comp) => {
      if (comp.types.includes('postal_town')) {
        leveringpoststed = comp.long_name;
      }
      if (comp.types.includes('postal_code')) {
        leveringpostnummer = comp.long_name;
      }
      if (comp.types.includes('route')) {
        leveringadresse = comp.long_name;
      }
      if (comp.types.includes('street_number')) {
        code = comp.long_name;
      }
    });

    setValues({
      leveringadresse: `${leveringadresse} ${code}`,
      leveringpostnummer,
      leveringpoststed
    });
  };

  useEffect(() => {
    const asValues = get(customer, 'values', {});
    const { leveringadresse, leveringpostnummer, leveringpoststed } = asValues;
    setValues({
      leveringadresse,
      leveringpostnummer,
      leveringpoststed,
      leveringbutikk: form.getFieldValue('name') || ''
    });
  }, [customer]);

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values]);

  /* const handleBlur = (value) => {
    if(!value) {
      setValues({
        leveringadresse: '',
        leveringpostnummer: '',
        leveringpoststed: '',
      })
    }
  } */

  const handleChange = (value) => {
    setFieldHasChanged(true);
    setValues({
      leveringadresse: value,
      leveringpostnummer: '',
      leveringpoststed: ''
    });
  };

  const { leveringadresse, leveringpostnummer, leveringpoststed } = values;

  const leveringadresseValue =
    leveringadresse && leveringpostnummer && leveringpoststed
      ? `${Object.values(values).join(' ')} Norway`
      : '';

  return (
    <>
      {form.getFieldDecorator('leveringpostnummer', {
        initialValue: leveringpostnummer || ''
      })(<Input type="hidden" />)}
      {form.getFieldDecorator('leveringbutikk', {
        initialValue: get(customer, 'values.leverinbutikk', ''),
      })(<Input type="hidden" />)}
      {form.getFieldDecorator('leveringpoststed', {
        initialValue: leveringpoststed || ''
      })(<Input type="hidden" />)}
      <Form.Item label="Leveringsadresse">
        {form.getFieldDecorator('leveringadresse', {
          initialValue: leveringadresseValue
        })(
          <AutocompleteLocation
            asValue={leveringadresseValue}
            onChange={handleChange}
            // onBlur={handleBlur}
            onPlaceSelected={handlePlaceSelected}
          />
        )}
      </Form.Item>
    </>
  );
};

export default React.memo(CustomerLeveringAddress);
