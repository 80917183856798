import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound404 from '../errors/404';
import ResetPassword from './ResetPassword';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/reset-password`} component={ResetPassword} />

    <Route component={NotFound404} />
  </Switch>
);
