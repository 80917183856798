import Compare from './Compare';
import Blank from './Blank';
import Pie from './Pie';
import RangeLabel from './RangeLabel';

export default {
  Compare,
  Blank,
  Pie,
  RangeLabel,
};
