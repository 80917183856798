import { all, fork } from 'redux-saga/effects';
import report from './Reports';
import order from './Orders';
import product from './Products';
import brukere from './Brukere';
import ui from './Ui';
import activities from './Activities';
import customers from './Customers';
import tableReports from './TableReports';
import staging from '../staging/sagas';
import statistics from './Statistics';
import invoices from './Invoices';

export default function* rootSaga() {
  yield all([
    fork(report),
    fork(order),
    fork(product),
    fork(brukere),
    fork(ui),
    fork(activities),
    fork(customers),
    fork(tableReports),
    fork(staging),
    fork(statistics),
    fork(invoices),
  ]);
}
