/* eslint-disable no-loop-func */
import Moment from 'moment-timezone';
import { find, get, omit } from 'lodash-es';

const STATS_TYPE_TOTAL = 'total';
const STATS_TYPE_CATEGORY = 'category';

const getStatsForDateRange = (
  datas,
  type,
  start,
  end,
  dataType = STATS_TYPE_TOTAL
) => {
  const selector = Moment(start);
  let total = 0;
  let count = 0;
  let categoriesTotal = {};

  while (Moment(selector).isSameOrBefore(Moment(end))) {
    const data = find(
      datas,
      (_data) =>
        _data.type === 'daily_report' &&
        _data.title === `${selector.format('YYYY')}_${type}`
    );
    // get the daily and add
    const daily = get(data, ['values', selector.format('YYYY-MM-DD')], {
      count: 0,
      total: 0,
    });
    if (dataType === STATS_TYPE_TOTAL) {
      total += daily.total;
      count += daily.count;
    } else {
      const _dailyNoCountTotal = omit(daily, ['total', 'count']);
      Object.keys(_dailyNoCountTotal).map((category) => {
        categoriesTotal = {
          ...categoriesTotal,
          [category]:
            get(_dailyNoCountTotal[category], 'total', 0) +
            get(categoriesTotal, [category], 0),
        };
        return true;
      });
    }

    selector.add(1, 'day');
  }

  return dataType === STATS_TYPE_TOTAL ? { total, count } : categoriesTotal;
};

const getSameRangeLastYear = (range) => {
  const finalRange = [
    Moment(range[0]).subtract(1, 'year'),
    Moment(range[1]).subtract(1, 'year'),
  ];

  // handle specific February case for end of month is 29,
  // should compare to February last year end of month
  if (
    Moment(range[1]).month() === 1 &&
    Moment(range[1]).isSame(Moment(range[1]).endOf('month'), 'day')
  ) {
    finalRange[1] = Moment(range[1])
      .startOf('month')
      .year(Moment(range[1]).year() - 1)
      .endOf('month');
  }

  return finalRange;
};

export default {
  getStatsForDateRange,
  getSameRangeLastYear,
  STATS_TYPE_TOTAL,
  STATS_TYPE_CATEGORY,
};
