/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import Button from 'antd/es/button';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Select from 'antd/es/select';
import Table from 'antd/es/table';
import moment from 'moment';

import AddChartModal from '../AddChartModal';
import YearPicker from '../YearPicker';
import ChartsHelper from '../../helpers/ReportChart';
import './styles.css';

const Report = ({ noDetails, loading, statistics, rangeDate }) => {
  const isDatasLoading = loading;
  const _noDetails = noDetails || false;
  const [currentAggregation, setAggr] = useState('day');
  const [currentType, setType] = useState('totalt_no_mva');
  const [statsDatas, setStats] = useState(statistics);
  const [periods, setPeriod] = useState([
    {
      start: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().endOf('year').format('YYYY-MM-DD'),
      // color: '#26b192',
      color: '#e8ccbd',
      // color: '#f7f2ef',
    },
    /* {
      start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      color: '#263644',
    }, */
  ]);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setStats(statistics);
  }, [rangeDate, statistics]);

  /**
   * Scaffold the chart and table datas
   */
  const chartDatas = ChartsHelper.buildDatas(
    // Filter out datas so that it only contains relevant datas for specified date range
    ChartsHelper.filterForRange(statsDatas, rangeDate),
    [...periods].reverse(),
    currentType,
    currentAggregation
  );
  const compareDatas = ChartsHelper.buildCompareDatas(periods);
  const compareColumns = ChartsHelper.buildCompareTableColumns(
    compareDatas,
    currentType
  );
  const renderDetails = () =>
    _noDetails || (
      <Col xs={24}>
        <Spin spinning={isDatasLoading}>
          <Table
            rowKey={(record) => record.uid}
            dataSource={compareDatas}
            columns={compareColumns}
          />
        </Spin>
      </Col>
    );
  // ///////////////////////////////

  return (
    <>
      <Row
        style={{ padding: '20px' }}
        gutter={[24, 24]}
        className="report-row-container"
      >
        <AddChartModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          addChart={({ start, end, color }) => {
            const newPeriod = [...periods];
            newPeriod.push({ start, end, color });
            setPeriod(newPeriod);
          }}
        />
        <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Col
            xs={24}
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <Select
              defaultValue={currentType}
              onChange={(val) => {
                setType(val);
              }}
              style={{ minWidth: '186px' }}
              disabled={isDatasLoading}
            >
              <Select.Option value="totalt_no_mva">
                Salgsinntekter
              </Select.Option>
              <Select.Option value="mva">MVA</Select.Option>
              <Select.Option value="antall">Antall ordre</Select.Option>
              <Select.Option value="totalt_no_mva_no_frakt">
                Salgsinntekter eks frakt
              </Select.Option>
              <Select.Option value="frakt">Frakt</Select.Option>
              <Select.Option value="rabatt">Rabatt</Select.Option>
              <Select.Option value="totalt">Totalt</Select.Option>
            </Select>
            <Select
              defaultValue={currentAggregation}
              onChange={(val) => {
                setAggr(val);
              }}
              style={{ minWidth: '186px' }}
              disabled={isDatasLoading}
            >
              <Select.Option value="day">Dag</Select.Option>
              <Select.Option value="week">Uke</Select.Option>
              <Select.Option value="month">Måned</Select.Option>
              <Select.Option value="quarter">Kvartal</Select.Option>
              <Select.Option value="year">Årlig</Select.Option>
            </Select>
          </Col>
          <Col xs={24} className="ranges-container">
            {periods.map((period, index) => (
              <YearPicker
                key={`rangepicker-${index}`}
                color={period.color}
                selected={moment(period.start).format('YYYY')}
                onSelected={(range) => {
                  setPeriod(
                    periods.map((p, i) => {
                      if (index === i) {
                        return {
                          ...p,
                          start: range.start.format('YYYY-MM-DD'),
                          end: range.end.format('YYYY-MM-DD'),
                        };
                      }
                      return p;
                    })
                  );
                }}
                onRemoved={
                  index > 0
                    ? () => {
                        setPeriod(periods.filter((p, i) => i !== index));
                      }
                    : null
                }
                min="2015"
                max="2030"
              />
            ))}
            <Button type="dashed" onClick={() => setModalVisible(true)}>
              Add temporary data
            </Button>
          </Col>
        </Col>
        <Col
          xs={24}
          style={{
            width: '97%',
          }}
        >
          <Spin spinning={isDatasLoading}>
            <Bar
              data={chartDatas}
              redraw
              width="100%"
              height="450px"
              options={ChartsHelper.buildChartOptions(
                [...periods].reverse(),
                currentAggregation
              )}
            />
          </Spin>
        </Col>
        {renderDetails()}
      </Row>
    </>
  );
};

const mapStateToProps = ({ Statistics }) => ({
  statistics: Statistics.datas,
  loading: Statistics.loading,
  rangeDate: Statistics.range,
});

export default connect(mapStateToProps, null)(Report);
