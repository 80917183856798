/* eslint-disable import/prefer-default-export */
export const defaultState = {
  datas: null,
  step: 'NO_STEP',
  visible: false,
  searchingCustomer: false,
  csutomerSearchResults: [],
  productsSearchResults: [],
  creatingOrder: false,
  products: [],
  updatingInventory: false,
};
