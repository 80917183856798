export const DATE_DEFAULT = null;
export const LINKS_DEFAULT = [];
export const BASIC_DEFAULT = '';
export const CATEGORY_DEFAULT = '';
export const RELATION_DEFAULT = [];
export const DATE_FORMAT = 'DD.MM.YYYY';
export const ADMIN_ROLE = '5e60bbfb677be3605882852c';
export const LAGER_ROLE = '5f3a55b04b0de76f1f05ab1d';
export const ORDER_DRAWER_WIDTH = '65%';
export const MOBILE_SCREEN_BREAKPOINT = 761;
export const CUSTOMERS_TABLE_COLUMNS = [
  {
    title: 'Customer ID',
    dataIndex: 'customerId',
    key: 'customerId',
    searchable: true,
    visible: true,
    type: 'basic',
  },
  {
    title: 'Navn',
    dataIndex: 'name',
    key: 'name',
    visible: true,
    searchable: true,
    type: 'basic',
    tags: ['form', 'dashboard'],
  },
  {
    title: 'Adresse',
    dataIndex: 'adresse',
    key: 'adresse',
    searchable: true,
    visible: true,
    type: 'basic',
  },
  {
    title: 'Postnummer',
    dataIndex: 'postnummer',
    key: 'postnummer',
    searchable: false,
    visible: true,
    type: 'basic',
  },
  {
    title: 'Poststed',
    dataIndex: 'poststed',
    key: 'poststed',
    searchable: true,
    visible: true,
    type: 'basic',
  },
  {
    title: 'Epost',
    dataIndex: 'email',
    key: 'email',
    searchable: true,
    type: 'basic',
  },
  {
    title: 'Telefonnummer',
    dataIndex: 'phone',
    key: 'phon',
    searchable: true,
    type: 'basic',
  },
  {
    title: 'Orders',
    dataIndex: 'orders',
    key: 'orders',
    searchable: false,
    visible: false,
    type: 'relation',
  },
  {
    title: 'Antall Ordre',
    dataIndex: 'ordersNumber',
    key: 'ordersNumber',
    searchable: false,
    visible: true,
    type: 'number',
  },
  {
    title: 'Totalkjøp',
    dataIndex: 'ordersAmount',
    key: 'ordersAmount',
    searchable: false,
    visible: true,
    type: 'number',
  },
  {
    title: 'Totalkjøp Eks Mva',
    dataIndex: 'ordersEksMvaAmount',
    key: 'ordersEksMvaAmount',
    searchable: false,
    visible: true,
    type: 'number',
  },
  {
    title: 'Nivå',
    dataIndex: 'ordersLevel',
    key: 'ordersLevel',
    searchable: true,
    visible: true,
    type: 'category',
    options: ['Bronsje', 'Sølv', 'Gull', 'Diamant'],
  },
  {
    title: 'Nyhetsbrev',
    dataIndex: 'nyhetsbrev',
    key: 'nyhetsbrev',
    searchable: false,
    visible: true,
    width: 120,
    type: 'basic',
  },
  {
    title: 'Firmanavn',
    dataIndex: 'company',
    key: 'company',
    searchable: false,
    visible: true,
    width: 120,
    type: 'basic',
  },
  {
    title: 'Organisasjonsnummer',
    dataIndex: 'company_number',
    key: 'company',
    searchable: false,
    visible: true,
    width: 120,
    type: 'basic',
  },
];

export const KATEGORI_ORDERS = [
  'Familie',
  'Figurer',
  'Signature',
  'Kjæledyr',
  'Engler',
  'Julekrybbe',
  'Ornament',
  'Kaketopp',
  'By Trygve Skaug',
  'Krus & Kopper',
  'Vaser',
  'Krus',
  'Duftlys',
  'Termoflasker',
  'Termokopper',
  'Drikkeflasker',
  'Matbokser',
  'Kjøkkenhåndklær',
  'Håndsprit',
];
