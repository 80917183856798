/* eslint-disable react/jsx-props-no-spreading,react/forbid-prop-types */
import React from 'react';
import Drawer from 'antd/es/drawer';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideSiderAction, showSiderAction } from '../../redux/sider/actions';
import { resolveDrawerCntnt } from './helpers.sider';

import './style.css';

const Sider2 = (props) => {
  const { type, visible, data, hide, show, ...restProps } = props;
  const { title, content, extraDrawerProps } = resolveDrawerCntnt(props);

  return (
    <Drawer
      title={title}
      onClose={hide}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      mask={false}
      maskClosable
      destroyOnClose
      className="sider-order"
      {...restProps}
      {...extraDrawerProps}
    >
      {content}
    </Drawer>
  );
};

Sider2.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.any,
  data: PropTypes.any,
  hide: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
};

Sider2.defaultProps = {
  type: null,
  data: null,
};

const mapStateToProps = ({ sider }) => ({
  visible: sider.visible,
  type: sider.type,
  data: sider.data,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      hide: hideSiderAction,
      show: showSiderAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sider2);
