/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Row from 'antd/es/row';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Autocomplete from 'antd/es/auto-complete';
import { find } from 'lodash-es';
import Collapse from 'antd/es/collapse';

import withOrderCreate from './ValidateOrderHOC';
import Divider from '../Divider';
import OrderNumberField from './OrderNumberFields';
import {
  searchCustomerAction,
  clearSearchAction,
} from '../../redux/staging/actions';
import CustomerLeveringAddress from '../Forms/CustomerLeveringAddress';

const { Option } = Autocomplete;
const { Panel } = Collapse;

const renderCustomerInformations = (customer) => {
  const conf = [
    { label: 'Kunde ID', key: 'kundenummer' },
    { label: 'Email', key: 'email' },
    { label: 'Name', key: 'name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Country', key: 'country' },
    { label: 'Address Line 1', key: 'adresse' },
    { label: 'Address Line 2', key: 'NOT_EXIST' },
    { label: 'Poststed', key: 'poststed' },
    { label: 'Postnummer', key: 'postnummer' },
    { label: 'Firmanavn', key: 'company' },
    { label: 'Organisasjonsnummer', key: 'company_number' },
    { label: 'Leveringsadresse', key: 'leveringadresse' },
  ];

  const formLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form {...formLayout} style={{ marginTop: '1rem' }}>
      <Collapse>
        <Panel header={customer.name} key="1">
          {conf.map((c) => {
            switch (c.type) {
              case 'textarea':
                return (
                  <Form.Item label={c.label} className="form-line">
                    <div
                      className="form-value"
                      dangerouslySetInnerHTML={{
                        __html: (customer[c.key] || '').replace(
                          /\/n/g,
                          '<br/>'
                        ),
                      }}
                    />
                  </Form.Item>
                );

              default:
                return (
                  <Form.Item label={c.label} className="form-line">
                    <div className="form-value">{customer[c.key] || ''}</div>
                    {/* <Input type="text" defaultValue={customer[c.key] || ''} disabled /> */}
                  </Form.Item>
                );
            }
          })}
        </Panel>
      </Collapse>
    </Form>
  );
};

const CustomerStep = ({
  form,
  searchResults,
  search,
  clearSearch,
  setCustomerState,
  customerState,
  setStepState,
  setOtherState,
  otherState,
  createOrder,
}) => {
  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const validatorInputRef = React.useRef(null);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [customer, _setCustomer] = useState(customerState || null);
  const setCustomer = (cust) => {
    _setCustomer(cust);
    setCustomerState(cust);
    if (cust) {
      setOtherState({
        ...otherState,
        shipping_name: cust.name,
        shipping_addressline_1: cust.leveringadresse || cust.adresse,
        shipping_adressline_2: '',
        shipping_postnummer: cust.leveringpostnummer || cust.postnummer,
        shipping_poststed: cust.leveringpoststed || cust.poststed,
        shipping_phone: cust.phone,
        wc_id: cust.wc_id
      });
      // when a customer is set, move focus to validateInput so that we can validate the form
      // from there
      validatorInputRef.current.focus();
    }
  };

  const handleCustomerCreate = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {};
        payload.kundenummer = values.new_kundenummer;
        payload.email = values.new_email;
        payload.name = values.new_name;
        payload.country = 'NO';
        payload.adresse = values.new_adresse;
        payload.postnummer = values.new_postnummer;
        payload.poststed = values.new_poststed;
        payload.phone = values.new_phone;
        payload.fajturaadresse = `${values.new_name}/n${values.new_adresse}/n${values.new_postnummer}/n${values.new_poststed}`;
        payload.company = values.new_firmanavn || '';
        payload.company_number = values.new_organisasjonsnummer || '';
        payload.leveringadresse = values.leveringadresse;
        payload.leveringpostnummer = values.leveringpostnummer;
        payload.leveringpoststed = values.leveringpoststed;
        payload.wc_id = values.wc_id;
        setCustomer(payload);
      }
    });
  };

  const orderStatusIsValid = () => {
    if (otherState.orderType.type === 'feil') {
      return (
        otherState.orderType.number !== '' &&
        otherState.orderType.number !== null &&
        otherState.orderType.number !== undefined
      );
    }

    return true;
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <>
      <Form
        layout="horizontal"
        labelCol={{
          xs: { span: 24 },
        }}
        wrapperCol={{
          xs: { span: 24 },
        }}
        className="addmodal-form"
      >
        <Divider>Kommentar</Divider>
        <Form.Item className="fullwidth">
          <Input.TextArea
            id="kommentar-field"
            style={{ width: '100%' }}
            placeholder="Kommentar"
            autoSize
            value={otherState.kommentar || ''}
            onChange={(val) =>
              setOtherState({
                ...otherState,
                kommentar: val.currentTarget.value,
              })
            }
          />
        </Form.Item>
      </Form>
      <Form
        layout="horizontal"
        {...formLayout}
        className="addmodal-form"
        onSubmit={handleCustomerCreate}
      >
        <Divider>
          Kunde{' '}
          {((isNewCustomer && !customer) || customer) && (
            <Button
              style={{ marginLeft: '8px' }}
              type="primary"
              size="small"
              onClick={() => {
                clearSearch();
                setCustomer(null);
                setIsNewCustomer(false);
              }}
            >
              {isNewCustomer && !customer ? 'Select existing' : 'Change'}
            </Button>
          )}
        </Divider>
        {!isNewCustomer && !customer && (
          <Form.Item className="fullWidth">
            {form.getFieldDecorator('email')(
              <Autocomplete
                optionLabelProp="label"
                placeholder="Navn | Epost | Kunde Id | Adresse"
                onSelect={(value) => {
                  if (value === 'CREATE_CUSTOMER') {
                    setIsNewCustomer(true);
                  } else {
                    setCustomer(find(searchResults, (o) => o.email === value));
                  }
                }}
                onSearch={(value) => {
                  if (value.length === 1) clearSearch();
                  if (value.length > 2) search(value);
                }}
              >
                {(searchResults || []).map((entry) => (
                  <Option key={entry.email} label={entry.email || ''}>
                    {`${entry.email || ''} (${entry.name || ''}) `}
                  </Option>
                ))}
                <Option key="CREATE_CUSTOMER">
                  <em>--- Create a new one ---</em>
                </Option>
              </Autocomplete>
            )}
          </Form.Item>
        )}

        {isNewCustomer && !customer && (
          <>
            <Form.Item label="Kunde ID">
              {form.getFieldDecorator('new_kundenummer', {
                rules: [{ required: true }],
              })(<Input type="text" placeholder="eg: 999" />)}
            </Form.Item>
            <Form.Item label="Email">
              {form.getFieldDecorator('new_email', {
                rules: [{ required: true }],
              })(<Input type="email" placeholder="eg: email@email.com" />)}
            </Form.Item>
            <Form.Item label="Name">
              {form.getFieldDecorator('new_name', {
                rules: [{ required: true }],
              })(<Input type="text" placeholder="eg: John Doe" />)}
            </Form.Item>
            <Form.Item label="Adress Line">
              {form.getFieldDecorator('new_adresse', {
                rules: [{ required: true }],
              })(<Input type="text" placeholder="eg: Åsbygdvegen 318 " />)}
            </Form.Item>
            <Form.Item label="Postnummer">
              {form.getFieldDecorator('new_postnummer', {
                rules: [{ required: true }],
              })(<Input type="text" placeholder="eg: 7690" />)}
            </Form.Item>
            <Form.Item label="Poststed">
              {form.getFieldDecorator('new_poststed', {
                rules: [{ required: true }],
              })(<Input type="text" placeholder="eg: Mosvik" />)}
            </Form.Item>
            <Form.Item label="Phone">
              {form.getFieldDecorator('new_phone', {
                rules: [{ required: false }],
              })(<Input type="text" placeholder="eg: +47 977 65 651" />)}
            </Form.Item>
            <Form.Item label="Firmanavn">
              {form.getFieldDecorator('new_firmanavn', {
                rules: [{ required: false }],
              })(<Input type="text" placeholder="eg: Microsoft" />)}
            </Form.Item>
            <Form.Item label="Organisasjonsnummer">
              {form.getFieldDecorator('new_organisasjonsnummer', {
                rules: [{ required: false }],
              })(<Input type="text" placeholder="eg: 123456" />)}
            </Form.Item>
           <CustomerLeveringAddress form={form} />
            <Form.Item style={{ textAlign: 'right' }} className="fullWidth">
              <Button type="primary" htmlType="submit">
                Opprett
              </Button>
            </Form.Item>
          </>
        )}

        {customer && (
          <Row gutter={[0, 24]}>
            {renderCustomerInformations(customer || {})}
            <Divider>Order Type</Divider>
            <OrderNumberField
              onChange={(values) =>
                setOtherState({
                  ...otherState,
                  orderType: values,
                })
              }
              initialValues={otherState.orderType || undefined}
            />
          </Row>
        )}

        {/* {customer && (
        <Button
          block
          disabled={!orderStatusIsValid()}
          type="primary"
          style={{ marginTop: '1.5rem' }}
          onClick={() => setStepState(1)}
        >
          Next
        </Button>
      )} */}
        <Input
          type="text"
          // Hide it from the view but make sure it is still focusable
          style={{ opacity: 0, height: 0, width: 0 }}
          ref={validatorInputRef}
          name="validator-input"
          onKeyPress={(evt) => {
            if (evt.key === 'Enter') {
              createOrder();
            }
          }}
        />
      </Form>
    </>
  );
};

const mapStateToProps = ({ staging }) => ({
  searchResults: staging.csutomerSearchResults,
  // loading: staging.searchingCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      search: searchCustomerAction,
      clearSearch: clearSearchAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'custmer_form' })(withOrderCreate(CustomerStep)));
