export const SIDER_HIDE = '@sider/hide';
export const SIDER_SHOW = '@sider/show';

export const showSiderAction = (type, data) => ({
  type: SIDER_SHOW,
  data,
  siderType: type,
});

export const hideSiderAction = () => ({
  type: SIDER_HIDE,
});
