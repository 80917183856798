/* eslint-disable func-names */
import { all, put, takeLatest, fork } from 'redux-saga/effects';
import { get } from 'lodash-es';
import {
  BRUKERE_FETCH_START,
  BRUKERE_FETCH_END,
  BRUKERE_ADD_USER_START,
  BRUKERE_ADD_USER_END,
  BRUKERE_TOGGLE_MODAL,
  BRUKERE_DELETE_USER,
  BRUKERE_DELETE_USER_END,
  BRUKERE_UPDATE_USER_START,
  BRUKERE_UPDATE_USER_END,
} from '../ActionsAndReducers/Brukere';
import handleError from './handleError';
import Api from '../../api/Methods';

function* fetchProduct() {
  yield takeLatest(BRUKERE_FETCH_START, function* () {
    try {
      const users = yield Api.fetchUsers();

      yield put({
        type: BRUKERE_FETCH_END,
        payload: get(users, 'data', []).map((u) => {
          const role = get(u, 'role.name', 'Authenticated');
          return {
            name: u.username || '',
            email: u.email || '',
            roles: role === 'Authenticated' ? ['Admin', 'Lager'] : [role],
            _id: u._id,
          };
        }),
      });
    } catch (e) {
      handleError(e);
      yield put({ type: BRUKERE_FETCH_END, payload: [] });
    }
  });
}

function* addUser() {
  yield takeLatest(BRUKERE_ADD_USER_START, function* (action) {
    try {
      yield Api.registerUser({
        ...action.payload,
      });
      // send confirmation email to user so he canset his password
      yield Api.resetPassword(action.payload.email);

      yield put({
        type: BRUKERE_ADD_USER_END,
      });
      yield put({
        type: BRUKERE_TOGGLE_MODAL,
      });
      yield put({
        type: BRUKERE_FETCH_START,
      });
    } catch (e) {
      handleError(e);
      yield put({
        type: BRUKERE_ADD_USER_END,
      });
    }
  });
}

function* _deleteUser() {
  yield takeLatest(BRUKERE_DELETE_USER, function* (action) {
    try {
      yield Api.deleteUser(action.id);
      yield put({
        type: BRUKERE_DELETE_USER_END,
      });
      yield put({
        type: BRUKERE_TOGGLE_MODAL,
      });
      yield put({
        type: BRUKERE_FETCH_START,
      });
    } catch (e) {
      handleError(e);
      yield put({
        type: BRUKERE_DELETE_USER_END,
      });
    }
  });
}

function* _updateUser() {
  yield takeLatest(BRUKERE_UPDATE_USER_START, function* (action) {
    try {
      yield Api.updateUser(action.id, action.payload);
      yield put({
        type: BRUKERE_UPDATE_USER_END,
      });
      yield put({
        type: BRUKERE_TOGGLE_MODAL,
      });
      yield put({
        type: BRUKERE_FETCH_START,
      });
    } catch (e) {
      handleError(e);
      yield put({
        type: BRUKERE_UPDATE_USER_END,
      });
    }
  });
}

export default function* BrukereSaga() {
  yield all([
    fork(fetchProduct),
    fork(addUser),
    fork(_deleteUser),
    fork(_updateUser),
  ]);
}
