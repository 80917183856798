/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';

const AutocompleteLocation = React.forwardRef((props, ref) => {
  const { onChange, onBlur, onPlaceSelected, placeholder, asValue } = props;
  
  const [value, setValue] = useState('');

  const { ref: antRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_API_KEY_MAP,
    onPlaceSelected: (place) => {
        if(typeof onPlaceSelected === "function")
        onPlaceSelected(place)
    },
    options: {
      componentRestrictions: { country: 'no' },
      types: []
    },
    libraries: ['places']
  });

  const handleOnBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur(value);
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value)
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    setValue(asValue);
  }, [asValue])

  return (
    <Input
      prefix={<Icon type="environment" />}
      placeholder={placeholder}
      ref={(c) => {
          if(c) antRef.current = c.input;
          return ref;
      }}
      value={value}
      onChange={handleChange}
      onBlur={handleOnBlur}
    />
  );
});

export default AutocompleteLocation;
