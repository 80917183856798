/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import SwipeableViews from 'react-swipeable-views';

import { loadInvoices, updateInvoiceStatus } from '../../redux/ActionsAndReducers/Invoices';
import { requestExportData } from '../../redux/ActionsAndReducers/Ui';
import CardLayout from '../../components/CardLayout/CardLayout';
import renderMixin from '../../mixins/invoice.render.mixin';
import { showSiderAction, hideSiderAction } from '../../redux/sider/actions';
import {
  toggle_invoice_filter_state_action,
  saveFilterAction,
} from '../../redux/app/actions';
import './style.css';

class InvoicesScreen extends renderMixin(CardLayout) {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchValue: '',
      sortValues: {},
    };
  }

  componentDidMount() {
    super.componentDidMount();
    const { _loadInvoices, items, toggleInvoiceFilterState } = this.props;
    if (!items || items.length <= 1) {
      toggleInvoiceFilterState(false);
      _loadInvoices();
    }
  }

  render() {
    const { items, isLoading } = this.props;

    const leftContent = (
      <>
        {this.renderSider('mobile')}
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={6}
          style={{
            overflowY: 'auto',
            height: `${window.innerHeight - 85}px`,
            position: 'relative',
          }}
        >
          {this.renderSideHeaderCard()}
          {isLoading ? this.renderSkeleton() : this.renderCards(items)}
          {this.renderPaginationCard()}
        </Col>
      </>
    );

    const rightContent = (
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={16}
        xl={18}
        style={{
          position: 'relative',
          height: `${window.innerHeight - 85}px`,
          backgroundColor: 'rgb(240, 242, 245)',
          overflowY: 'auto',
        }}
      >
        {this.renderKpisAndDetails()}
        {!this.isMobile() && this.renderSider('desktop')}
      </Col>
    );

    return this.isMobile() ? (
      <>
        <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
          <SwipeableViews>
            {leftContent}
            {rightContent}
          </SwipeableViews>
        </Row>
      </>
    ) : (
      <>
        <Row gutter={16} style={{ backgroundColor: '#f0f2f5' }}>
          {leftContent}
          {rightContent}
        </Row>
      </>
    );
  }
}

InvoicesScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,

  _loadInvoices: PropTypes.func.isRequired,
  onExport: PropTypes.bool.isRequired,
  _requestExportData: PropTypes.func.isRequired,
  toggleInvoiceFilterState: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  // isFiltered: PropTypes.bool.isRequired,
  pagination: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  _loadInvoices: (opts) => dispatch(loadInvoices(opts)),
  _requestExportData: (model, filter) => dispatch(requestExportData(model, filter)),
  showSider2: (...args) => dispatch(showSiderAction(...args)),
  hideSider2: (...args) => dispatch(hideSiderAction(...args)),
  toggleInvoiceFilterState: (...args) =>
    dispatch(toggle_invoice_filter_state_action(...args)),
  saveFilter: (...args) => dispatch(saveFilterAction(...args)),
  updateStatus: (...args) => dispatch(updateInvoiceStatus(...args))
});

const mapStateToProps = ({ Invoices, Ui, app, sider }) => ({
  isLoading: Invoices.isLoading,
  items: Invoices.invoices,
  total: Invoices.total,
  onExport: Ui.onExport.invoices,
  // isFiltered: app.invoice.filtered,
  siderData: sider.data,
  sider2IsVisible: sider.visible,
  pagination: Invoices.pagination,
  configFilters: app.filters.invoices || { search: null, sorters: {} },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesScreen);
