import moment from 'moment';

const today = () => ({
  start: moment().startOf('day'),
  end: moment().endOf('day'),
});

const yesterday = () => ({
  start: moment().subtract(1, 'day').startOf('day'),
  end: moment().subtract(1, 'day').endOf('day'),
});

const thisWeek = () => ({
  start: moment().startOf('isoWeek'),
  end: moment().endOf('isoWeek'),
});

const previousMonth = (date = undefined) => ({
  start: moment(date || undefined)
    .subtract(1, 'month')
    .startOf('month'),
  end: moment(date || undefined)
    .subtract(1, 'month')
    .endOf('month'),
});

const nextMonth = (date = undefined) => ({
  start: moment(date || undefined)
    .add(1, 'month')
    .startOf('month'),
  end: moment(date || undefined)
    .add(1, 'month')
    .endOf('month'),
});

const lastMonth = () => previousMonth();

const lastWeek = () => ({
  start: moment().subtract(7, 'days').add(1, 'day').startOf('isoWeek'),
  end: moment().subtract(7, 'days').add(1, 'day').endOf('isoWeek'),
});

const thisMonth = () => ({
  start: moment().startOf('month'),
  end: moment().endOf('month'),
});

const thisYear = () => ({
  start: moment().startOf('year').utc(),
  end: moment().endOf('year').utc(),
});

const previousYear = (date = undefined) => ({
  start: moment(date || undefined)
    .subtract(1, 'year')
    .startOf('year')
    .utc(),
  end: moment(date || undefined)
    .subtract(1, 'year')
    .endOf('year')
    .utc(),
});

const nextYear = (date = undefined) => ({
  start: moment(date || undefined)
    .add(1, 'year')
    .startOf('year'),
  end: moment(date || undefined)
    .add(1, 'year')
    .endOf('year'),
});

const lastXDays = (numberOfDays) => ({
  start: moment().subtract(numberOfDays, 'days').startOf('day'),
  end: moment().endOf('day'),
});

const lastYear = (date = undefined) => ({
  start: moment(date || undefined)
    .subtract(1, 'year')
    .startOf('year')
    .utc(),
  end: moment(date || undefined)
    .subtract(1, 'year')
    .endOf('year')
    .utc(),
});

const thisMonthLastYear = () => ({
  start: moment().subtract(1, 'year').startOf('month'),
  end: moment().subtract(1, 'year').endOf('month'),
});

export default {
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  previousMonth,
  nextMonth,
  thisYear,
  previousYear,
  nextYear,
  today,
  yesterday,
  lastXDays,
  lastYear,
  thisMonthLastYear,
};
