/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import _, { transform, isEqual, isObject } from 'lodash-es';

function difference(object, base) {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

function renameKey(obj, key, newKey) {
  if (_.includes(_.keys(obj), key)) {
    obj[newKey] = _.clone(obj[key], true);

    delete obj[key];
  }

  return obj;
}

function renameKeys(obj, opts) {
  const newObj = _.cloneDeep(obj);
  Object.keys(opts).map((key) => {
    renameKey(newObj, key, opts[key]);
  });
  return newObj;
}

export default {
  difference,
  renameKey,
  renameKeys,
};
