import { get, uniq, concat, pick } from 'lodash-es';

const mergeWithoutDupe = (...args) => {
  let result = [];
  const concatArray = (toConcat) => {
    result = concat(result, toConcat);
  };
  args.forEach(concatArray);
  const nonValidData = (data) => {
    if (data.includes('undefined')) return false;
    return data !== '';
  };

  return uniq(result.filter(nonValidData));
};

const customer = (order, defaultValue = '-') =>
  get(order, 'customer_meta[0]', get(order, 'values.kunde', defaultValue));

const mediaLinks = (order) => {
  const actualLinks = get(order, 'values.files', []);
  const linksToPopulate = [
    `orders/packinglist/${get(
      order,
      '_id',
      get(order, 'id', get(order, "_id", undefined))
    )}?mode=pdf`, // packinglist
    `orders/orderlist/${get(
      order,
      '_id',
      get(order, 'id', get(order, "_id", undefined))
    )}?mode=pdf`, // orderlist
  ];

  return mergeWithoutDupe(actualLinks, linksToPopulate);
};

const hasGiftWrappings = (order) => {
  const varelinjer = get(order, 'values.varelinjer', {});
  return Object.keys(varelinjer).includes('50000');
};

const createNewOrderPayloadForNewCustomer = (orderId, orderValue, newCustomer) => {
  const productsAr = Object.values(get(orderValue, 'ordre'));
  const products = productsAr.map((p) => ({ amount: p.amount, product: p.meta, loading: false }));
  const payload = {
    creator: get(orderValue, 'creator'),
    customer: newCustomer,
    other: {
      ...pick(orderValue, ['frakt', 'leveringsDato', 'leveringstype', 'mva', 'rabatt', 'status', 'totalt', 'vekt', 'updateFrakt']),
      orderType: { ...get(orderValue, 'orderType', {}), number: get(orderValue, 'number'), orderId },
      ordredato: get(orderValue, 'ordreDato'),
      shipping_name: newCustomer.name,
      shipping_addressline_1: newCustomer.leveringadresse || newCustomer.adresse,
      shipping_adressline_2: '',
      shipping_postnummer: newCustomer.leveringpostnummer || newCustomer.postnummer,
      shipping_poststed: newCustomer.leveringpoststed || newCustomer.poststed,
      shipping_phone: newCustomer.phone,
    },
    products,
  };
  return payload;
}

export default {
  customer,
  mediaLinks,
  hasGiftWrappings,
  createNewOrderPayloadForNewCustomer
};
