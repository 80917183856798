/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Button from 'antd/es/button';
// import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Moment from 'moment-timezone';
import Pagination from 'antd/es/pagination';
import { get } from 'lodash-es';

import MultiSearch from '../components/MultiSearch2';
import AllKpis from '../components/Kpis/AllKpis';
import OrderCard from '../components/OrderCard';
import Report from '../components/Report';

const renderMixin = (Base) =>
  class extends Base {
    renderKpisAndDetails() {
      return (
        <>
          <AllKpis
            customers={{ statsLoaded: false, stats: { count: 0 } }}
            lager={{ loading: true }}
            orders={{ loading: true }}
            size={this.isMobile() ? 'small' : 'default'}
            desktopView={!this.isMobile()}
          />
          <Col xs={24} style={{ padding: '0px 4px' }}>
            <Card className="report-card-container">
              <Report noDetails />
            </Card>
          </Col>
        </>
      );
    }

    renderPaginationCard() {
      const {
        isLoading,
        onExport,
        _requestExportData,
        total,
        _loadOrders,
        pagination,
      } = this.props;

      return (
        <Card
          className="card-pagination"
          style={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
            zIndex: 9,
            boxShadow: '#ccc 0px 3px 3px',
          }}
        >
          <Pagination
            defaultCurrent={7}
            total={total}
            pageSize={50}
            // size="small"
            current={pagination.offset / pagination.limit + 1}
            onChange={(page, pageSize) => {
              _loadOrders({
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                  ...pagination.filters,
                },
              });
            }}
          />
          {!isLoading ? (
            <Button
              type="default"
              shape="circle"
              icon={onExport ? 'loading' : 'download'}
              style={{ boxShadow: '#00000069 2px 3px 4px' }}
              onClick={() => _requestExportData('orders')}
              disabled={onExport}
            />
          ) : null}
        </Card>
      );
    }

    renderSideHeaderCard() {
      const {
        isLoading,
        _loadOrders,
        pagination,
        filtersShown,
        showStage,
        statuses,
      } = this.props;

      const renderActionsProps = {
        renderActions: () => (
          <Button
            onClick={() => {
              showStage();
            }}
            icon="plus"
            type="primary"
            shape="circle"
            size="small"
            style={{ marginTop: '5px' }}
          />
        ),
      };

      return (
        <>
          <MultiSearch
            {...renderActionsProps}
            isMobile={false}
            size={this.isMobile() ? 'small' : 'default'}
            filtersShown={filtersShown}
            handleOnEnterPress={this.handleSearch.bind(this)}
            handleOnSearch={this.handleSearchInput.bind(this)}
            placeholder="Order number, name, email"
            loading={isLoading}
            statusList={statuses}
            onStatusChange={(value) => {
              const opts = {
                limit: pagination.limit,
                offset: 0,
                filters: {
                  ...pagination.filters,
                  ...{ 'values.status': value || '' },
                },
              };
              _loadOrders(opts);
            }}
            onDeliverMethodChange={(value) => {
              const opts = {
                limit: pagination.limit,
                offset: 0,
                filters: {
                  ...pagination.filters,
                  ...{ 'values.leveringstype': value || '' },
                },
              };
              _loadOrders(opts);
            }}
            onDateFilterChange={(date) => {
              const dateFilters =
                date.length > 0
                  ? {
                      from: Moment(date[0]).startOf('day').toISOString(),
                      to: Moment(date[1]).endOf('day').toISOString(),
                    }
                  : {
                      from: undefined,
                      to: undefined,
                    };
              const opts = {
                limit: pagination.limit,
                offset: 0,
                filters: {
                  ...pagination.filters,
                  ...dateFilters,
                },
              };

              _loadOrders(opts);
            }}
          />
        </>
      );
    }

    renderCards(_orders) {
      const {
        _atomicUpdate,
        atomicUpdateState,
        showSider2,
        siderData,
        sider2IsVisible,
        hideSider2,
      } = this.props;

      return (
        <>
          {_orders.map((order, index) => (
            <OrderCard
              size={this.isMobile() ? 'small' : 'default'}
              order={order}
              key={`order-${index}`}
              active={
                get(siderData, 'values.number', null) ===
                get(order, 'values.number', 0)
              }
              atomicUpdate={_atomicUpdate}
              atomicUpdateState={atomicUpdateState}
              onClick={() => {
                const orderIsInSider =
                  get(siderData, 'values.number', null) ===
                  get(order, 'values.number', 0);
                const orderAlreadyShown = sider2IsVisible && orderIsInSider;

                return orderAlreadyShown
                  ? hideSider2()
                  : showSider2('order', order);
              }}
            />
          ))}
        </>
      );
    }
  };

export default renderMixin;
