import extensions from './extensions';

const subscriptions = {};
const fayeClient = new window.Faye.Client(
  process.env.REACT_APP_REALTIME_SERVER || ''
);
fayeClient.addExtension(extensions.registerClientId);
fayeClient.addExtension(extensions.blockOwnUpdates);
fayeClient.addExtension(extensions.sanitizeOrder);

export default {
  addListener: ({ name, channel, action }) => {
    subscriptions[name] = fayeClient
      .subscribe(channel, action)
      // eslint-disable-next-line no-console
      .then((d) =>
        console.log(`Subscribed to [${channel}] as key [${name}]`, d)
      );
  },
  removeListener: (name) => {
    if (
      subscriptions[name] &&
      typeof subscriptions[name].cancel === 'function'
    ) {
      subscriptions[name].cancel();
    }
  },
};
