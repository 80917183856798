import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from 'antd/es/button';
import { get } from 'lodash-es';
import {
  showSider as showSiderAction,
  hideSider as hideSiderAction,
} from '../../redux/ActionsAndReducers/Ui';
import { notifyError } from '../../helpers/Notify';

import Api from '../../api/Methods';

const buildSiderOpts = (order) => {
  const title = (
    <>
      <span style={{ color: '#db8556', fontWeight: 'bold' }}>
        {get(order, 'values.number', '-')}
        {'   '}
        {'>'}
      </span>
      {'       '}
      <Button type="primary" size="small">
        {get(order, 'values.kunde.name', '-')}
      </Button>
    </>
  );
  return {
    type: 'order',
    content: get(order, 'values', {}),
    width: '100%',
    title,
  };
};

const handleShowingOrderInSider = (
  id,
  showSider,
  hideSider,
  siderShown,
  setLoading
) => (e) => {
  e.preventDefault();
  setLoading(true);
  // try to find the order in DB
  Api.findOneOrder(id)
    .then(({ data }) => {
      const siderOpts = buildSiderOpts(data);
      if (siderShown) {
        hideSider();
        setTimeout(() => showSider(siderOpts), 650);
      } else {
        showSider(siderOpts);
      }
    })
    .catch((error) => notifyError(`Error opening [${id}] : ${error.message()}`))
    .finally(() => {
      setLoading(false);
    });
};

const RelatedButton = ({
  // eslint-disable-next-line camelcase
  title,
  order_id,
  showSider,
  hideSider,
  siderShown,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      icon="file"
      type="primary"
      className="related-buttons"
      loading={loading}
      onClick={handleShowingOrderInSider(
        order_id,
        showSider,
        hideSider,
        siderShown,
        setLoading
      )}
    >
      {title}
    </Button>
  );
};

const mapStateToProps = ({ Ui }) => ({
  siderShown: Ui.sider.show,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      showSider: showSiderAction,
      hideSider: hideSiderAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedButton);
