/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Table from 'antd/es/table';
import RangeLabel from '../Kpis/RangeLabel';
import RangePicker2 from '../RangePicker2/RangePicker2';

const headerStyle = {
  fontWeight: 'bolder',
  fontSize: '.9rem',
  color: 'rgba(0,0,0,.60)',
  marginBottom: 0,
};

const columns = [
  {
    title: 'Customers',
    dataIndex: 'customer',
    key: 'customer',
    width: 280,
    fixed: 'left',
  },
  {
    title: 'Sales',
    dataIndex: 'orderAmounts',
    key: 'orderAmounts',
  },
];

const CustomerStats = ({
  rangeDate,
  setRange,
  dataSource,
  isLoading,
}) => {
  const dataSorted = dataSource.sort((a, b) => b.orderAmounts - a.orderAmounts)
  return (
    <Row gutter={[8, 8]}>
      <Col lg={12} xl={12}>
        <Col xs={24}>
          <h4 style={{ ...headerStyle, lineHeight: '32px', height: '32px' }}>
            <RangeLabel range={rangeDate} />
            <RangePicker2
              style={{ float: 'left', marginRight: '10px' }}
              defaultValue={rangeDate}
              onChange={setRange}
            />
          </h4>
        </Col>
      </Col>

      <Col lg={24} xl={24}>
        <Table
          dataSource={dataSorted}
          columns={columns}
          loading={isLoading}
          pagination={false}
          size="small"
          scroll={{ y: window.innerHeight - 205 }}
        />
      </Col>
    </Row>
  )
}


CustomerStats.defaultProps = {
  // desktopView: true,
  isLoading: false,
};

CustomerStats.propTypes = {
  rangeDate: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  // desktopView: PropTypes.bool,
}

export default CustomerStats;