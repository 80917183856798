import Orders from './Orders';
import FileUploads from './FileUploads';
import Customers from './Customers';
import Comments from './Comments';
import Shipping from './Shipping';
import Ui from './Ui';
import Reports from './Reports';
import Products from './Products';
import Brukere from './Brukere';
import Activities from './Activities';
import TableReports from './TableReports';
import Statistics from './Statistics';
import Invoices from './Invoices';

export default {
  Orders,
  FileUploads,
  Customers,
  Comments,
  Shipping,
  Ui,
  Reports,
  Products,
  Brukere,
  Activities,
  TableReports,
  Statistics,
  Invoices,
};
