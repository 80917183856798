/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { get } from 'lodash-es';
import Checkbox from 'antd/es/checkbox';
import AutocompleteLocation from '../AutocompleteLocation/AutocompleteLocation';

const CustomerLocationField = (props) => {
  const { form, customer, setFieldHasChanged } = props;

  const [adrValidationStatus, setAdrValidationStatus] = useState('');

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [showOnMap, setShowOnMap] = useState(false);

  useEffect(() => {
    const v = get(customer, 'values.addressOnMap', null);
    const shMap = get(customer, 'values.showOnMap', false);
    if (v) {
      setLatitude(v.lat);
      setLongitude(v.lng);
      setShowOnMap(shMap);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({ showOnMap });
  }, [showOnMap]);

  const handlePlaceSelected = (place) => {
    if (!place.geometry && !place.geometry.location) {
      setAdrValidationStatus('warning');
      setShowOnMap(false);
      return;
    }

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    setLatitude(lat);
    setLongitude(lng);

    const { formatted_address } = place;
    form.setFieldsValue({ addressOnMap: formatted_address, lat, lng });
    setAdrValidationStatus('');
  };

  const handleShowOnMap = (e) => {
    setFieldHasChanged(true);
    if (!latitude || !longitude) {
      setAdrValidationStatus('warning');
      setShowOnMap(false);
    } else {
      const v = e.target.checked;
      setShowOnMap(v);
    }
  };

  const handleOnBlur = () => {
    if (form.isFieldsTouched()) {
      setFieldHasChanged(true);
      if (!latitude || !longitude) {
        handleShowOnMap({target: {checked: false }});
      }
    }
  };

  const handleChange = () => {
    setLatitude('');
    setLongitude('');
  };

  return (
    <>
      {form.getFieldDecorator('lat', {
        initialValue: get(customer, 'values.addressOnMap.lat', '')
      })(<Input type="hidden" />)}
      {form.getFieldDecorator('lng', {
        initialValue: get(customer, 'values.addressOnMap.lng', '')
      })(<Input type="hidden" />)}
      <Form.Item
        name="AddressOnMap"
        label="Fakturaadresse"
        validateStatus={adrValidationStatus}
        help={
          adrValidationStatus === 'warning'
            ? 'Unable to find location map, please select one.'
            : ''
        }
        style={{marginBottom: 0}}
      >
        {form.getFieldDecorator('addressOnMap', {
          initialValue: get(customer, 'values.addressOnMap.value', '')
        })(
          <AutocompleteLocation
            asValue={form.getFieldValue('addressOnMap')}
            onChange={handleChange}
            onBlur={handleOnBlur}
            onPlaceSelected={handlePlaceSelected}
          />
        )}
      </Form.Item>
      <Form.Item name="showOnMap">
        {form.getFieldDecorator('showOnMap', {
          initialValue: get(customer, 'values.showOnMap', false)
        })(
          <Checkbox checked={showOnMap} onChange={handleShowOnMap}>
            Show on map
          </Checkbox>
        )}
      </Form.Item>
    </>
  );
};

export default React.memo(CustomerLocationField);
