/* eslint-disable func-names */
import { all, put, takeLatest, fork, call } from 'redux-saga/effects';
import {
  STATISTICS_FETCH_START,
  STATISTICS_FETCH_END,
} from '../ActionsAndReducers/Statistics';
import { fetch } from '../../api/reports';

function* handleFetch() {
  yield takeLatest(STATISTICS_FETCH_START, function* ({ tz }) {
    try {
      const { data } = yield call(fetch, tz);
      yield put({ type: STATISTICS_FETCH_END, datas: data || [] });
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* reportsSaga() {
  yield all([fork(handleFetch)]);
}
