import FileUploadModel from '../DataModels/FileUpload';

// ///////////////
// Constants
// ///////////////
export const SET_FILES_LIST = 'FileUploads/set_files_list';
export const UPDATE_FILE = 'FileUploads/update_file';

// ///////////////
// Reducers
// ///////////////
const fileUploadDM = new FileUploadModel();
export default (state = fileUploadDM, action) => {
  switch (action.type) {
    case SET_FILES_LIST:
      return state.setCurrentFiles(action.fileList);
    case UPDATE_FILE:
      return state.updateFile(action.file);

    default:
      return state;
  }
};

// ///////////////
// Actions
// ///////////////
export const setFilesList = (fileList) => ({
  type: SET_FILES_LIST,
  fileList,
});

export const updateFile = (file) => ({
  type: UPDATE_FILE,
  file,
});
