/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-properties */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Col from 'antd/es/col';
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import moment from 'moment';
import { get, isEmpty, orderBy, reduce } from 'lodash-es';
import { connect } from 'react-redux';

import useBreakpoint from './breakpoints';
import { DATE_FORMAT } from '../constants/defaultValues';
import WysiwygEditor from '../components/WysiwygEditor';
import StringsHelper from './Strings';

import './style.css';

// eslint-disable-next-line max-len
export const round = (value, precision = 0) =>
  Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision);

export const buildTableValues = ({
  varelinjer,
  frakt_belop_eks_mva,
  frakt_belop_inkl_mva,
  frakt_mva,
  // total_frakt_belop_eks_mva,
  total_mva,
  totalt,
  rabatt,
}) => {
  const _rabattWithMva = round(parseFloat(rabatt || '0'), 2);
  const _rabattWithoutMva = round(_rabattWithMva / 1.25, 2);
  const _rabattMva = round(_rabattWithMva - _rabattWithoutMva, 2);
  const showNegative = (number) =>
    `${number > 0 ? '-' : ''}${round(number, 2).toFixed(2)}`;
  const _totalt = typeof totalt === 'string' ? parseFloat(totalt) : totalt;

  let rows = Object.keys(varelinjer || {}).map((k) => ({
    artikkel: k,
    antall: varelinjer[k].amount || 0,
    delivered: varelinjer[k].delivered || 0,
    tittel: StringsHelper.htmlDecode(varelinjer[k].title || ''),
    enhetspris_eks_mva: get(varelinjer, [k, 'enhetspris_eks_mva'], 0),
    belop_eks_mva: get(varelinjer, [k, 'belop_eks_mva'], 0),
    mva: get(varelinjer, [k, 'mva'], 0),
    belop_inkl_mva: get(varelinjer, [k, 'belop_inkl_mva'], 0),
    notes: get(varelinjer, [k, 'wcgwp_notes']),
    lokasjon_new: parseInt(
      (get(varelinjer, [k, "meta.lokasjon_new"], "0") || "").replace(/\D/gi, ""),
      10
    ),
  }));

  rows = orderBy(rows, 'lokasjon_new', 'asc');
  
  const reducer = (_rows, key) => reduce(_rows, (sum, n) => {
    const _sum = sum + parseFloat(get(n, key, "0"));
    // eslint-disable-next-line no-restricted-globals
    if(isNaN(_sum)) {
      return sum;
    }
    return _sum;
  }, 0);
  const rowsTotalVarelinjers = {
    artikkel: '',
    antall: '',
    delivered: '',
    tittel: '',
    enhetspris_eks_mva: '',
    belop_eks_mva: reducer(rows, 'belop_eks_mva'),
    mva: reducer(rows, 'mva'),
    belop_inkl_mva: reducer(rows, 'belop_inkl_mva'),
  }
  // Inject as a row frakt details here
  rows.push({
    artikkel: '',
    antall: '',
    delivered: '',
    tittel: '',
    enhetspris_eks_mva: <strong>Frakt</strong>,
    belop_eks_mva: <strong>{frakt_belop_eks_mva}</strong>,
    mva: <strong>{frakt_mva}</strong>,
    belop_inkl_mva: <strong>{frakt_belop_inkl_mva}</strong>,
    editFrakt: true
  });

  // Rabatt
  rows.push({
    artikkel: '',
    antall: '',
    delivered: '',
    tittel: '',
    enhetspris_eks_mva: <strong>Rabatt</strong>,
    belop_eks_mva: <strong>{showNegative(_rabattWithoutMva)}</strong>,
    mva: <strong>{showNegative(_rabattMva)}</strong>,
    belop_inkl_mva: <strong>{showNegative(_rabattWithMva)}</strong>,
  });

  // Totalt
  rows.push({
    artikkel: '',
    antall: '',
    delivered: '',
    tittel: '',
    enhetspris_eks_mva: <strong>Totalt (NOK)</strong>,
    belop_eks_mva: <strong>{parseFloat(frakt_belop_eks_mva - _rabattWithoutMva + rowsTotalVarelinjers.belop_eks_mva).toFixed(2)}</strong>,
    mva: <strong>{parseFloat(frakt_mva - _rabattMva + rowsTotalVarelinjers.mva).toFixed(2)}</strong>,
    belop_inkl_mva: <strong>{parseFloat(frakt_belop_inkl_mva - _rabattWithMva + rowsTotalVarelinjers.belop_inkl_mva).toFixed(2)}</strong>,
  });
  return rows;
};

const resolveFieldByType = (
  type,
  { value, onEdit, valueKey, form, ...props }
) => {
  let el = <div>{value}</div>;
  const _config = props.config ? props.config : { editable: true };

  switch (type) {
    case 'table':
      el = <TableField onEdit={onEdit} {...props} value={value} />;
      break;
    case 'text':
      el = [
        <Form.Item
          className={onEdit ? 'field-shown' : 'field-hidden'}
          key={`${valueKey[0]}-1`}
        >
          {form.getFieldDecorator(valueKey[0], { initialValue: value })(
            <Input
              type="text"
              size="small"
              {...props}
              disabled={!_config.editable}
              onChange={(e) =>
                props.onChange(e.currentTarget.value, props.index || 0)
              }
              onBlur={() => props.toggleEdit(false)}
              style={{ maxWidth: '100px' }}
            />
          )}
        </Form.Item>,
        <div
          key={`${valueKey[0]}-2`}
          className={!onEdit ? 'field-shown' : 'field-hidden'}
          style={props.style || {}}
        >
          {value}
        </div>,
      ];
      break;
    case 'sporingsnummer':
      el = [
        <Form.Item
          className={onEdit ? 'field-shown' : 'field-hidden'}
          key={`${valueKey[0]}-1`}
        >
          {form.getFieldDecorator(valueKey[0], { initialValue: value })(
            <Input
              type="text"
              size="small"
              {...props}
              disabled={!_config.editable}
              onChange={(e) =>
                props.onChange(e.currentTarget.value, props.index || 0)
              }
              onBlur={() => props.toggleEdit(false)}
            />
          )}
        </Form.Item>,
        <div
          key={`${valueKey[0]}-2`}
          className={!onEdit ? 'field-shown' : 'field-hidden'}
          style={props.style || {}}
        >
          <a
            href={` http://sporing.bring.no/sporing.html?q=${value}`}
            target="_blank"
          >
            {value}
          </a>
        </div>,
      ];
      break;
    case 'select':
      el = [
        <Form.Item
          className={onEdit ? 'field-shown' : 'field-hidden'}
          key={`${valueKey[0]}-1`}
        >
          {form.getFieldDecorator(valueKey[0], { initialValue: value })(
            <Select
              xs={24}
              style={{ width: '100%', minWidth: '100px' }}
              size="small"
              onChange={props.onChange}
              onSelect={() => props.toggleEdit(false)}
            >
              <Select.Option value="" key="null-0">
                (no value)
              </Select.Option>
              {(props.options || []).map((optVal, index) => (
                <Select.Option value={optVal} key={`${optVal}-${index}`}>
                  {optVal}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>,
        <div
          key={`${valueKey[0]}-2`}
          className={!onEdit ? 'field-shown' : 'field-hidden'}
          style={props.style || {}}
        >
          {value}
        </div>,
      ];
      break;
    case 'date':
      const handleDateChange = (dateValue) => {
        props.onChange(dateValue);
        props.toggleEdit(false);
      };

      el = [
        <Form.Item
          className={onEdit ? 'field-shown' : 'field-hidden'}
          key={`${valueKey[0]}-1`}
        >
          {form.getFieldDecorator(valueKey[0], { initialValue: moment(value) })(
            <DatePicker
              format={DATE_FORMAT}
              style={{ width: '100%' }}
              onChange={handleDateChange}
            />
          )}
        </Form.Item>,
        <div
          key={`${valueKey[0]}-2`}
          className={!onEdit ? 'field-shown' : 'field-hidden'}
          style={props.style || {}}
        >
          {value && typeof value.format === 'function'
            ? value.format(DATE_FORMAT)
            : value
            ? moment(value).format(DATE_FORMAT)
            : value}
        </div>,
      ];
      break;
    case 'richtext':
      el = [
        <Form.Item
          className={onEdit ? 'field-shown' : 'field-hidden'}
          key={`${valueKey[0]}-1`}
        >
          {form.getFieldDecorator(valueKey[0], { initialValue: value })(
            <WysiwygEditor
              onEdit={onEdit}
              contentValue={value}
              onChange={(e) => props.onChange(e, props.index || 0)}
            />
          )}
        </Form.Item>,
        <div
          key={`${valueKey[0]}-2`}
          style={props.style || {}}
          className={!onEdit ? 'field-shown' : 'field-hidden'}
          dangerouslySetInnerHTML={{
            __html: StringsHelper.removeParenthezedValues(value),
          }}
        />,
      ];
      break;
    default:
      // el = <>{value}</>;
      break;
  }

  return el;
};

const EditableFieldWrapper = ({
  label,
  value,
  type,
  editable,
  noTitle,
  ...props
}) => {
  const [onEdit, setEditState] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment
  const [theValue, setValue] = useState(props.values || value);

  // When props is changed, make sure to update the state
  // This fix GU-168
  // (https://wausolutions.atlassian.net/browse/GU-168?atlOrigin=eyJpIjoiNzdkMjM0NjkyNWNhNDRkZDk4MGIzY2VmMjQzYmRmYTkiLCJwIjoiaiJ9)
  useEffect(() => {
    setValue(props.values || value);
  }, [props.values, value]);

  // Disable all editable state once new order clicked
  // Fix GU-220
  // https://wausolutions.atlassian.net/secure/RapidBoard.jspa?rapidView=9&projectKey=GU&modal=detail&selectedIssue=GU-220
  useEffect(() => {
    setEditState(false);
  }, [props.orderId]);

  const handleEditClick = (v = true) => () => {
    setEditState(v);
  };

  const element =
    type === 'groupped' ? (
      <GrouppedField
        onEdit={onEdit}
        {...props}
        values={theValue}
        onChange={(v, index) => {
          const newValue = [...theValue];
          newValue[index] = v;
          setValue(newValue);
        }}
      />
    ) : (
      resolveFieldByType(type, {
        label,
        value: theValue,
        type,
        onEdit,
        ...props,
        onChange: (v) => setValue(v),
        toggleEdit: setEditState,
      })
    );

  // editable is set to true if not specified
  const isEditable = typeof editable === 'boolean' ? editable : true;

  let iconSize;
  const breakpoint = useBreakpoint();
  if (breakpoint === 'xs') {
    iconSize = 'small';
  }

  const normalRender = (
    <Col {...(props || {})}>
      <div className="header-field">
        {noTitle ? null : (
          <label className="header-field-label">
            {label}
            {isEditable ? (
              <>
                {!onEdit ? (
                  <Button
                    size={iconSize}
                    className="field-edit-icon"
                    type="primary"
                    shape="circle"
                    icon="edit"
                    onClick={handleEditClick(true)}
                  />
                ) : (
                  <Button
                    size={iconSize}
                    className="field-edit-icon"
                    shape="circle"
                    icon="check"
                    onClick={handleEditClick(false)}
                  />
                )}
              </>
            ) : null}
          </label>
        )}
        <div
          className={['header-field-value', onEdit ? 'edit' : false]
            .filter((c) => Boolean(c))
            .join(' ')}
        >
          {element}
        </div>
      </div>
    </Col>
  );

  const inlineRender = (
    <Col {...(props || {})} style={{ height: '27px' }}>
      <div className="header-field inline-render">
        {!props.noLabel ? (
          <label className="header-field-label">{label}</label>
        ) : null}
        <div
          className={['header-field-value', onEdit ? 'edit' : false]
            .filter((c) => Boolean(c))
            .join(' ')}
        >
          {element}
          {isEditable ? (
            <>
              {!onEdit ? (
                <Button
                  size={iconSize}
                  className="field-edit-icon"
                  type="primary"
                  shape="circle"
                  icon="edit"
                  onClick={handleEditClick(true)}
                />
              ) : (
                <Button
                  size={iconSize}
                  className="field-edit-icon"
                  shape="circle"
                  icon="check"
                  onClick={handleEditClick(false)}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </Col>
  );

  return ['text', 'select', 'sporingsnummer'].includes(type)
    ? inlineRender
    : normalRender;
};

const SimpleEditableField = ({ label, value, form, valueKey, ...props }) => (
  <Form.Item label={label}>
    {form.getFieldDecorator(valueKey[0] || 'others', {
      initialValue: value || '',
    })(<Input type="text" {...props} />)}
  </Form.Item>
);

const _TableField = ({ value, onEdit, columns, dataSource, ...props }) => {
  const _v = get(props, 'newOrder.values', {});

  return (
    <Table
      className="table-field"
      scroll={{ x: true }}
      dataSource={isEmpty(_v) ? value : buildTableValues(_v)}
      columns={columns}
      pagination={false}
      {...props}
    />
  );
};

const TableField = connect(
  ({ Ui }) => ({
    loading: Ui.editOrderLine.loading,
    newOrder: Ui.editOrderLine.orderUpdated,
  }),
  null
)(_TableField);

const GrouppedField = ({ values, config, ...props }) =>
  values.map((v, index) =>
    resolveFieldByType(config[index].type, {
      ...props,
      config: config[index],
      valueKey: [props.valueKey[index]],
      value: v,
      index,
    })
  );

export const computeConfigToFields = (configs, layout, content, form) =>
  configs
    .map(({ valueKey, transformValue, ...field }) => {
      let fieldToReturn = { ...field, form, valueKey };

      if (valueKey && valueKey.length === 1) {
        fieldToReturn = { ...fieldToReturn, value: get(content, ...valueKey) };
      }

      if (valueKey && valueKey.length > 1) {
        fieldToReturn = {
          ...fieldToReturn,
          values: valueKey.map((k) => get(content, k)),
        };
      }

      if (transformValue && typeof transformValue === 'function') {
        fieldToReturn = {
          ...fieldToReturn,
          value: transformValue(fieldToReturn.value, content),
        };
      }

      return fieldToReturn;
    })
    .map(({ simple, ...field }, index) =>
      simple && simple === true ? (
        <SimpleEditableField key={index} {...layout} {...field} />
      ) : (
        <EditableFieldWrapper
          key={index}
          {...layout}
          {...field}
          orderId={content._id || content.identifier}
          noLabel
        />
      )
    );

export default {
  EditableWrapper: EditableFieldWrapper,
  SimpleEditableWrapper: SimpleEditableField,
};
