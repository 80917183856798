import update from 'immutability-helper';

export const TR_REQUEST_START = '@TableReport/request/start';
export const TR_REQUEST_END = '@TableReport/request/end';

const defaultState = {
  loading: false,
  datas: null,
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case TR_REQUEST_START:
      return update(state, {
        loading: { $set: true },
      });

    case TR_REQUEST_END:
      return update(state, {
        loading: { $set: false },
        datas: { $set: action.payload },
      });

    default:
      return state;
  }
};

export const requestTableReportAction = (periods) => ({
  type: TR_REQUEST_START,
  periods,
});
