/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Spin from 'antd/es/spin';
import { get, omit } from 'lodash-es';
import Table from 'antd/es/table';
import Popconfirm from 'antd/es/popconfirm';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Collapse from 'antd/es/collapse';

import Activities from '../Activities/Activities';
import {
  showEditOrderModalAction,
  editOrderLineAction,
  deleteOrderLineAction,
  requestPrint,
} from '../../redux/ActionsAndReducers/Ui';
import StringsHelper from '../../helpers/Strings';
import { tableColumns } from './PdfView';
import { buildTableValues } from '../../helpers/Fields';
import { renderMediaFileLink } from '../../helpers/OrderSections/configs';
import OrderCard from '../OrderCard';
import EditOrderModal from './EditOrderModal';
import RelatedOrders from './RelatedOrders';
import { atomicUpdate, saveOrder } from '../../redux/ActionsAndReducers/Orders';
import Api from '../../api/Methods';
import { showSiderAction } from '../../redux/sider/actions';

const { Panel } = Collapse;

const hasLabelAndPacking = (order) =>
  get(order, 'values.files', []).filter((l) =>
    l.match(/(bring)|(packinglist)/gi)
  ).length >= 2;
const isKaffekruset = (order) =>
  get(order, 'values.leveringstype', '') === 'Kaffekruset';

const MobileView = ({
  order,
  update,
  updateStates,
  showEditOrderModal,
  updateOrderLine,
  deleteOrderLine,
  tableLoading,
  showSider,
  _print,
  isPrinting,
  _saveOrder,
  currentPagination
}) => {
  const [loading, setLoading] = useState(false);
  const [orderState, setOrder] = useState(order);

  const handleFraktUpdate = (v) => {
    if(v === undefined || v === '' || v === null) return;
    const valuesToPush = {...omit(order.values), frakt: v, updateFrakt: true};
    const id = get(order, '_id', get(order, 'id', 0));
    _saveOrder(
      id,
      {values: valuesToPush},
      currentPagination,
      false,
      true
    );
    setTimeout(() => {
      setLoading(false);
      setOrder(id)
    }, 1000)
  }

  if (typeof orderState === 'string' && !loading) {
    setLoading(true);
    Api.findOneOrder(orderState)
      .then((result) => {
        setOrder(result.data);
        showSider('order', result.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <Spin spinning={loading}>
      <Row className="mobile-drawer-content-container" gutter={[0, 8]}>
        <Col xs={24}>
          <OrderCard
            order={order}
            active={false}
            atomicUpdate={update}
            atomicUpdateState={updateStates}
            onClick={() => {}}
          />
        </Col>

        {get(order, 'values.kommentar', false) ? (
          <Col xs={24}>
            <Card className="order-card">
              <Row>
                <Col xs={24}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '.9rem',
                      marginBottom: '1rem',
                      display: 'block',
                    }}
                  >
                    Kommentar
                  </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: get(order, 'values.kommentar', ''),
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}

        <EditOrderModal orderId={order.id || order._id} />

        <Col xs={24}>
          <Card className="order-card">
            <Button
              type="primary"
              icon="plus"
              block
              style={{ marginBottom: '.5rem' }}
              onClick={showEditOrderModal}
            >
              Add order line
            </Button>
            <Table
              dataSource={buildTableValues(order.values || {})}
              columns={tableColumns(updateOrderLine, order, deleteOrderLine, handleFraktUpdate)}
              scroll={{ x: 500 }}
              bordered={false}
              className="table-field"
              size="small"
              pagination={false}
              loading={tableLoading}
            />
          </Card>
        </Col>

        <Col xs={24}>
          <Collapse accordion>
            <Panel header="Levering">
              <div
                dangerouslySetInnerHTML={{
                  __html: StringsHelper.removeParenthezedValues(
                    get(order, 'values.leveringsAdresse', '')
                  ),
                }}
              />
              <a
                href={`http://sporing.bring.no/sporing.html?q=${get(
                  order,
                  'values.sporingsnummer',
                  ''
                )}`}
                target="_blank"
                style={{ fontWeight: 'bold' }}
              >
                {get(order, 'values.sporingsnummer', 'No sporingsnummer yet') ||
                  'No sporingsnummer yet'}
              </a>
            </Panel>
          </Collapse>
        </Col>

        <Col xs={24}>
          {get(order, 'values.files', []).map((link, index) =>
            renderMediaFileLink(link, `media_files-${index}`, 'default', order)
          )}
          {isKaffekruset(order)
            ? renderMediaFileLink(
                StringsHelper.urlJoin([
                  process.env.REACT_APP_BACKEND_URL,
                  `orders/kaffelabel/${get(order, '_id', '0')}?mode=pdf`,
                ]),
                'kaffelabel'
              )
            : null}
          {hasLabelAndPacking(order) ? (
            <Popconfirm
              title="Are you sure you want to re-print the documents ?"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                _print({
                  id: get(order, '_id', get(order, 'id', '0')),
                })
              }
            >
              <Button
                loading={isPrinting}
                type="danger"
                shape="circle"
                size="large"
                style={{ margin: '.2rem .5rem' }}
              >
                {isPrinting ? null : <Icon type="printer" />}
              </Button>
            </Popconfirm>
          ) : null}
        </Col>

        <RelatedOrders order={order} />

        <Col xs={24}>
          <Card className="order-card">
            <Activities order={order} />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

MobileView.defaultProps = {
  order: {},
};

MobileView.propTypes = {
  order: PropTypes.shape({
    values: PropTypes.any,
  }),
  update: PropTypes.func.isRequired,
  updateStates: PropTypes.any.isRequired,
};

export default connect(
  ({ Ui, Orders }) => ({
    tableLoading: Ui.editOrderLine.loading,
    updateStates: Orders.atomicUpdates,
    isPrinting: Ui.printing,
    currentPagination: Orders.pagination,
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        _saveOrder: (id, datas, pagination, wcUpdate, recalculate) =>
          dispatch(saveOrder(id, datas, pagination, wcUpdate, recalculate)),
        showEditOrderModal: showEditOrderModalAction,
        updateOrderLine: editOrderLineAction,
        deleteOrderLine: deleteOrderLineAction,
        update: atomicUpdate,
        showSider: showSiderAction,
        _print: requestPrint,
      },
      dispatch
    ),
  })
)(MobileView);
