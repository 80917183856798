/* eslint-disable func-names */
import {
  all,
  put,
  takeLatest,
  fork,
  call,
  takeEvery,
} from 'redux-saga/effects';
import {
  FETCH_START,
  FETCH_ONGOING,
  FETCH_END,
  SET_AGGR,
  FETCH_START_AUX,
  FETCH_ONGOING_AUX,
  FETCH_END_AUX,
  SET_TYPE,
  FETCH_PERIOD_START,
  FETCH_PERIOD_ONGOING,
  FETCH_PERIOD_END,
  ADD_CHART_DATA,
} from '../ActionsAndReducers/Reports';
import Api from '../../api/Methods';

function* fetchDatas() {
  yield takeLatest(FETCH_START, function* ({ options }) {
    yield put({ type: FETCH_ONGOING, currentRange: options });
    const reports = yield call(Api.getReport, options);
    yield put({ type: FETCH_END, results: reports.data });
  });
}

function* fetchAuxiliaryDatas() {
  yield takeLatest(FETCH_START_AUX, function* ({ options }) {
    yield put({ type: FETCH_ONGOING_AUX, currentRange: options });
    const reports = yield call(Api.getReport, options);
    yield put({ type: FETCH_END_AUX, results: reports.data });
  });
}

function* setAggregation() {
  yield takeLatest(SET_AGGR, function* ({ periods, aggr, dataType }) {
    yield all(
      periods.map((period, index) =>
        put({
          type: FETCH_PERIOD_START,
          start: period.start,
          end: period.end,
          index,
          aggr,
          dataType,
        })
      )
    );
    // yield put({
    //   type: FETCH_PERIOD_START,
    //   ...options,
    // });
    // yield put({
    //   type: FETCH_START_AUX,
    //   options: {
    //     ...action.auxOptions,
    //     aggr: action.payload,
    //   },
    // });
  });
}

function* setType() {
  yield takeLatest(SET_TYPE, function* ({ periods, aggr, dataType }) {
    yield all(
      periods.map((period, index) =>
        put({
          type: FETCH_PERIOD_START,
          start: period.start,
          end: period.end,
          index,
          aggr,
          dataType,
        })
      )
    );
    // yield put({
    //   type: FETCH_START,
    //   options: {
    //     ...action.options,
    //     dataType: action.payload,
    //   },
    // });
    // yield put({
    //   type: FETCH_START_AUX,
    //   options: {
    //     ...action.auxOptions,
    //     dataType: action.payload,
    //   },
    // });
  });
}

function* fetchPeriodReport() {
  yield takeEvery(FETCH_PERIOD_START, function* ({ type, ...action }) {
    yield put({
      type: FETCH_PERIOD_ONGOING,
      ...action,
    });
    const reports = yield call(Api.getReport, { ...action });
    yield put({ type: FETCH_PERIOD_END, payload: reports.data, ...action });
  });
}

function* addData() {
  yield takeLatest(ADD_CHART_DATA, function* ({ payload }) {
    yield put({
      type: FETCH_PERIOD_START,
      ...payload,
      dataType: payload.dataType,
    });
  });
}

export default function* reportsSaga() {
  yield all([
    fork(fetchDatas),
    fork(fetchAuxiliaryDatas),
    fork(setAggregation),
    fork(setType),
    fork(fetchPeriodReport),
    fork(addData),
  ]);
}
