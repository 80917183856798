import InvoiceModel from '../DataModels/Invoices';
import { notifyError } from '../../helpers/Notify';
import Api from '../../api/Methods';

// ///////////////
// Constants
// ///////////////
export const INVOICES_LOADING = 'Invoices/loading';
export const INVOICES_LOADED = 'Invoices/loaded';
export const INVOICES_LOADING_ERROR = 'Invoices/loadingError';
export const INVOICES_SHOW_DETAILS = 'Invoices/showDetails';
export const INVOICES_HIDE_DETAILS = 'Invoices/hideDetails';

export const INVOICES_UPDATE_STATUS_START = '@Invoices/updateStatus/start';
export const INVOICES_UPDATE_STATUS_END = '@Invoices/updateStatus/end';

export const INVOICES_LOAD_ONE = 'Invoices/load_one';

// ///////////////
// Reducers
// ///////////////
const invoiceDM = new InvoiceModel();
export default (state = invoiceDM, action) => {
  switch (action.type) {
    case INVOICES_LOADING:
      return state.toggleLoading();
    case INVOICES_LOADED:
      return state
        .setCurrentInvoices(action.invoices, action.total)
        .setPagination(action.pagination)
        .toggleLoading();
    case INVOICES_LOADING_ERROR:
      notifyError('Error', 'There was an error loading invoice list');
      return state.toggleLoading();

    case INVOICES_SHOW_DETAILS:
      return state.showDetails(action.id);

    case INVOICES_HIDE_DETAILS:
      return state.hideDetails();
  
    case INVOICES_UPDATE_STATUS_START:
      return state;
    
    case INVOICES_UPDATE_STATUS_END:
      return state.updateStatus({
        ...action,
    });

    default:
      return state;
  }
};

// ///////////////
// Actions
// ///////////////

export const loadInvoices = (opts = { limit: 50, offset: 0 }) => (
  dispatch,
) => {
  dispatch({ type: INVOICES_LOADING });

  Api._getInvoices((err, invoices) => {
    if (err) {
      return dispatch({ type: INVOICES_LOADING_ERROR });
    }

    // work the invoice id and also put the datas in values key
    // so it ressemble to the orders scheme
    const invoicesToSave = invoices.data.items.map((invoice) => ({
      values: {
        ...invoice,
        type: 'invoice',
        invoiceId: `${invoice.identifier} - ${invoice.fakturanummer}`,
      },
      files: [],
      id: invoice._id,
    }));

    return dispatch({
      type: INVOICES_LOADED,
      invoices: invoicesToSave,
      total: invoices.data.total,
      pagination: opts,
    });
  }, opts);
};

export const updateInvoiceStatus = (args) => ({
  type: INVOICES_UPDATE_STATUS_START,
  ...args,
});

export const showDetails = (id) => (dispatch) => {
  dispatch({ type: INVOICES_SHOW_DETAILS, id });
};

export const hideDetails = () => (dispatch) => {
  dispatch({ type: INVOICES_HIDE_DETAILS });
};