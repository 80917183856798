/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Switch from 'antd/es/switch';
import Row from 'antd/es/row';
import Select from 'antd/es/select';
import Pagination from 'antd/es/pagination';
import Tag from 'antd/es/tag';
import { get, groupBy } from 'lodash-es';
import moment from 'moment';

import Sider2 from '../components/Sider/Sider2';
import MultiSearch from '../components/MultiSearch';
import { ReactComponent as MoneyIcon } from '../components/OrderCard/money.svg';
import { ReactComponent as BoxIcon } from '../components/OrderCard/OscarIconSvg.svg';

import CardTile from '../components/CardLayout/CardTile';

const renderMixin = (Base) =>
  class extends Base {
    renderKpisAndDetails() {
      return <></>;
    }

    renderPaginationCard() {
      const {
        isLoading,
        onExport,
        _requestExportData,
        pagination,
        total,
        fetch,
        configFilters,
      } = this.props;

      return (
        <Card
          className="card-pagination"
          style={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
            zIndex: 9,
            boxShadow: '#ccc 0px 3px 3px',
          }}
        >
          <Pagination
            defaultCurrent={7}
            total={total || 0}
            pageSize={pagination.limit}
            // size="small"
            current={pagination.offset / pagination.limit + 1}
            onChange={(page, pageSize) => {
              fetch({
                tableOptions: {
                  pagination: { current: page, pageSize },
                  filters: { ...configFilters.filters },
                  sorters: {
                    // columnKey: 'lokasjon_new',
                    // field: 'lokasjon_new',
                    // order: 'ascend',
                  },
                },
                range: {
                  start: moment().startOf('month'),
                  end: moment().endOf('month'),
                },

                total: total || 0,
              });
            }}
          />
          {!isLoading ? (
            <Button
              type="default"
              shape="circle"
              icon={onExport ? 'loading' : 'download'}
              style={{ boxShadow: '#00000069 2px 3px 4px' }}
              onClick={() => _requestExportData('products')}
              disabled={onExport}
            />
          ) : null}
        </Card>
      );
    }

    renderSider(device = 'desktop') {
      const { saveProduct, pagination, configFilters } = this.props;
      const extraProps = {
        saveProduct: saveProduct.bind(this),
        products_table_options: {
          ...configFilters,
          pagination: {
            current: pagination.offset / pagination.limit + 1,
            pageSize: pagination.limit,
          },
          // filters: {},
          // sorters: {},
        },
        products_table_range: {
          start: moment().startOf('month'),
          end: moment().endOf('month'),
        },
      };

      if (device === 'mobile') {
        return this.isMobile() ? (
          <Sider2
            getContainer={false}
            style={{ position: 'absolute' }}
            width="100%"
            {...extraProps}
          />
        ) : null;
      }
      return !this.isMobile() ? (
        <Sider2
          getContainer={false}
          style={{ position: 'absolute' }}
          width="100%"
          {...extraProps}
        />
      ) : null;
    }

    handleProductSearch(search = '', filters = {}) {
      const {
        pagination,
        fetch,
        total,
        saveFilter,
        configFilters,
      } = this.props;
      const newFState = { ...configFilters.filters };
      const key = Object.keys(filters).pop();
      const val = Object.values(filters).pop();
      // workout filters
      if (val === null) {
        delete newFState[key];
      } else {
        newFState[key] = val;
      }

      saveFilter('products', 'filters', newFState);
      saveFilter('products', 'search', search);

      fetch({
        tableOptions: {
          pagination: { current: 1, pageSize: pagination.limit },
          filters: newFState,
          sorters: {
            // columnKey: 'lokasjon_new',
            // field: 'lokasjon_new',
            // order: 'ascend',
          },
          search,
        },
        range: {
          start: moment().startOf('month'),
          end: moment().endOf('month'),
        },

        total: total || 0,
      });
    }

    renderFilters() {
      const { isLoading, categories, configFilters } = this.props;
      const sortFunc = (a, b) => (a.metas.order || 0) - (b.metas.order || 0);
      const grppedCateg = groupBy(
        Array.from(categories)
          .filter((cat) => cat.metas.parent.length > 0)
          .sort(sortFunc),
        // groupBy func
        (entry) => entry.metas.parent[0].name
      );
      const topCategories = Array.from(categories)
        .filter((cat) => cat.metas.parent.length === 0)
        .sort(sortFunc);

      return (
        <>
          <Col xs={24} style={{ marginBottom: '1rem' }}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              defaultValue={get(configFilters, ['filters', 'merke'], [])}
              disabled={isLoading}
              placeholder="Merke"
              onChange={(val) => {
                const search =
                  val.length < 1 ? { merke: null } : { merke: val };
                this.handleProductSearch(
                  get(configFilters, ['search'], ''),
                  search
                );
              }}
            >
              {topCategories.map((cat, i) => (
                <Select.Option value={cat.name} key={`cat-${i}`}>
                  {cat.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              defaultValue={get(configFilters, ['filters', 'kategori'], [])}
              disabled={isLoading}
              placeholder="Kategori"
              onChange={(val) => {
                const search =
                  val.length < 1 ? { kategori: null } : { kategori: val };
                this.handleProductSearch(
                  get(configFilters, ['search'], ''),
                  search
                );
              }}
            >
              {Object.entries(grppedCateg).map(([merke, categs], i) => (
                <Select.OptGroup label={merke}>
                  {categs.map((cat) => (
                    <Select.Option key={`${i}${cat._id}`} value={cat.name}>
                      {cat.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Col>
        </>
      );
    }

    renderSideHeaderCard() {
      const { isLoading, filtersShown, showSider2, configFilters, showInventoryModal } = this.props;

      return (
        <MultiSearch
          renderActions={() => (
            <>
            <Button
        onClick={showInventoryModal}
        icon="plus"
        type="primary"
        block
        style={{ marginBottom: '.5rem', color: '#db8556' }}
        ghost
        >
          Add Inventory
        </Button>
            <Button
              onClick={() => showSider2('product', {})}
              icon="plus"
              type="primary"
              block
              style={{ marginBottom: '.5rem' }}
            >
              Add new product
            </Button>
            </>
          )}
          handleOnEnterPress={(e) => {
            e.preventDefault();
            const searchValue = document.getElementById('searchValue').value;
            this.handleProductSearch(searchValue);
          }}
          handleOnSearch={this.handleProductSearch.bind(this)}
          isMobile={this.isMobile()}
          filtersShown={filtersShown}
          loading={isLoading}
          placeholder="Name or SKU"
          renderExtended={this.renderFilters.bind(this)}
          defaultSearchValue={get(configFilters, ['search'], '')}
          bottomActions={() => (
            <Row style={{display: 'flex', justifyContent: 'end', marginTop: 6}}>
              <small style={{ marginRight: 2}}>Archived</small>
              <Switch size="small"
              onChange={(checked) => {
                const search =
                checked ? { archived: true } : { archived: false };
                this.handleProductSearch(
                  get(configFilters, ['search'], ''),
                  search
                );
              }}
              />
            </Row>
          )}
        />
      );
    }

    renderCardMain(item) {
      return (
        <>
          <Col xs={24}>
            <Tag>{get(item, 'sku', '-')}</Tag>
          </Col>
          <Col
            xs={24}
            style={{ color: '#db8556', paddingTop: '6px', fontWeight: 'bold' }}
          >
            {get(item, 'kategori', '-')}
          </Col>
        </>
      );
    }

    renderCardSecondary(item) {
      return (
        <Col xs={24}>
          <div className="weight">
            <MoneyIcon className="weight-icon" />
            <div className="weight-value">{get(item, 'pris_ut', 0)}</div>
          </div>
          <div className="weight">
            <BoxIcon className="weight-icon" />
            <div className="weight-value">{get(item, 'lager', 0)}</div>
          </div>
        </Col>
      );
    }

    renderCards(_items) {
      const { showSider2, siderData, sider2IsVisible, hideSider2 } = this.props;
      const orderIsVisibleInSider = (item) =>
        get(siderData, '_id', 0) === item._id && sider2IsVisible;

      return (
        <>
          {_items.map((item, index) => (
            <CardTile
              item={item}
              style={item.archived ? { backgroundColor: '#f7f2ef', opacity: 0.8 } : {}}
              active={orderIsVisibleInSider(item)}
              key={`product-tile-${index}`}
              title={get(item, 'navn', 'UNKNOWN')}
              renderMain={this.renderCardMain.bind(this)}
              renderSecondary={this.renderCardSecondary.bind(this)}
              onClick={() => {
                if (orderIsVisibleInSider(item)) {
                  return hideSider2();
                }
                return showSider2('product', { _id: item.id, ...item });
              }}
            />
          ))}
        </>
      );
    }
  };

export default renderMixin;
