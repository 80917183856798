/* eslint-disable react/no-array-index-key */
import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import CustomerStep from './CustomerStep';
import StatusStep from './StatusStep';
import ProductStep from './ProductStep';
import ValidateButtons from './ValidateButtons';

export const renderCustomerStep = (funcs, states) => (
  <Row className="addmodal-row">
    <CustomerStep
      setCustomerState={funcs.setCustomer}
      customerState={states.customer}
      setStepState={funcs.setStep}
      setOtherState={funcs.setOther}
      otherState={states.other}
      allStates={states}
      resetAllAndClose={() => {
        funcs.clearAllSteps();
        funcs.setStep(0);
        funcs.hide();
      }}
    />
  </Row>
);

export const renderStatusStep = (funcs, states) => (
  <Row className="addmodal-row">
    <StatusStep
      allStates={states}
      setStepStatus={funcs.setStep}
      setOtherState={funcs.setOther}
      otherState={states.other}
      resetAllAndClose={() => {
        funcs.clearAllSteps();
        funcs.setStep(0);
        funcs.hide();
      }}
    />
  </Row>
);

export const renderProductStep = (funcs, states) => (
  <Row className="addmodal-row">
    <ProductStep
      setProductsState={funcs.setProducts}
      productsState={states.products}
      setStep={funcs.setStep}
      setWaTState={funcs.setWaT}
      otherState={states.other}
      setOtherState={funcs.setOther}
    />
  </Row>
);

export const renderValidateButtons = (funcs, states) => (
  <ValidateButtons
    allStates={states}
    setStepStatus={funcs.setStep}
    setOtherState={funcs.setOther}
    otherState={states.other}
    resetAllAndClose={() => {
      funcs.clearAllSteps();
      funcs.setStep(0);
      funcs.hide();
    }}
  />
);

export const renderStepAtPlace = (placement, steps, args) => {
  const containerStyle = args.noSpace
    ? {}
    : {
        marginBottom: '1.5rem',
        borderBottom: '1px dotted rgba(170, 170, 170, 0.4)',
        paddingBottom: '1rem',
      };

  return (
    <Col xs={24}>
      <Row>
        {(steps || [])
          .filter((step) => step.placement === placement)
          .map((step, idx) => (
            <Col xs={24} key={`col-order-info-${idx}`} style={containerStyle}>
              {step.render(args.funcs, args.states)}
            </Col>
          ))}
      </Row>
    </Col>
  );
};
