/* eslint-disable react/jsx-props-no-spreading,react/prop-types,no-continue,no-plusplus */
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GuardedContext } from './GuardedProvider';

const GuardedRoute = ({ component, path, meta, ...props }) => {
  const guardsFunctions = useContext(GuardedContext);
  // if false, no redirection necessary so render component
  // if anything else, then a redirection is necessary
  let redirect = false;
  // called as a function, it just signals that redirection is not necessary
  const next = () => {
    redirect = false;
  };
  // called as next.redirect(path) it changes redirect values to a path
  // then it means a redirection is necessary
  next.redirect = (pathToRedirect) => {
    redirect = pathToRedirect;
  };

  // loop through the guards functions and run them one by one
  // then check the value of redirect if it has been changed by
  // guard function calling next.redirect()
  // if redirect has changed, stop the loop and do not run any middleware anymore
  for (let i = 0; i < guardsFunctions.length; i++) {
    if (typeof guardsFunctions[i] !== 'function') {
      continue;
    }
    guardsFunctions[i]({ path: path === undefined ? '/' : path, meta }, next);
    if (redirect !== false) {
      break;
    }
  }

  // if redirect has changed, render a redirect component
  // else, just render a normal react-router route
  return redirect !== false ? (
    <Redirect to={{ pathname: redirect }} />
  ) : (
    <Route {...props} path={path} component={component} />
  );
};

export default GuardedRoute;
