/* eslint-disable default-case */
/* eslint-disable import/prefer-default-export */
export const formatActivtyFromChange = (name, value) => {
  switch (name) {
    case 'values.status':
      return `Status changed to ${value !== '' ? value : 'EMPTY'}`;
    case 'values.number':
      return `Number changed to ${value !== '' ? value : 'EMPTY'}`;
    case 'values.leveringstype':
      return `Leveringstype changed to ${value !== '' ? value : 'EMPTY'}`;
    case 'values.totalt':
      return `OrdreVolm changed to ${value !== '' ? value : 'EMPTY'}`;
    case 'values.pakketAv':
      return `PakketAv changed to ${value !== '' ? value : 'EMPTY'}`;
    case 'values.ordreDato':
      if (typeof value === 'object') {
        return `Ordredato changed to ${value.format('YYYY.MM.DD')}`;
      }
      return 'Ordredato changed to NULL';
    case 'values.sendtDato':
      if (typeof value === 'object') {
        return `Sendtdato changed to ${value.format('YYYY.MM.DD')}`;
      }
      return 'Sendtdato changed to NULL';
    case 'values.leveringsDato':
      if (typeof value === 'object') {
        return `LeveringsDato changed to ${value.format('YYYY.MM.DD')}`;
      }
      return 'LeveringsDato changed to NULL';
    case 'values.sporingsnummer':
      return `Sporingsnummer changed to ${value || 'EMPTY'}`;
  }

  return `${name.replace(/values\./gi, '')} changed to ${value || 'EMPTY'}`;
};
