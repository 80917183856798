/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, has } from 'lodash-es';
import moment from 'moment';
import { notifyError } from '../../helpers/Notify';

import { totalPris } from './ProductStep';
import { createOrderAction } from '../../redux/staging/actions';

const calculateTotal = (states) =>
  totalPris(get(states, 'products', [])) -
  get(states, 'other.rabatt', 0) * 1 +
  get(states, 'other.frakt', 0);

const noUnresolvedProduct = (product) => has(product.product, '_id');

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createOrder: createOrderAction,
    },
    dispatch
  ),
});

const mapStateToProps = ({ staging }) => ({
  loading: staging.creatingOrder,
  products: staging.products || [],
});

const withOrderCreate = (Component) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ createOrder, products, resetAllAndClose, allStates, ...props }) => (
    <Component
      resetAllAndClose={resetAllAndClose}
      {...props}
      createOrder={() => {
        if(!allStates.customer) {
          notifyError('Error', 'Order must have a customer');
          return;
        }
        const stateToSave = {
          ...allStates,
          products: (products || []).filter(noUnresolvedProduct),
        };
        if (stateToSave.other && !stateToSave.other.ordredato) {
          stateToSave.other.ordredato = moment();
        }
        stateToSave.other.totalt = calculateTotal(stateToSave);

        // append creator if possible
        const username = get(
          JSON.parse(localStorage.user_object || '{}'),
          'user.username',
          null
        );
        if (username) {
          stateToSave.creator = { name: username };
        }

        createOrder(stateToSave, resetAllAndClose);
      }}
    />
  ));

export default withOrderCreate;
