/* eslint-disable func-names */
import { all, put, takeLatest, fork } from 'redux-saga/effects';
import { get } from 'lodash-es';
import moment from 'moment';
import { SIDER_HIDE } from '../sider/actions';
import {
  PRODUCTS_FETCH_START,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_FAIL,
  PRODUCTS_FETCH_CATEGORIES_START,
  PRODUCTS_FETCH_CATEGORIES_DONE,
  PRODUCTS_FETCH_STATS_START,
  PRODUCTS_FETCH_STATS_DONE,
  PRODUCTS_UPDATE_START,
  PRODUCTS_UPDATE_END,
  PRODUCTS_CLOSE_MODAL,
  PRODUCTS_UPDATE_CATEG_START,
  PRODUCTS_UPDATE_CATEG_END,
} from '../ActionsAndReducers/Products';
import Api from '../../api/Methods';
import handleError from './handleError';

function* fetchProducts() {
  yield takeLatest(PRODUCTS_FETCH_START, function* ({ payload }) {
    const { tableOptions, range } = payload;
    try {
      // make action call to API
      const results = yield Api.getProducts({
        start: range.start.format('YYYY-MM-DD'),
        end: range.end.format('YYYY-MM-DD'),
        sorters: { field: 'lokasjon_new', order: 'ascend' },
        ...tableOptions,
      });
      yield put({
        type: PRODUCTS_FETCH_SUCCESS,
        payload: {
          items: get(results, 'data.items', []),
          total: get(results, 'data.total', 0),
          pagination: {
            limit: tableOptions.pagination.pageSize,
            offset:
              tableOptions.pagination.pageSize *
              (tableOptions.pagination.current - 1),
          },
        },
      });
    } catch (e) {
      yield put({ type: PRODUCTS_FETCH_FAIL });
      handleError(e);
    }
  });
}

function* fetchProductsCategories() {
  yield takeLatest(PRODUCTS_FETCH_CATEGORIES_START, function* () {
    try {
      // make action call to API
      const results = yield Api.getProductsCategories();
      yield put({
        type: PRODUCTS_FETCH_CATEGORIES_DONE,
        data: get(results, 'data', []),
      });
    } catch (e) {
      handleError(e);
    }
  });
}

function* fetchProductsStats() {
  yield takeLatest(PRODUCTS_FETCH_STATS_START, function* () {
    try {
      // make action call to API
      const results = yield Api.getProductsStats();
      yield put({
        type: PRODUCTS_FETCH_STATS_DONE,
        data: get(results, 'data.0', {}),
      });
    } catch (e) {
      handleError(e);
    }
  });
}

function* updateProductCategories() {
  yield takeLatest(PRODUCTS_UPDATE_CATEG_START, function* (action) {
    try {
      // make action call to API
      const results = yield Api.updateProductCategories(action.payload);
      yield put({
        type: PRODUCTS_UPDATE_CATEG_END,
        data: get(results, 'data', []),
      });
    } catch (e) {
      handleError(e);
    }
  });
}

function* updateOrAddProduct() {
  yield takeLatest(PRODUCTS_UPDATE_START, function* (action) {
    try {
      if (action.id) {
        yield Api.updateProduct(action.id, action.payload);
      } else {
        yield Api.addProduct(action.payload);
      }
      yield put({ type: PRODUCTS_UPDATE_END });
      yield put({
        type: PRODUCTS_FETCH_START,
        payload: {
          tableOptions: {
            pagination: { current: 1, pageSize: 50 },
            filters: {},
            sorters: {
              columnKey: 'sku',
              field: 'sku',
              order: 'ascend',
            },
            ...get(action, 'refetchOptions.tableOptions', {})
          },
          /* tableOptions: get(action, 'refetchOptions.tableOptions', {
            pagination: { current: 1, pageSize: 50 },
            filters: {},
            sorters: {
              columnKey: 'sku',
              field: 'sku',
              order: 'ascend',
            },
          }), */
          range: get(action, 'refetchOptions.range', {
            start: moment().startOf('month'),
            end: moment().endOf('month'),
          }),
        },
      });
      yield put({ type: PRODUCTS_FETCH_STATS_START });
      yield put({ type: PRODUCTS_CLOSE_MODAL });
      yield put({ type: SIDER_HIDE });
    } catch (e) {
      handleError(e);
      yield put({ type: PRODUCTS_UPDATE_END });
    }
  });
}

export default function* productsSaga() {
  yield all([
    fork(fetchProducts),
    fork(fetchProductsCategories),
    fork(fetchProductsStats),
    fork(updateOrAddProduct),
    fork(updateProductCategories),
  ]);
}
