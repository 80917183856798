/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import Modal from 'antd/es/modal';
import Checkbox from 'antd/es/checkbox';
import PropTypes from 'prop-types';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Descriptions from 'antd/es/descriptions';
import { get, omit } from 'lodash-es';
import Button from 'antd/es/button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchProductsCategoriesAction,
  updateCategoriesAction,
} from '../../redux/ActionsAndReducers/Products';

import UpdateCategoriesModal from '../Modals/UpdateCategories.modal';
import EditableAvatar from '../EditableAvatar';
import StringHelper from '../../helpers/Strings';

const castToNumberIfNeeded = StringHelper.castToNumber;

const ProductsModal = ({
  close,
  visible,
  product,
  form,
  categories,
  save,
  loading,
  noModal,
  saveCategories,
}) => {
  const imagesProduct = (get(product, 'bilder', '') || '').split(',');
  const [enhetspris, setEnhetspris] = useState(0);
  const [mva, setMva] = useState(0);
  const [bruttofortjeneste, setBruto] = useState(0);
  const calculateAmounts = (_product) => {
    const _enhetspris =
      castToNumberIfNeeded(get(_product, 'pris_ut', 0)) / 1.25;
    const _mva =
      castToNumberIfNeeded(get(_product, 'pris_ut', 0)) -
      castToNumberIfNeeded(get(_product, 'pris_ut', 0) / 1.25);
    const _bruto =
      _enhetspris - castToNumberIfNeeded(get(_product, 'pris_inn', 0));
    setEnhetspris(_enhetspris);
    setMva(_mva);
    setBruto(_bruto);
  };
  const [catModalVisible, setCatModalVis] = useState(false);
  const [modalCats, setModalCats] = useState([]);
  const [modalCatsParents, setModalCatsParents] = useState([]);

  const showModalWith = (cats, parents = []) => {
    setModalCats(cats);
    setCatModalVis(true);
    setModalCatsParents(parents);
  };
  const closeModal = () => {
    setModalCats([]);
    setCatModalVis(false);
    setModalCatsParents([]);
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    calculateAmounts(product);

    return () => {
      form.resetFields();
    };
  }, [product]);

  const sortFunc = (a, b) => (a.metas.order || 0) - (b.metas.order || 0);
  const topCateg = categories
    .filter((cat) => cat.metas.parent.length === 0)
    .sort(sortFunc);
  const subCateg = categories
    .filter((cat) => cat.metas.parent.length > 0)
    .sort(sortFunc);

  const encloseInModal = (element) => (
    <Modal
      visible={visible}
      footer={null}
      onCancel={close}
      destroyOnClose
      className="normal-modal"
    >
      {element}
    </Modal>
  );

  const formElement = (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        form.validateFields((errors, values) => {
          if (!errors) {
            const imageIsUpdated = form.isFieldTouched('bilder');
            let valsToSave =
              values.lokasjon_new === 0
                ? omit({ ...values }, 'lokasjon_new')
                : {
                    ...values,
                  };
            // Avoid uploading image if was not changed
            if (!imageIsUpdated) {
              valsToSave = omit({ ...valsToSave }, 'bilder');
            }

            save(get(product, '_id', null), valsToSave);
          }
        });
      }}
    >
      <Row>
        <UpdateCategoriesModal
          open={catModalVisible}
          categories={modalCats}
          handleOk={(vals) => {
            if (vals.length > 0) {
              saveCategories(vals);
            }
            closeModal();
          }}
          handleClose={closeModal}
          parents={modalCatsParents}
        />
        <Col xs={24} style={{ textAlign: 'center' }}>
          <Form.Item>
            {form.getFieldDecorator('bilder', {
              initialValue: get(imagesProduct, '[0]', null),
            })(
              <EditableAvatar
                // defaultValue={get(imagesProduct, '[0]', null)}
                imgStyles={{
                  width: '45%',
                  borderRadius: '7px',
                  margin: 'auto',
                  maxWidth: '200px',
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12}>
          <Form.Item label="Navn">
            {form.getFieldDecorator('navn', {
              initialValue: get(product, 'navn', ''),
            })(<Input type="text" />)}
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Varetekst">
            {form.getFieldDecorator('varetekst', {
              initialValue: get(product, 'varetekst', ''),
            })(<Input type="text" />)}
          </Form.Item>
        </Col>
        <Col md={12} xs={24} sm={24}>
          <Form.Item label="Sku">
            {form.getFieldDecorator('sku', {
              initialValue: get(product, 'sku', ''),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Lokasjon">
            {form.getFieldDecorator('lokasjon_new', {
              initialValue: get(product, 'lokasjon_new', 0),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Merke</span>
                &nbsp;
                {/* <Button
                  shape="circle"
                  icon="edit"
                  size="small"
                  onClick={() => showModalWith([...topCateg])}
                /> */}
              </span>
            }
          >
            {form.getFieldDecorator('merke', {
              initialValue: get(product, 'merke', 'Willow Tree'),
            })(
              <Select style={{ width: '100%' }} disabled>
                {topCateg.map((cat) => (
                  <Select.Option key={cat._id} value={cat.name}>
                    {cat.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                <span>Kategori</span>
                &nbsp;
                <Button
                  shape="circle"
                  icon="edit"
                  size="small"
                  onClick={() => {
                    const filteredSubCateg = [...subCateg].filter((categ) => {
                      const parent = get(categ, 'metas.parent[0].name', null);

                      return parent === form.getFieldValue('merke');
                    });
                    const filteredTopCateg = [...topCateg].filter(
                      (categ) => categ.name === form.getFieldValue('merke')
                    );

                    showModalWith(filteredSubCateg, filteredTopCateg);
                  }}
                />
              </span>
            }
          >
            {form.getFieldDecorator('kategori', {
              initialValue: get(product, 'kategori', '').split(','),
            })(
              <Select style={{ width: '100%' }} mode="multiple">
                {subCateg
                  .filter(
                    (cat) =>
                      cat.metas.parent.length > 0 &&
                      cat.metas.parent[0].name === form.getFieldValue('merke')
                  )
                  .map((cat) => (
                    <Select.Option key={cat._id} value={cat.name}>
                      {cat.name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Høyde">
            {form.getFieldDecorator('hoyde', {
              initialValue: get(product, 'hoyde', ''),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Bredde">
            {form.getFieldDecorator('bredde', {
              initialValue: get(product, 'bredde', ''),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Dydbe">
            {form.getFieldDecorator('dydbe', {
              initialValue: get(product, 'dydbe', ''),
            })(<Input type="text" />)}
          </Form.Item>
        </Col>

        <Col md={12} xs={24} sm={24}>
          <Form.Item label="Pris">
            {form.getFieldDecorator('pris_inn', {
              initialValue: get(product, 'pris_inn', 0),
            })(
              <Input
                type="text"
                onBlur={(e) => {
                  calculateAmounts({
                    pris_inn: e.currentTarget.value,
                    pris_ut: get(product, 'pris_ut', 0),
                  });
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Veil. Pris">
            {form.getFieldDecorator('pris_ut', {
              initialValue: get(product, 'pris_ut', 0),
            })(
              <Input
                type="text"
                onBlur={(e) => {
                  calculateAmounts({
                    pris_ut: e.currentTarget.value,
                    pris_inn: get(product, 'pris_inn', 0),
                  });
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Antall">
            {form.getFieldDecorator('lager', {
              initialValue: get(product, 'lager', -1),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Vekt (gram)">
            {form.getFieldDecorator('vekt', {
              initialValue: get(product, 'vekt', 0),
            })(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Published">
            {form.getFieldDecorator('published', {
              initialValue: get(product, 'published', false),
              valuePropName: 'checked',
            })(<Checkbox>Published</Checkbox>)}
          </Form.Item>
          <Form.Item label="Archived">
            {form.getFieldDecorator('archived', {
              initialValue: get(product, 'archived', false),
              valuePropName: 'checked',
            })(<Checkbox>Archived</Checkbox>)}
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={24}>
          <Descriptions
            title="Ekstra info"
            bordered
            layout="vertical"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Enhetspris (eks mva)">
              {`${enhetspris} NOK`}
            </Descriptions.Item>
            <Descriptions.Item label="Mva">
              {`${mva.toFixed(1)} NOK`}
            </Descriptions.Item>
            <Descriptions.Item label="Bruttofortjeneste">
              {`${bruttofortjeneste.toFixed(1)} NOK`}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row> */}
      <Row>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched()}
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );

  return noModal ? formElement : encloseInModal(formElement);
};

ProductsModal.defaultProps = {
  product: {},
  categories: [],
  // eslint-disable-next-line no-console
  save: (datas) => {
    console.log(datas);
  },
  loading: false,
  noModal: false,
};

ProductsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  product: PropTypes.shape({}),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    isFieldsTouched: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  categories: PropTypes.array,
  save: PropTypes.func,
  loading: PropTypes.bool,
  fetchCateg: PropTypes.func.isRequired,
  saveCategories: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Products }) => ({
  categories: Products.categories.data || [],
  loading: Products.modalLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCateg: fetchProductsCategoriesAction,
      saveCategories: updateCategoriesAction,
    },
    dispatch
  );

export default Form.create({ name: 'productsForm' })(
  connect(mapStateToProps, mapDispatchToProps)(ProductsModal)
);
