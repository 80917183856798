import React from 'react';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import Divider from 'antd/es/divider';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createOrderAction } from '../../redux/staging/actions';

const StatusStep = ({ form, setOtherState, otherState }) => {
  const statuses = [
    'Ny',
    'Pakkes',
    'Pakket',
    'Sendt',
    'Levert',
    'Kanselert',
    'Rest',
    'Snooze',
  ];
  const deliveryOptions = ['RFID', 'Sporingspakke', 'Kaffekruset'];

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const handleSaveData = (key, currentDatas, func = null) => (v) => {
    let _v = v;
    // eslint-disable-next-line no-prototype-builtins
    if (typeof _v === 'object' && _v.hasOwnProperty('currentTarget')) {
      _v = _v.currentTarget.value;
    }

    if (func && typeof func === 'function') {
      return func(_v, currentDatas);
    }

    return setOtherState({ ...currentDatas, [key]: _v });
  };

  return (
    <>
      <Col xs={24}>
        <Divider>Ordredetaljer</Divider>
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Form layout="horizontal" {...formLayout} className="addmodal-form">
            <Form.Item label="Status">
              {form.getFieldDecorator('status', {
                rules: [{ required: true }],
                initialValue: otherState.status || 'Ny',
              })(
                <Select
                  style={{ width: '100%' }}
                  onChange={handleSaveData('status', otherState)}
                >
                  {statuses.map((s, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Select.Option value={s} key={`status-opt-${index}`}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Ordredato">
              {form.getFieldDecorator('ordredato', {
                rules: [{ required: true }],
                initialValue: otherState.ordredato || moment(),
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={handleSaveData('ordredato', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Rabatt (Kr)">
              {form.getFieldDecorator('rabatt', {
                rules: [{ required: false }],
                initialValue: otherState.rabatt || 0,
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={9999999}
                  step={10}
                  onChange={handleSaveData('rabatt', otherState)}
                />
              )}
            </Form.Item>
            <Divider>Levering</Divider>
            <Form.Item label="Leveringstype" style={{display: 'none'}}>
              {form.getFieldDecorator('leveringstype', {
                rules: [{ required: true }],
                initialValue: otherState.leveringstype || 'RFID',
              })(
                <Select
                  style={{ width: '100%' }}
                  onChange={handleSaveData('leveringstype', otherState)}
                >
                  {deliveryOptions.map((s, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Select.Option value={s} key={`status-opt-${index}`}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label=" Leveringsdato">
              {form.getFieldDecorator('leveringsdato', {
                rules: [{ required: false }],
                initialValue: otherState.leveringsdato || null,
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={handleSaveData('leveringsdato', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Vekt (kgs)">
              {form.getFieldDecorator('vekt', {
                rules: [{ required: true }],
                initialValue: (otherState.vekt || 0) / 1000,
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.01}
                  min={0}
                  max={99999999999}
                  onChange={handleSaveData(
                    'vekt',
                    otherState,
                    (v, currentState) => {
                      setOtherState({ ...currentState, vekt: (v || 0) * 1000 });
                    }
                  )}
                />
              )}
            </Form.Item>
            <Form.Item label="Frakt (Kr)">
              {form.getFieldDecorator('frakt', {
                rules: [{ required: true }],
                initialValue: otherState.frakt || 0,
              })(
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  max={999999}
                  step={10}
                  onChange={handleSaveData(
                    'frakt',
                    otherState,
                    (v, currentState) => {
                      setOtherState({ ...currentState, frakt: v, updateFrakt: true });
                    }
                  )}
                />
              )}
            </Form.Item>
            <Form.Item style={{display: 'none'}}>
              {form.getFieldDecorator('updateFrakt', {
                initialValue: otherState.updateFrakt || false,
              })(
                <div>null</div>
              )}
            </Form.Item>
            <Form.Item label="Mottaker">
              {form.getFieldDecorator('shipping_name', {
                rules: [{ required: true }],
                initialValue: otherState.shipping_name || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData('shipping_name', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Adresselinje 1">
              {form.getFieldDecorator('shipping_addressline_1', {
                rules: [{ required: true }],
                initialValue: otherState.shipping_addressline_1 || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData(
                    'shipping_addressline_1',
                    otherState
                  )}
                />
              )}
            </Form.Item>
            <Form.Item label="Adresselinje 2">
              {form.getFieldDecorator('shipping_adressline_2', {
                rules: [{ required: false }],
                initialValue: otherState.shipping_adressline_2 || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData('shipping_adressline_2', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Postnummer">
              {form.getFieldDecorator('shipping_postnummer', {
                rules: [{ required: true }],
                initialValue: form.getFieldValue('leveringpostnummer') || otherState.shipping_postnummer || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData('shipping_postnummer', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Poststed">
              {form.getFieldDecorator('shipping_poststed', {
                rules: [{ required: true }],
                initialValue: otherState.shipping_poststed || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData('shipping_poststed', otherState)}
                />
              )}
            </Form.Item>
            <Form.Item label="Telefon">
              {form.getFieldDecorator('shipping_phone', {
                rules: [{ required: true }],
                initialValue: otherState.shipping_phone || '',
              })(
                <Input
                  type="text"
                  onChange={handleSaveData('shipping_phone', otherState)}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </Col>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createOrder: createOrderAction,
    },
    dispatch
  ),
});

const mapStateToProps = ({ staging }) => ({
  loading: staging.creatingOrder,
  products: staging.products || [],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'other_infos' })(StatusStep));
