export const SHOW_STAGE = '@stage/show';
export const HIDE_STAGE = '@stage/hide';

export const SEARCH_CUSTOMER_START = '@stage/search_customer/start';
export const SEARCH_CUSTOMER_END = '@stage/search_customer/end';
export const SEARCH_CLEAR = '@stage/search_clear';

export const SEARCH_PRODUCT_START = '@stage/search_product/start';
export const SEARCH_PRODUCT_END = '@stage/search_product/end';
export const SEARCH_PRODUCT_CLEAR = '@stage/search_product_clear';

export const CREATE_ORDER_START = '@stage/create_order_start';
export const CREATE_ORDER_END = '@stage/create_order_end';

export const PRODUCT_LIST_SET_PRODUCT = '@stage/product_list/set_product';
export const PRODUCT_LIST_CLEAR = '@stage/product_list/clear';
export const PRODUCT_LIST_SET_RESOLVED_PRODUCT =
  '@stage/product_list/set_resolved';
export const PRODUCT_LIST_REMOVE_PRODUCT = '@stage/product_list/remove_product';
export const PRODUCT_LIST_REMOVE_MANY_PRODUCTS =
  '@stage/product_list/remove_many_products';

export const UPDATE_INVENTORY_START = '@stage/update_inventory_start';
export const UPDATE_INVENTORY_END = '@stage/update_inventory_end';
  

export const showStageAction = () => ({
  type: SHOW_STAGE,
});

export const hideStageAction = () => ({
  type: HIDE_STAGE,
});

export const searchCustomerAction = (email) => ({
  type: SEARCH_CUSTOMER_START,
  email,
});

export const clearSearchAction = () => ({
  type: SEARCH_CLEAR,
});

export const searchProductAction = (query) => ({
  type: SEARCH_PRODUCT_START,
  query,
});

export const createOrderAction = (payload, afterCreate = null) => ({
  type: CREATE_ORDER_START,
  payload,
  afterCreate,
});

export const clearSearchProductAction = (replaceBy = '') => ({
  type: SEARCH_PRODUCT_CLEAR,
  replaceBy,
});

export const setProductInListAction = (line, index = null) => ({
  type: PRODUCT_LIST_SET_PRODUCT,
  line,
  index,
});

export const removeProductAtAction = (index) => ({
  type: PRODUCT_LIST_REMOVE_PRODUCT,
  index,
});

export const removeManyProductsAction = (filter) => ({
  type: PRODUCT_LIST_REMOVE_MANY_PRODUCTS,
  filter,
});

export const updateInventoryAction = (payload, afterUpdate = null) => ({
  type: UPDATE_INVENTORY_START,
  payload,
  afterUpdate,
});