/* eslint-disable no-case-declarations */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-case */
import { get } from 'lodash-es';
import moment from 'moment';
import {
  PRODUCTS_FETCH_START,
  fetchProductsCategoriesAction,
  fetchProductsStatsAction,
} from './ActionsAndReducers/Products';
import {
  ORDER_WHOLE_SAVE_START,
  ORDER_WHOLE_SAVE_SUCCESS,
  resetChanges,
  ATOMIC_UPDATE_END,
  ORDER_REALTIME_ORDER_UPDATED,
  ORDER_REALTIME_NEW_ORDER,
} from './ActionsAndReducers/Orders';
import { fetchOrderStatusesAction } from './ActionsAndReducers/Ui';
import {
  ACTIVITIES_CREATE_END,
  createActivity,
  reduxFetchActivities,
} from './ActionsAndReducers/Activities';
import { formatActivtyFromChange } from '../helpers/Activities';

export const eventHooks = ({ dispatch, getState }) => (next) => (action) => {
  // run before the action
  switch (action.type) {
    case PRODUCTS_FETCH_START:
      // catch products fetch, if categories/stats has not
      // been loaded yet just query them
      const { Products } = getState();
      if (!Products?.categories?.loaded) {
        dispatch(fetchProductsCategoriesAction());
      }

      if (!Products?.stats?.loaded) {
        dispatch(fetchProductsStatsAction());
      }
      break;

    case ORDER_WHOLE_SAVE_START:
      // grab changes then create activities accordingly
      const rawChanges = Object.entries(getState().Orders.changes);
      if (rawChanges.length > 0) {
        const username = get(
          JSON.parse(localStorage.getItem('user_object')),
          'user.username',
          ''
        );
        const activity = {
          creator: username,
          date: moment().toISOString(),
          order_sku: get(action, 'payload.values.number', undefined),
          activity: rawChanges
            .map(
              // eslint-disable-next-line no-unused-vars
              ([name, value]) => formatActivtyFromChange(name, value)
            )
            .join(', '),
        };
        dispatch(createActivity(activity));
      }
      break;
  }

  const nextAction = next(action);

  // run after the action
  switch (action.type) {
    case ORDER_WHOLE_SAVE_SUCCESS:
      dispatch(resetChanges());
      dispatch(fetchOrderStatusesAction());
      break;
    case ACTIVITIES_CREATE_END:
      dispatch(reduxFetchActivities(action.sku));
      break;

    // Refetch order statuses on this events
    case ORDER_REALTIME_NEW_ORDER:
    case ATOMIC_UPDATE_END:
    case ORDER_REALTIME_ORDER_UPDATED:
      dispatch(fetchOrderStatusesAction());
      break;
  }

  return nextAction;
};
