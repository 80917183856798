import update from 'immutability-helper';
import { ORDER_DRAWER_WIDTH } from '../../constants/defaultValues';

export const SHOW_MODAL = 'UI/show_modal';
export const HIDE_MODAL = 'UI/hide_modal';

export const SHOW_SIDER = 'UI/show_sider';
export const HIDE_SIDER = 'UI/hide_sider';

export const REQUEST_SET_PASSWORD_START = '@Ui/request_set_pasword_start';
export const REQUEST_SET_PASSWORD_END = '@Ui/request_set_pasword_end';
export const EXPORT_DATA_START = '@Ui/export-data/start';
export const EXPORT_DATA_END = '@Ui/export-data/end';
export const PRINT_PDFS_START = '@Ui/print-pdf/start';
export const PRINT_PDFS_END = '@Ui/print-pdf/end';

export const SHOW_EDIT_ORDER_MODAL = '@Ui/show-edit-order-modal';
export const HIDE_EDIT_ORDER_MODAL = '@Ui/hide-edit-order-modal';
export const SEARCH_PRODUCT_START = '@Ui/order-modal/search-start';
export const SEARCH_PRODUCT_END = '@Ui/order-modal/search-end';
export const ORDER_LINE_ADD = '@Ui/order-line/add';
export const ORDER_LINE_ADD_END = '@Ui/order-line/add/ended';
export const ORDER_LINE_DELETE = '@Ui/order-line/delete';
export const ORDER_LINE_DELETE_END = '@Ui/order-line/delete/ended';
export const CLEAR_SEARCH = '@Ui/clear-search';

export const UPDATE_ORDER_LINE_START = '@Ui/update-order-line-start';
export const UPDATE_ORDER_LINE_END = '@Ui/update-order-line-end';

export const TOGGLE_FILTERS = '@Ui/toggle-filters';

export const FETCH_ORDER_STATUSES = '@Ui/fetch_order_statuses/start';
export const FETCH_ORDER_STATUSES_END = '@Ui/fetch_order_statuses/fulfilled';

const uiState = {
  modalShown: false,
  dataType: null,
  idInModal: null,
  setPasswordIsLoading: false,
  onExport: {
    orders: false,
    customers: false,
    lager: false,
  },
  printing: false,
  sider: {
    show: false,
    content: null,
    type: 'order',
    data: null,
    width: ORDER_DRAWER_WIDTH,
    title: '',
  },
  atomicUpdates: {},
  showFilters: false,
  editOrderModal: {
    show: false,
    loading: false,
    productInfo: null,
  },
  editOrderLine: {
    loading: false,
    orderUpdated: null,
  },
  orderStatuses: [],
};

export default (state = uiState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return update(state, {
        modalShown: { $set: true },
        dataType: { $set: action.dataType },
        idInModal: { $set: action.id },
      });

    case HIDE_MODAL:
      return update(state, { $set: uiState });

    case REQUEST_SET_PASSWORD_START:
      return update(state, {
        setPasswordIsLoading: { $set: true },
      });

    case REQUEST_SET_PASSWORD_END:
      return update(state, {
        setPasswordIsLoading: { $set: false },
      });

    case EXPORT_DATA_START:
      return update(state, {
        onExport: { [action.model]: { $set: true } },
      });
    case EXPORT_DATA_END:
      return update(state, {
        onExport: { [action.model]: { $set: false } },
      });

    case PRINT_PDFS_START:
      return update(state, {
        printing: { $set: true },
      });

    case PRINT_PDFS_END:
      return update(state, {
        printing: { $set: false },
      });

    case SHOW_SIDER:
      return update(state, {
        sider: {
          show: { $set: true },
          content: { $set: action.siderContent },
          type: { $set: action.siderType },
          data: { $set: action.siderData || null },
          width: { $set: action.width || ORDER_DRAWER_WIDTH },
          title: { $set: action.title || null },
        },
      });

    case HIDE_SIDER:
      return update(state, {
        sider: {
          show: { $set: false },
          content: { $set: null },
          type: { $set: 'no-content' },
          data: { $set: null },
          title: { $set: null },
        },
        editOrderLine: {
          loading: { $set: false },
          orderUpdated: { $set: null },
        },
      });

    case TOGGLE_FILTERS:
      return update(state, {
        $toggle: ['showFilters'],
      });

    case SHOW_EDIT_ORDER_MODAL:
      return update(state, {
        editOrderModal: {
          show: { $set: true },
        },
      });

    case HIDE_EDIT_ORDER_MODAL:
      return update(state, {
        editOrderModal: {
          show: { $set: false },
          productInfo: { $set: null },
        },
      });

    case UPDATE_ORDER_LINE_START:
    case ORDER_LINE_DELETE:
      return update(state, {
        editOrderLine: {
          loading: { $set: true },
          orderUpdated: { $set: null },
        },
      });

    case UPDATE_ORDER_LINE_END:
    case ORDER_LINE_DELETE_END:
      return update(state, {
        editOrderLine: {
          loading: { $set: false },
          orderUpdated: { $set: action.newOrder },
        },
      });

    case SEARCH_PRODUCT_START:
      return update(state, {
        editOrderModal: {
          loading: { $set: true },
        },
      });

    case SEARCH_PRODUCT_END:
      return update(state, {
        editOrderModal: {
          loading: { $set: false },
          productInfo: { $set: action.productInfo },
        },
      });

    case ORDER_LINE_ADD:
      return update(state, {
        editOrderModal: {
          loading: { $set: true },
        },
        editOrderLine: {
          loading: { $set: true },
        },
      });

    case ORDER_LINE_ADD_END:
      return update(state, {
        editOrderModal: {
          loading: { $set: false },
          show: { $set: false },
          productInfo: { $set: null },
        },
        editOrderLine: {
          loading: { $set: false },
          orderUpdated: { $set: action.newOrder },
        },
      });

    case CLEAR_SEARCH:
      return update(state, {
        editOrderModal: {
          productInfo: { $set: [] },
        },
      });

    case FETCH_ORDER_STATUSES_END:
      return update(state, {
        orderStatuses: { $set: action.payload },
      });

    default:
      return state;
  }
};

export const requestExportData = (model, filter = {}) => ({
  type: EXPORT_DATA_START,
  model,
  filter,
});

export const onExportEnd = (model) => ({
  type: EXPORT_DATA_END,
  model,
});

export const showModal = (dataType, id) => ({
  type: SHOW_MODAL,
  dataType,
  id,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const requestNewPassword = (payload) => ({
  type: REQUEST_SET_PASSWORD_START,
  payload,
});

export const requestPrint = (payload) => ({
  type: PRINT_PDFS_START,
  payload,
});

export const showSider = ({ type, content, data, ...rest }) => ({
  type: SHOW_SIDER,
  siderType: type,
  siderContent: content,
  siderData: data,
  ...rest,
});

export const hideSider = () => ({
  type: HIDE_SIDER,
});

export const toggleFilters = () => ({
  type: TOGGLE_FILTERS,
});

export const showEditOrderModalAction = () => ({
  type: SHOW_EDIT_ORDER_MODAL,
});

export const hideEditOrderModalAction = () => ({
  type: HIDE_EDIT_ORDER_MODAL,
});

export const editOrderLineAction = (payload) => ({
  type: UPDATE_ORDER_LINE_START,
  ...payload,
});

export const searchProductAction = (payload) => ({
  type: SEARCH_PRODUCT_START,
  ...payload,
});

export const clearSearchProductAction = () => ({
  type: CLEAR_SEARCH,
});

export const addOrderLineAction = (payload) => ({
  type: ORDER_LINE_ADD,
  ...payload,
});

export const deleteOrderLineAction = (payload) => ({
  type: ORDER_LINE_DELETE,
  ...payload,
});

export const fetchOrderStatusesAction = () => ({
  type: FETCH_ORDER_STATUSES,
});
