import update from 'immutability-helper';

export const PRODUCTS_FETCH_START = '@products/fetch_start';
export const PRODUCTS_FETCH_SUCCESS = '@products/fetch_success';
export const PRODUCTS_FETCH_FAIL = '@products/fetch_fail';

export const PRODUCTS_FETCH_CATEGORIES_START =
  '@products/categories/fetch_start';
export const PRODUCTS_FETCH_CATEGORIES_DONE = '@products/categories/fetch_done';

export const PRODUCTS_FETCH_STATS_START = '@products/stats/fetch_start';
export const PRODUCTS_FETCH_STATS_DONE = '@products/stats/fetch_done';

export const PRODUCTS_OPEN_MODAL = '@products/modal/open';
export const PRODUCTS_CLOSE_MODAL = '@products/modal/close';

export const PRODUCTS_UPDATE_START = '@products/update/start';
export const PRODUCTS_UPDATE_END = '@products/update/end';

export const PRODUCTS_UPDATE_CATEG_START = '@products/update/categories/start';
export const PRODUCTS_UPDATE_CATEG_END = '@products/update/categories/end';

export const defaultState = {
  products: [],
  loading: false,
  total: 0,
  categories: {
    loaded: false,
    data: [],
  },
  stats: {
    loaded: false,
    data: {
      purchases: 0,
      sales: 0,
    },
  },
  inModal: null,
  showModal: false,
  modalLoading: false,
  pagination: {
    limit: 50,
    offset: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCTS_FETCH_START:
      return update(state, {
        loading: { $set: true },
      });

    case PRODUCTS_FETCH_SUCCESS:
      return update(state, {
        products: { $set: action.payload.items },
        total: { $set: action.payload.total },
        loading: { $set: false },
        pagination: { $set: action.payload.pagination },
      });

    case PRODUCTS_FETCH_FAIL:
      return update(state, {
        loading: { $set: false },
      });

    case PRODUCTS_FETCH_CATEGORIES_DONE:
      return update(state, {
        categories: {
          loaded: {
            $set: true,
          },
          data: {
            $set: action.data,
          },
        },
      });

    case PRODUCTS_FETCH_STATS_DONE:
      return update(state, {
        stats: {
          loaded: {
            $set: true,
          },
          data: {
            $set: action.data,
          },
        },
      });

    case PRODUCTS_OPEN_MODAL:
      return update(state, {
        showModal: { $set: true },
        inModal: { $set: action.payload },
      });
    case PRODUCTS_CLOSE_MODAL:
      return update(state, {
        showModal: { $set: false },
        inModal: { $set: null },
      });

    case PRODUCTS_UPDATE_START:
      return update(state, {
        modalLoading: { $set: true },
      });
    case PRODUCTS_UPDATE_END:
      return update(state, {
        modalLoading: { $set: false },
      });

    case PRODUCTS_UPDATE_CATEG_START:
      return update(state, {
        categories: {
          loaded: { $set: false },
        },
      });

    case PRODUCTS_UPDATE_CATEG_END:
      return update(state, {
        categories: {
          loaded: { $set: true },
          data: {
            $set: action.data,
          },
        },
      });

    default:
      return state;
  }
};

export const fetchProductsAction = (payload) => ({
  type: PRODUCTS_FETCH_START,
  payload,
});

export const fetchProductsCategoriesAction = () => ({
  type: PRODUCTS_FETCH_CATEGORIES_START,
});

export const fetchProductsStatsAction = () => ({
  type: PRODUCTS_FETCH_STATS_START,
});

export const openModal = (inModal = {}) => ({
  type: PRODUCTS_OPEN_MODAL,
  payload: inModal,
});

export const closeModal = () => ({
  type: PRODUCTS_CLOSE_MODAL,
});

export const updateProduct = (id, payload, refetchOptions = null) => ({
  type: PRODUCTS_UPDATE_START,
  id,
  payload,
  refetchOptions,
});

export const updateCategoriesAction = (categoriesToUpdate) => ({
  type: PRODUCTS_UPDATE_CATEG_START,
  payload: categoriesToUpdate,
});
