import React, { useEffect, useState } from 'react';
import { find, get } from 'lodash-es';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { bindActionCreators } from 'redux';
import Button from 'antd/es/button';
import Autocomplete from 'antd/es/auto-complete';
import OrderHelper from '../../../helpers/Orders';
import useBreakpoint from '../../../helpers/breakpoints';
import {
  searchCustomerAction,
  clearSearchAction,
  createOrderAction
} from '../../../redux/staging/actions';
import { notifyError } from '../../../helpers/Notify';

const { Option } = Autocomplete;

const OrderTitleSider = ({
  order,
  openSider,
  searchResults,
  clearSearch,
  search,
  createOrder,
  loading,
}) => {
  let iconSize;
  const breakpoint = useBreakpoint();
  if (breakpoint === 'xs') {
    iconSize = 'small';
  }

  const defaultCustomer = OrderHelper.customer(order, { _id: '0', name: '-' });
  const isCustomerEditable = get(order, 'values.status', '') === 'Ny';

  const [edit, setEdit] = useState(false);
  const [customer, setCustomer] = useState(defaultCustomer);

  const updateCustomer = () => {
    if(defaultCustomer._id === customer._id) {
      return;
    }
    if(!customer.wc_id) {
      notifyError('Error', 'The selected customer does not have a WC id');
      return;
    }
    const { _id, values } = order;
    const payload = OrderHelper.createNewOrderPayloadForNewCustomer(_id, values, customer);
    createOrder({ ...payload, asCustomerUpdate: true }, () => window.location.reload());
  }

  useEffect(() => {
    setCustomer(OrderHelper.customer(order, { _id: '0', name: '-' }))
  }, [order._id]);

  return (
    <>
      <span style={{ color: '#db8556', fontWeight: 'bold' }}>
        {get(order, 'values.number', '-')}
      </span>
      {'   '}
      {'>'}
      {'       '}
      {!edit ? (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              openSider(
                'customer',
                get(customer, '_id', '-')
              );
            }}
          >
            {get(customer, 'name', '-')}
          </Button>
          {isCustomerEditable && <Button
            size={iconSize}
            className="field-edit-icon"
            type="primary"
            shape="circle"
            icon="edit"
            onClick={() => setEdit(true)}
          />}
        </>
      ) : (
        <>
          <Autocomplete
            optionLabelProp="label"
            placeholder="Navn | Epost | Kunde Id"
            onSelect={(value) => {
              setCustomer(find(searchResults, (o) => o.email === value));
            }}
            onSearch={(value) => {
              if (value.length === 1) clearSearch();
              if (value.length > 2) search(value);
            }}
          >
            {(searchResults || []).map((entry) => (
              <Option key={entry.email} label={entry.email || ''}>
                {`${entry.email || ''} (${entry.name || ''}) `}
              </Option>
            ))}
          </Autocomplete>
          {!loading && <Button
            size={iconSize}
            className="field-edit-icon"
            shape="circle"
            icon="check"
            loading={loading}
            disabled={loading}
            onClick={() => updateCustomer()}
          />}
          {loading && <Icon type='loading' style={{ marginLeft: '5px' }}/>}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ staging }) => ({
  searchResults: staging.csutomerSearchResults,
  loading: staging.creatingOrder,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      search: searchCustomerAction,
      clearSearch: clearSearchAction,
      createOrder: createOrderAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderTitleSider);
