import MainScreenComponent from '../screens/dashboard/main';
import CustomersScreenComponent from '../screens/dashboard/customers';
import ReportScreenComponent from '../screens/dashboard/report';
import ProdukterScreenComponent from '../screens/dashboard/products';
import BrukereScreenComponent from '../screens/dashboard/brukere';
import FakturaScreenComponent from '../screens/dashboard/invoices';
import NotFoundScreenComponent from '../screens/errors/404';

const routes = [
  { path: '/dashboard/main', component: MainScreenComponent },
  { path: '/dashboard/customers', component: CustomersScreenComponent },
  { path: '/dashboard/report', component: ReportScreenComponent },
  { path: '/dashboard/lager/produkter', component: ProdukterScreenComponent },
  { path: '/dashboard/brukere', component: BrukereScreenComponent },
  { path: '/dashboard/faktura', component: FakturaScreenComponent },

  // hold the catch all route when nothing matches
  { component: NotFoundScreenComponent, key: 'fallback-route-99' },
];

export default routes;
