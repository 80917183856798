import update from 'immutability-helper';
import Moment from 'moment';
import DateRanges from '../../helpers/DateRanges';

export const FETCH_START = 'REPORT/start_fetch_datas';
export const FETCH_ONGOING = 'REPORT/ongoing_fetch_datas';
export const FETCH_END = 'REPORT/end_fetch_datas';
export const SET_AGGR = 'REPORT/set_aggregation';
export const SET_TYPE = 'REPORT/set_type';

export const FETCH_START_AUX = 'REPORT/start_fetch_auxiliary_datas';
export const FETCH_ONGOING_AUX = 'REPORT/ongoing_fetch_auxiliary_datas';
export const FETCH_END_AUX = 'REPORT/end_fetch_auxiliary_datas';

export const FETCH_PERIOD_START = 'REPORT/fetch_period_start';
export const FETCH_PERIOD_ONGOING = 'REPORT/fetch_period_ongoing';
export const FETCH_PERIOD_END = 'REPORT/fetch_period_end';

export const ADD_CHART_DATA = 'REPORT/add_chart_data';
export const REMOVE_CHART_DATA = 'REPORT/remove_chart_data';

const defaultState = {
  datas: [],
  loading: false,
  aggregation: 'month',
  currentRange: {},
  auxDatas: [],
  auxLoading: false,
  auxCurrentRange: {},
  type: 'totalt_no_mva',
  periods: [
    {
      ...DateRanges.previousYear(Moment().subtract(1, 'year')),
      loading: false,
      datas: {},
      color: '#26b192', // 26b192
    },
    {
      ...DateRanges.lastYear(),
      loading: false,
      datas: {},
      color: '#263644',
    },
    {
      ...DateRanges.thisYear(),
      loading: false,
      datas: {},
      color: '#1890ff', // 1890ff
    },
  ],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ONGOING:
      return update(state, {
        loading: { $set: true },
        currentRange: { $set: action.currentRange },
      });

    case FETCH_ONGOING_AUX:
      return update(state, {
        auxLoading: { $set: true },
        auxCurrentRange: { $set: action.currentRange },
      });

    case FETCH_END:
      return update(state, {
        loading: { $set: false },
        datas: { $set: action.results },
      });

    case FETCH_END_AUX:
      return update(state, {
        auxLoading: { $set: false },
        auxDatas: { $set: action.results },
      });

    case SET_AGGR:
      return update(state, {
        aggregation: { $set: action.payload },
      });

    case SET_TYPE:
      return update(state, {
        type: { $set: action.payload },
      });

    case FETCH_PERIOD_ONGOING:
      return update(state, {
        periods: {
          [action.index]: {
            start: {
              $set: action.start,
            },
            end: {
              $set: action.end,
            },
            loading: {
              $set: true,
            },
          },
        },
      });

    case FETCH_PERIOD_END:
      return update(state, {
        periods: {
          [action.index]: {
            datas: {
              $set: action.payload,
            },
            loading: {
              $set: false,
            },
          },
        },
      });

    case ADD_CHART_DATA:
      return update(state, {
        periods: {
          $push: [action.payload],
        },
      });

    case REMOVE_CHART_DATA:
      return update(state, {
        periods: {
          $splice: [[action.index, 1]],
        },
      });

    default:
      return state;
  }
};

export const fetchReport = (options, type = 'main') => ({
  type: type === 'main' ? FETCH_START : FETCH_START_AUX,
  options,
});

export const fetchPeriodReport = (options) => ({
  type: FETCH_PERIOD_START,
  ...options,
});

export const addChart = (options) => ({
  type: ADD_CHART_DATA,
  payload: options,
});

export const removeChart = (index) => ({
  type: REMOVE_CHART_DATA,
  index,
});

export const setAggregation = (aggr, periods, opts) => ({
  type: SET_AGGR,
  payload: aggr,
  periods,
  ...opts,
});

export const setType = (type, periods, opts) => ({
  type: SET_TYPE,
  payload: type,
  periods,
  ...opts,
});
