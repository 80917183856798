import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';

import withInventoryUpdate from './ValidateInventoryHOC';

const ValidateButtons = ({ updateInventories, resetAllAndClose, loading }) => (
  <Row>
    <Col xs={12}>
      <Button type="danger" onClick={() => resetAllAndClose()} block>
        Avbryt
      </Button>
    </Col>
    <Col xs={12}>
      <Button
        type="primary"
        loading={loading}
        disabled={loading}
        block
        onClick={() => {
          updateInventories();
        }}
      >
        Opprett
      </Button>
    </Col>
  </Row>
);

const mapStateToProps = ({ staging }) => ({
  loading: staging.updatingInventory,
  products: staging.products || [],
});

export default connect(mapStateToProps, null)(withInventoryUpdate(ValidateButtons));
