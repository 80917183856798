/* eslint-disable import/prefer-default-export */
import Axios from 'axios';
import QueryHelper from '../helpers/query';
import client from '../helpers/HttpClient';
import { CUSTOMERS_TABLE_COLUMNS } from '../constants/defaultValues';

export const _getCustomersPromiseOnly = (
  opts = {
    limit: 50,
    offset: 0,
    filters: {},
    sorters: {},
  }
) => {
  const query =
    `api/customers_optimized?_limit=${opts.limit}` +
    `&_skip=${opts.offset}` +
    `${QueryHelper.buildFilters(opts.filters)}` +
    `${QueryHelper.buildSorters(opts.sorters)}`;

  return client.get(query);
};

export const _getColumnsPromiseOnly = () =>
  new Promise((resolve) => resolve(CUSTOMERS_TABLE_COLUMNS));

export const getCustomersStats = () => client.get('api/customers/stats');

export const _getColumns = (cb) =>
  _getColumnsPromiseOnly()
    .then(({ data }) => {
      if (cb && typeof cb === 'function') {
        cb(null, data);
      }
    })
    .catch(cb);

export const _getCustomersAndColumns = (cb, opts) =>
  Axios.all([_getCustomersPromiseOnly(opts), _getColumnsPromiseOnly()])
    .then(
      Axios.spread((customers, columns) => {
        cb(null, customers, columns);
      })
    )
    .catch(cb);

export const updateCustomer = (id, datas) =>
  client.put(`api/customers/${id}`, datas);

export const getOneCustomer = (id) => client.get(`api/customers/${id}`);

export const httpSearchCustomer = (query) =>
  client.get(`api/customers/search?q=${query}`);

export const httpCreateCustomer = (values) =>
  client.post(`api/customers`, values);
  
export const httpSetCoordData = (values) =>
  client.post(`api/customers/coord`, values);

  export const httpSyncCustomer = (id) =>
    client.post(`api/customers/sync`, {id});
