/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'antd/es/modal';
import moment from 'moment';
import Button from 'antd/es/button';

import YearPicker from '../YearPicker';
import ColorsHelper from '../../helpers/Colors';

export default ({ visible, onCancel, addChart }) => {
  const [range, setRange] = useState({
    start: moment().startOf('year'),
    end: moment().endOf('year'),
  });

  const colorToSet = ColorsHelper.getRandom();

  return (
    <Modal visible={visible} onCancel={onCancel} footer={null} destroyOnClose>
      <h3>
        Add a new data set
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '40px',
            backgroundColor: colorToSet,
          }}
        />
      </h3>
      <YearPicker
        selected={range.start.format('YYYY')}
        onSelected={(_range) => setRange(_range)}
        min="2016"
        max="2030"
        color={colorToSet}
      />
      <Button
        type="primary"
        onClick={() => {
          if (typeof addChart === 'function') {
            addChart({
              start: range.start.format('YYYY-MM-DD'),
              end: range.end.format('YYYY-MM-DD'),
              loading: true,
              datas: {},
              color: colorToSet,
            });
          }
          onCancel();
        }}
      >
        Add
      </Button>
    </Modal>
  );
};
