/* eslint-disable no-nested-ternary,react/jsx-props-no-spreading */
import React from 'react';
import Moment from 'moment-timezone';
import StatisticsHelper from '../../helpers/Statistics';
import DateHelper from '../../helpers/dates';

const standardDateFmt = 'DD.MM.YY';

const isDateStartOfMonth = (date) =>
  Moment(date).isSame(Moment(date).startOf('month'), 'day');
const isDateEndOfMonth = (date) =>
  Moment(date).isSame(Moment(date).endOf('month'), 'day');
const isDateStartOfYear = (date) =>
  Moment(date).isSame(Moment(date).startOf('year'), 'day');
const isDateEndOfYear = (date) =>
  Moment(date).isSame(Moment(date).endOf('year'), 'day');
const isSameDay = (range) => Moment(range[0]).isSame(Moment(range[1]), 'day');
const isToday = (range) =>
  range.filter((date) => Moment(date).isSame(Moment(), 'day')).length === 2;
const isSameMonth = (range) =>
  Moment(range[0]).isSame(Moment(range[1]), 'month');
const isFullMonth = (range) =>
  isDateStartOfMonth(range[0]) && isDateEndOfMonth(range[1]);
const isFullYear = (range) =>
  isDateStartOfYear(range[0]) && isDateEndOfYear(range[1]);
const rangeToString = (range) => {
  if (isFullYear(range)) return Moment(range[0]).format('YYYY');
  if (isFullMonth(range)) return Moment(range[0]).format('MM.YY');
  if (isSameDay(range))
    return isToday(range) ? 'Idag' : Moment(range[0]).format(standardDateFmt);
  if (isSameMonth(range))
    return `${Moment(range[0]).format('Do')} - ${Moment(range[1]).format(
      standardDateFmt
    )}`;

  return `${Moment(range[0]).format(standardDateFmt)} - ${Moment(
    range[1]
  ).format(standardDateFmt)}`;
};

const RangeLabel = ({ range, ...props }) => {
  const base = rangeToString(range);

  // https://wausolutions.atlassian.net/browse/GU-218?focusedCommentId=12398
  // const rangeIsToday = DateHelper.isRangeTodayOnly(range);
  // const compare = rangeIsToday
  //   ? 'Igår'
  //   : rangeToString(StatisticsHelper.getSameRangeLastYear(range));

  return <span {...props}>{`${base}`}</span>;
};

export default RangeLabel;
