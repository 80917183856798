import React, { useState } from 'react';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Autocomplete from 'antd/es/auto-complete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { find } from 'lodash-es';
import { Img } from 'react-image';
import {
  hideEditOrderModalAction,
  searchProductAction,
  addOrderLineAction,
  clearSearchProductAction,
} from '../../redux/ActionsAndReducers/Ui';
import StringsHelper from '../../helpers/Strings';

import './styles.css';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const handleSubmit = (e, form, addOrderLine) => {
  e.preventDefault();
  e.stopPropagation();

  form.validateFields((err, values) => {
    if (!err) {
      // console.log(values);
      addOrderLine(values);
    }
  });
};
const formItemStyle = { width: '100%' };
const { Option } = Autocomplete;

const EditOrderModal = ({
  visible,
  hide,
  form,
  loading,
  searchProduct,
  products,
  addOrderLine,
  orderId,
  clearSearch,
}) => {
  const [product, setProduct] = useState(null);

  return (
    <Modal
      visible={visible}
      className="edit-order-modal"
      footer={null}
      onCancel={hide}
      title="Add a new line"
      destroyOnClose
    >
      <Form onSubmit={(e) => handleSubmit(e, form, addOrderLine)}>
        {form.getFieldDecorator('id', { initialValue: orderId })(
          <Input type="text" style={{ display: 'none' }} />
        )}
        {form.getFieldDecorator('title', {
          initialValue: (product || { navn: '' }).navn,
        })(<Input type="text" style={{ display: 'none' }} />)}
        <Row gutter={16}>
          <Col xs={24} md={10}>
            <Form.Item>
              {form.getFieldDecorator('sku', {
                rules: [{ required: true, message: 'SKU is mandatory' }],
              })(
                <Autocomplete
                  placeholder="Sku or Navn"
                  optionLabelProp="value"
                  autoFocus
                  loading={loading}
                  style={formItemStyle}
                  onSelect={(v) => {
                    const prod = find(products, (p) => p.sku === v);
                    setProduct(prod);
                  }}
                  onSearch={(value) => {
                    if (value) {
                      searchProduct({ query: value });
                    } else {
                      setProduct(null);
                      clearSearch();
                    }
                  }}
                >
                  {(products || []).map((p) => (
                    <Option key={p.sku} value={p.sku}>
                      <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        gutter={8}
                      >
                        <Col xs={5}>
                          <Img
                            src={[
                              StringsHelper.urlJoin([
                                process.env.REACT_APP_BACKEND_URL,
                                'images/product',
                                p.sku,
                              ]),
                              'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRktwbT-KQiekd5Tjjfzjd5FODUXaEipV12yg&usqp=CAU',
                            ]}
                            alt={p.navn}
                            style={{
                              display: 'block',
                              width: '100%',
                              borderRadius: '5px',
                            }}
                          />
                        </Col>
                        <Col xs={19}>
                          ({p.sku}) {p.navn}
                        </Col>
                      </Row>
                    </Option>
                  ))}
                </Autocomplete>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item>
              {form.getFieldDecorator('antall', {
                rules: [{ required: true, message: 'Antall is mandatory' }],
              })(
                <Input style={formItemStyle} type="text" placeholder="Antall" />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(form.getFieldsError()) || !product}
                loading={loading}
                block
              >
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default connect(
  ({ Ui }) => ({
    visible: Ui.editOrderModal.show,
    loading: Ui.editOrderModal.loading,
    products: Ui.editOrderModal.productInfo,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hide: hideEditOrderModalAction,
        searchProduct: searchProductAction,
        addOrderLine: addOrderLineAction,
        clearSearch: clearSearchProductAction,
      },
      dispatch
    )
)(Form.create({ name: 'orderline_modal' })(EditOrderModal));
