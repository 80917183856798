/* eslint-disable react/no-array-index-key,react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from 'react-router-dom';
import GuardedRouter from '../../components/GuardedRouter';

const renderRoutesFromMap = (routeMappings) => (
  <Switch>
    {routeMappings.map((mapping, i) => (
      <GuardedRouter.GuardedRoute {...mapping} key={i} />
    ))}
  </Switch>
);

export default renderRoutesFromMap;
