/* eslint-disable no-prototype-builtins */
import update from 'immutability-helper';
import { get, findKey } from 'lodash-es';
import computeOrderIds from '../../helpers/computeOrderIds';
import patchValues from '../../helpers/patchValues';
// import { atomicUpdate } from '../ActionsAndReducers/Orders';

export default class Order {
  constructor() {
    this.orders = [];
    this.columns = [];
    this.loading = false;
    this.error = null;
    this.fieldsLoading = {};
    this.orderShowingInModal = null;
    this.total = 0;
    this.detailsIsSaving = false;
    this.pagination = {};
    this.kpis = {
      daily: {
        current_volum: 0,
        last_volum: 0,
        current_anall: 0,
        last_antall: 0,
      },
      monthly: {
        current_volum: 0,
        last_volum: 0,
        current_anall: 0,
        last_antall: 0,
      },
    };
    this.kpi_loading = false;
    this.changes = {};
    this.atomicUpdates = {};
    this.stats = {
      idag: {},
      mnd: {},
    };
    this.statsLoading = false;
  }

  pushNewOrder(order) {
    const orderWithIds = computeOrderIds([order]);

    return update(this, {
      orders: {
        $unshift: orderWithIds.map((o) => patchValues(o, this.columns)),
      },
    });
  }

  resetChanges() {
    return update(this, {
      changes: { $set: {} },
    });
  }

  addChanges(changes) {
    return update(this, {
      changes: {
        $apply: (values) => {
          Object.entries(changes.values).forEach(
            // eslint-disable-next-line no-unused-vars
            ([_, value]) => {
              // eslint-disable-next-line no-param-reassign
              values[value.name] = value.value;
            }
          );

          return values;
        },
      },
    });
  }

  setKpi(val) {
    return update(this, {
      kpis: { $set: val },
    });
  }

  setKpiLoading(val) {
    return update(this, {
      kpi_loading: { $set: val },
    });
  }

  detailsSaving(state) {
    return update(this, {
      detailsIsSaving: { $set: state },
    });
  }

  _findThisOrderKey(orderId) {
    return parseInt(
      findKey(this.orders, (order) => order._id === orderId),
      10
    );
  }

  setColumns(cols) {
    window.columns = cols;
    return update(this, {
      columns: { $set: cols },
      loading: { $set: false },
    });
  }

  setOrders(ord, total) {
    return update(this, {
      orders: { $set: ord },
      loading: { $set: false },
      total: { $set: total },
    });
  }

  setOrderShowingInModal(order) {
    return update(this, {
      orderShowingInModal: {
        $set: order,
      },
    });
  }

  replaceOrder(orderId, newOrder) {
    const keyOfOrder = this._findThisOrderKey(orderId);
    if (keyOfOrder === undefined) {
      return this;
    }

    // eslint-disable-next-line no-param-reassign
    newOrder.values.orderId = get(newOrder, 'values.number', '0');

    return update(this, {
      orders: {
        [keyOfOrder]: {
          $set: newOrder,
        },
      },
    });
  }

  setOneOrder(ordId, key, value) {
    const keyOfOrder = this._findThisOrderKey(ordId);
    if (keyOfOrder === undefined) {
      return this;
    }

    const newValues = {
      ...this.orders[keyOfOrder].values,
      [key]: value,
    };

    return update(this, {
      orders: {
        [keyOfOrder]: {
          values: { $set: newValues },
        },
      },
    });
  }

  setPagination(opts) {
    return update(this, { pagination: { $set: opts } });
  }

  setColumnsAndOrders(cols, ords, total) {
    const newSelf = this.setColumns(cols);
    return newSelf.setOrders(ords, total);
  }

  toggleLoading() {
    return update(this, { loading: { $set: !this.loading } });
  }

  setError(err) {
    return update(this, {
      loading: { $set: false },
      error: { $set: err },
    });
  }

  toggleFieldLoading(key) {
    return update(this, {
      fieldsLoading: {
        [key]: {
          $set: !get(this, `fieldsLoading.${key}`, false),
        },
      },
    });
  }

  removeFile(orderId, fileId) {
    const orderIndex = this._findThisOrderKey(orderId);
    if (orderIndex < 0) {
      // eslint-disable-next-line no-console
      console.error('Order index not found');

      return this;
    }

    const fileIndex = findKey(
      this.orders[orderIndex].files,
      (file) => file.id === fileId
    );

    return update(this, {
      orders: {
        [orderIndex]: {
          files: {
            $splice: [[fileIndex, 1]],
          },
        },
      },
    });
  }

  addFile(id, action, value = null) {
    const orderIndex = this._findThisOrderKey(id);
    if (orderIndex < 0) {
      // eslint-disable-next-line no-console
      console.error('Order index not found');

      return this;
    }

    return update(this, {
      orders: {
        [orderIndex]: {
          files: {
            $push: [value],
          },
        },
      },
    });
  }

  setAtomicUpdates(args) {
    const keyOfOrder = findKey(this.orders, (o) => o._id === args.id);
    const atomicUpdateExists = this.atomicUpdates.hasOwnProperty(args.id);
    const u = {
      orders: {
        [keyOfOrder]: {
          values: {
            [args.key]: { $set: args.value },
          },
        },
      },
    };

    if (atomicUpdateExists) {
      u.atomicUpdates = {
        [args.id]: {
          [args.key]: {
            $set: { loading: args.loading },
          },
        },
      };
    } else {
      u.atomicUpdates = {
        [args.id]: {
          $set: {
            [args.key]: { loading: args.loading },
          },
        },
      };
    }

    return update(this, u);
  }

  toggleStatsLoading() {
    return update(this, { $toggle: ['statsLoading'] });
  }

  setStats(stats) {
    return update(this, {
      stats: { $set: stats },
    });
  }
}
