/* eslint-disable import/prefer-default-export */
import Moment from 'moment';
import client from '../helpers/HttpClient';

export const fetch = (tz = process.env.REACT_APP_TIMEZONE) =>
  client.get(`api/statistics?tz=${tz}`);
export const getReport = async ({ start, end, aggr, dataType }) =>
  client.get(
    `api/report?aggregate=${
      aggr || 'month'
    }&start=${start}&end=${end}&fullscale=1&type=${dataType}&offset=${Moment().utcOffset()}`
  );

export const httpGetTableReports = (
  start = Moment().startOf('month').utc().format('YYYY-MM-DD'),
  end = Moment().endOf('month').utc().format('YYYY-MM-DD')
) =>
  client.get(
    `api/report/table?start=${start}&end=${end}&offset=${
      Moment().utcOffset() / 60
    }`
  );
