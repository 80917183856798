/* eslint-disable react/prop-types */
import React from 'react';
import { get } from 'lodash-es';
import Button from 'antd/es/button';

import InvoiceStatusButton from '../../Invoices/InvoiceStatusButton/InvoiceStatusButton';

const InvoiceTitleSider = ({ invoice, openSider, statusBtnAction }) => (
  <>
    <span style={{ color: '#db8556', fontWeight: 'bold' }}>
      {get(invoice, 'values.fakturanummer', '-')}
    </span>
    {'   '}
    {'>'}
    {'       '}
    <Button
      type="primary"
      size="small"
      onClick={() => {
        openSider('customer', get(invoice, 'values.customer', '-'));
      }}
    >
      {get(invoice, 'values.customers.name', '-')}
    </Button>

    <div
      style={{ display: 'inline-block', position: 'absolute', right: '58px' }}
    >
      <InvoiceStatusButton
        size="default"
        isEditable
        value={get(invoice, 'values.status', '-')}
        btnAction={(value) => {
          if (typeof statusBtnAction === 'function') {
            statusBtnAction({
              id: get(invoice, 'id', ''),
              value,
              orderNumber: get(invoice, 'values.orders.values.number', ''),
            });
          }
        }}
        allowUnset={false}
      />
    </div>
  </>
);

export default InvoiceTitleSider;
