/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Popover from 'antd/es/popover';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Badge from 'antd/es/badge';
import List from 'antd/es/list';
import Icon from 'antd/es/icon';
import moment from 'moment';

const YearPicker = ({
  color,
  selected = '2020',
  onSelected = null,
  min = '2016',
  max = '2024',
  onRemoved = null,
}) => {
  const buttonStyle = {
    backgroundColor: color,
    borderColor: color,
  };
  const defValue =
    typeof selected === 'number' ? selected.toString() : selected;
  const _min = parseInt(min, 10);
  const _max = parseInt(max, 10);
  const years = [];

  for (let i = _min; i <= _max; i++) {
    years.push(i.toString());
  }

  const [valueSelected, setValueSelected] = useState(defValue);
  const [visible, setVisibility] = useState(false);
  useEffect(() => {
    setValueSelected(defValue);
  }, [selected]);

  const navigate = (direction) => {
    const currentValueToInt = parseInt(valueSelected, 10);
    const finalValue =
      direction === 'left'
        ? (currentValueToInt - 1).toString()
        : (currentValueToInt + 1).toString();

    if (years.indexOf(finalValue) < 0) {
      return false;
    }

    setValueSelected(finalValue);
    if (typeof onSelected === 'function') {
      onSelected({
        start: moment(finalValue).startOf('year'),
        end: moment(finalValue).endOf('year'),
      });
    }

    return true;
  };

  const popoverContent = (
    <Row style={{ width: '100px', textAlign: 'center' }} gutter={16}>
      <Col xs={24}>
        <List
          size="small"
          bordered
          dataSource={years}
          renderItem={(item) => (
            <List.Item
              className="list-button"
              onClick={() => {
                setValueSelected(item);
                setVisibility(false);
                if (typeof onSelected === 'function') {
                  onSelected({
                    start: moment(item).startOf('year'),
                    end: moment(item).endOf('year'),
                  });
                }
              }}
            >
              {item}
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <Badge
      offset={[0, -3]}
      className="remove-chart-icon"
      count={
        onRemoved ? (
          <Icon
            className="icon-close"
            type="close-circle"
            onClick={() => onRemoved()}
            theme="twoTone"
            twoToneColor={color}
            style={{ fontSize: '20px', zIndex: 2, cursor: 'pointer' }}
          />
        ) : null
      }
    >
      <Button.Group>
        <Button
          style={buttonStyle}
          type="primary"
          onClick={() => navigate('left')}
        >
          <Icon type="left" />
        </Button>
        <Popover
          placement="bottom"
          trigger="click"
          content={popoverContent}
          visible={visible}
          onVisibleChange={setVisibility}
        >
          <Button type="primary" style={buttonStyle}>
            {valueSelected}
          </Button>
        </Popover>
        <Button
          type="primary"
          onClick={() => navigate('right')}
          style={buttonStyle}
        >
          <Icon type="right" />
        </Button>
      </Button.Group>
    </Badge>
  );
};

export default YearPicker;
