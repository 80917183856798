import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { concat } from 'lodash-es';
import getters from '../../helpers/General/getters';
import RelatedButton from './RelatedButton';

import './RelatedOrders.css';

const renderRelated = (relatedArray) => (
  <Row>
    <Col xs={24}>
      <hr style={{ border: '.5px solid #e8e8e8', margin: '1rem 0px' }} />
      <h3 style={{ fontWeight: 'bolder', fontSize: '14px' }}>Related orders</h3>
      {relatedArray.map((related) => (
        <RelatedButton title={related.number} order_id={related._id} />
      ))}
    </Col>
  </Row>
);

const RelatedOrders = ({ order }) => {
  const restOrders = getters.getAsArray(order, ['values', 'rest']);
  const originalOrder = getters.getAsArray(order, ['values', 'original']);
  const hasRelated = restOrders.length > 0 || originalOrder.length > 0;

  return hasRelated ? renderRelated(concat(restOrders, originalOrder)) : null;
};

export default RelatedOrders;
