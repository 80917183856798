/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Col from 'antd/es/col';
import Autocomplete from 'antd/es/auto-complete';
import InputNumber from 'antd/es/input-number';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import { get, find, findIndex } from 'lodash-es';

import GiftWrapping from './GiftWrapping';
import {
  searchProductAction,
  clearSearchProductAction,
  setProductInListAction,
  removeProductAtAction,
  removeManyProductsAction,
} from '../../redux/staging/actions';

export const totalPris = (_p) =>
  _p.reduce(
    (accumulator, currentLine) =>
      accumulator +
      parseFloat(get(currentLine, 'product.pris_inn', '0')) *
        (currentLine.amount || 0),
    0
  );
export const totalAntall = (_p) =>
  _p.reduce(
    (accumulator, currentLine) =>
      accumulator + (currentLine.amount || 0),
    0
  );
// eslint-disable-next-line no-mixed-operators
const totalMva = (_p) =>
  _p.reduce(
    (accumulator, currentLine) =>
      accumulator +
      ((parseFloat(get(currentLine, 'product.pris_inn', '0')) *
        (currentLine.amount || 0)) 
        // / 1.25) 
        *
        0.25),
    0
  );
const produceTableColumns = (
  deleteFunc,
  showGwFunc,
  clearInnpakkingFunc,
  wrappingStatus,
  updateProductLine
) => [
  { title: 'SKU', dataIndex: 'product.sku', key: 'sku' },
  { title: 'Navn', dataIndex: 'product.navn', key: 'navn' },
  { title: 'Antall', dataIndex: 'amount', key: 'amount',
    render: (p, record) => (
      <InputNumber
      className="expand-on-small"
      // ref={amountInputRef}
      min={0}
      max={999999}
      value={record.amount}
      onChange={(val) => updateProductLine({ ...record, amount: val })}
      // onPressEnter={addProductLine}
    />      
      )
  },
  {
    title: 'Beløp',
    dataIndex: 'product',
    key: 'belop',
    render: (p, record, index) => {
      const total = parseFloat(get(p, 'pris_inn', '0')) * (record.amount || 0);

      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{total.toFixed(2)}</div>
          <Button
            size="small"
            shape="circle"
            type="danger"
            icon="close"
            onClick={() => deleteFunc(index)}
          />
        </div>
      );
    },
  },
  {
    title: (
      <Button
        size="small"
        type={wrappingStatus[0] === true ? 'primary' : 'default'}
        icon="gift"
        onClick={() => {
          // Clear all innpakking related to individual products
          clearInnpakkingFunc(true);
          showGwFunc(true, { navn: 'All products', amount: 1, _id: 0 });
        }}
      />
    ),
    dataIndex: 'gift-wrapping',
    key: 'gift-wrapping',
    render: (p, record) => {
      if (get(record, ['product', 'navn'], null) === 'Innpakking') {
        return null;
      }
      return (
        <div style={{ textAlign: 'center' }}>
          <Button
            size="small"
            type={
              wrappingStatus[get(record, ['product', '_id'], 1)] === true
                ? 'primary'
                : 'default'
            }
            icon="gift"
            onClick={() => {
              // Clear all innpakking related to all products
              clearInnpakkingFunc(false);
              showGwFunc(true, {
                navn: get(record, ['product', 'navn'], null),
                amount: record.amount || 1,
                _id: get(record, ['product', '_id'], null),
              });
            }}
          />
        </div>
      );
    },
  },
];

const ProductStep = ({
  searchProduct,
  searchResults,
  setStep,
  otherState,
  setOtherState,
  clearSearchResults,
  addTempProduct,
  products,
  removeProductAt,
  removeManyProducts,
}) => {
  // const [products, _setProducts] = useState(productsState);
  const [tempLine, setTempLine] = useState({ product: null, amount: 0 });
  const [searchText, setSearchText] = useState('');
  const amountInputRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [gwModalShown, showGwModal] = useState(false);
  const [productInGw, setProductInGw] = useState({
    navn: 'No Name',
    amount: 0,
    _id: null,
  });
  const [wrappingStatus, setWrappingStatus] = useState({});

  const innpakkProduct = (_id, status) => {
    // Set in state what kind of wrapping it is (for all products or individual)
    if (_id !== null) {
      setWrappingStatus({
        ...wrappingStatus,
        [_id]: status,
      });
    }
  };

  const clearAllInnpakking = (individualInnpakking = true) => {
    const innpakkingIndexes = products
      .map((p, i) => {
        const isInnpakking = get(p, 'product.sku', 0) === '50000';
        const isForAll = get(p, 'metas.gw.for', null) === 0;
        if (isInnpakking && individualInnpakking && !isForAll) return i;
        if (isInnpakking && !individualInnpakking && isForAll) return i;

        return null;
      })
      .filter((index) => index !== null);

    if (innpakkingIndexes.length > 0) {
      const allWrappedProducts = Object.keys(wrappingStatus);
      const newWrappingStatus = { ...wrappingStatus };
      // eslint-disable-next-line no-return-assign
      allWrappedProducts.map((_id) => (newWrappingStatus[_id] = false));
      setWrappingStatus(newWrappingStatus);

      removeManyProducts({ sku: '50000' });
    }
  };

  const showGwModalWith = (
    gwVisible,
    product = { navn: 'No Name', amount: 0, _id: null }
  ) => {
    // find out the already set messages
    const innpakking = products.filter(
      (p) => get(p, 'metas.gw.for', null) === product._id
    );
    const messages = get(innpakking, '[0]metas.gw.messages', []);
    const productToShow = { ...product };
    // append message if any
    if (messages.length > 0) {
      productToShow.messages = messages;
    }

    setProductInGw(productToShow);
    showGwModal(gwVisible);
    // Set an Innpakking product line to be added later
    setTempLine({
      ...tempLine,
      product: {
        navn: 50000,
        sku: 50000,
        pris_ut: 0,
      },
      amount: product.amount,
      loading: true,
    });
  };

  const totalWeight = (_p) =>
    _p.reduce(
      (accumulator, currentLine) =>
        accumulator +
        parseFloat(get(currentLine, 'product.vekt', '0')) *
          (currentLine.amount || 0),
      0
    );
  const saveOtherStates = (p = null) => {
    const productState = p || products;
    const weight = totalWeight(productState);
    const totalt = totalPris(productState);
    setOtherState({
      ...otherState,
      vekt: weight,
      totalt,
      frakt: totalt * 0.08,
      leveringstype: weight > 3000 ? 'Sporingspakke' : 'RFID',
      mva: totalMva(p),
    });
  };

  useEffect(() => {
    if (products) {
      saveOtherStates(products);
    }
  }, [products]);

  const moveToStep = (step) => {
    saveOtherStates(products);
    setStep(step);
  };
  const deleteProductLine = (index) => {
    const newProducts = products.filter((p, id) => id !== index);
    removeProductAt(index);
    saveOtherStates(newProducts);
  };
  const cleanup = () => {
    setSearchText('');
    setTempLine({ product: null, amount: 0 });
    autoCompleteRef.current.focus();
  };

  const updateProductLine = (targetProduct) => {
    const indexOfLine = findIndex(products, (p) => {
      if (p.product._id === undefined || targetProduct.product._id === undefined) {
        return p.product.sku === targetProduct.product.sku;
      }
      return p.product._id === targetProduct.product._id;
    });

    const newProducts = [...products];
    newProducts[indexOfLine].amount = targetProduct.amount;
    // add the product line to redux, so it gets resolved eventually
    addTempProduct(newProducts[indexOfLine], indexOfLine);
  }
  const productsTableColumns = produceTableColumns(
    deleteProductLine,
    showGwModalWith,
    clearAllInnpakking,
    wrappingStatus,
    updateProductLine
  );
  const addProductLine = (metas = null) => {
    if (tempLine.product === null || tempLine.amount === 0) {
      return;
    }
    // handle same products
    const sameObjectKey = findIndex(products, (p) => {
      if (p.product._id === undefined || tempLine.product._id === undefined) {
        return p.product.sku === tempLine.product.sku;
      }
      return p.product._id === tempLine.product._id;
    });
    // handle same pakking
    const samePakkingKey = findIndex(
      products,
      (p) => get(p, 'metas.gw.for', null) === get(metas, 'gw.for', -1)
    );
    const _products = [...products];
    console.log('SAME PAKKING KEY', samePakkingKey, _products);
    if (sameObjectKey >= 0) {
      _products[sameObjectKey] = {
        ...tempLine,
        amount: tempLine.amount + _products[sameObjectKey].amount,
      };
    } else if (samePakkingKey >= 0) {
      // Just update
      _products[samePakkingKey] = {
        ...tempLine,
      };
    } else {
      // just append if no products match
      _products.push(tempLine);
    }
    const indexOfLine =
      sameObjectKey >= 0
        ? sameObjectKey
        : samePakkingKey >= 0
        ? samePakkingKey
        : _products.length - 1;

    // Handle adding metas to the new product
    if (metas !== null && metas.gw) {
      _products[indexOfLine].metas = { ...metas };
    }

    // add the product line to redux, so it gets resolved eventually
    addTempProduct(_products[indexOfLine], indexOfLine);
    cleanup();
  };

  return (
    <Col xs={24}>
      <GiftWrapping
        visible={gwModalShown}
        onCancel={() => {
          // Remove wrapping in state
          // const keyToUpdate = get(productInGw, '_id', null);
          // innpakkProduct(keyToUpdate, false);
          showGwModalWith(false);
        }}
        wrapFor={productInGw}
        // This will add innpakking produkt that was set in `showGwModalWith` function
        onSave={(messages) => {
          // Set in state what kind of wrapping it is (for all products or individual)
          const keyToUpdate = get(productInGw, '_id', null);
          innpakkProduct(keyToUpdate, true);
          // build metas for the new Innpakking
          const metas = {
            gw: {
              for: productInGw._id,
              messages,
            },
          };
          addProductLine(metas);
        }}
      />
      <Divider>Varelinjer</Divider>
      <div className="addmodal-form" style={{ paddingBottom: '1rem' }}>
        <Table
          className="addmodal-form-product-table"
          columns={productsTableColumns}
          dataSource={products}
          size="small"
          pagination={false}
          bordered
          rowClassName={(record) => {
            if (record.errored) return 'row-errored';
            if (record.loading) return 'row-loading';

            return 'row-normal';
          }}
          footer={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span style={{ fontWeight: 'bold' }}>Totalt : </span>{' '}
                {totalPris(products).toFixed(2)}
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Antall : </span>{' '}
                {totalAntall(products)}
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Vekt : </span>{' '}
                {totalWeight(products) / 1000} kgs
              </div>
            </div>
          )}
        />
      </div>
      <div className="product-modal action-container">
        <Autocomplete
          autoFocus
          className="expand-on-small"
          placeholder="SKU or Name"
          ref={autoCompleteRef}
          onSearch={(value) => {
            clearSearchResults(value);
            searchProduct(value);
          }}
          value={searchText}
          onChange={(text) => setSearchText(text)}
          optionLabelProp="val"
          onSelect={(value) => {
            setTempLine({
              ...tempLine,
              product: find(searchResults, (o) => o.sku === value) || {
                navn: value,
                sku: value,
                pris_ut: 0,
              },
              amount: null,
              loading: true,
            });
            amountInputRef.current.focus();
            clearSearchResults();
          }}
        >
          {(searchResults || []).map((entry) => (
            <Autocomplete.Option key={entry.sku} val={entry.sku}>
              <span style={{ fontWeight: 'bold' }}>({entry.sku})</span>{' '}
              {entry.navn || ''}
            </Autocomplete.Option>
          ))}
        </Autocomplete>
        <InputNumber
          className="expand-on-small"
          ref={amountInputRef}
          min={0}
          max={999999}
          value={tempLine.amount}
          onChange={(val) => setTempLine({ ...tempLine, amount: val })}
          onPressEnter={addProductLine}
        />
        <Button
          className="expand-on-small"
          type="primary"
          onClick={addProductLine}
          disabled={tempLine.amount === 0 || tempLine.product === null}
        >
          Add product
        </Button>
      </div>

      {/* <Col xs={24} style={{ marginTop: '1.5rem' }}>
        <div
          style={{
            width: '80%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button type="primary" onClick={() => moveToStep(0)}>
            Previous
          </Button>
          {products.length > 0 && (
            <Button type="primary" onClick={() => moveToStep(2)}>
              Next
            </Button>
          )}
        </div>
      </Col> */}
    </Col>
  );
};

const mapStateToProps = ({ staging }) => ({
  searchResults: staging.productsSearchResults,
  products: staging.products,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      searchProduct: searchProductAction,
      clearSearchResults: clearSearchProductAction,
      addTempProduct: setProductInListAction,
      removeProductAt: removeProductAtAction,
      removeManyProducts: removeManyProductsAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductStep);
