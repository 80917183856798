/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import moment from 'moment-timezone';
import {
  get,
  set,
  flatten,
  filter,
  find,
  cloneDeep,
  groupBy,
  mapValues,
  has,
} from 'lodash-es';
import Strings from './Strings';

const getBiggestDatasInPeriods = (periods) => {
  const biggestDatas = Math.max(
    ...periods.map((period) => Object.keys(period.datas).length)
  );

  // find the datas
  for (const p in periods) {
    if (periods[p].datas.length === biggestDatas) {
      return periods[p];
    }
  }

  return periods[0];
};

const getLongestDatas = (allDatas) => {
  const longestDatas = Math.max(...allDatas.map((d) => d.values.length));
  console.log('INN', allDatas);
  return allDatas.filter((d) => d.values.length === longestDatas)[0];
};

const getQuartersFor = (year) => {
  const quarters = [];
  const yearDate = `${year}-01-01`;
  quarters.push({
    label: 'Quarter #1',
    start: moment(yearDate, 'YYYY-MM-DD').month(0).startOf('month'),
    end: moment(yearDate, 'YYYY-MM-DD').month(2).endOf('month'),
  });
  quarters.push({
    label: 'Quarter #2',
    start: moment(yearDate, 'YYYY-MM-DD').month(3).startOf('month'),
    end: moment(yearDate, 'YYYY-MM-DD').month(5).endOf('month'),
  });
  quarters.push({
    label: 'Quarter #3',
    start: moment(yearDate, 'YYYY-MM-DD').month(6).startOf('month'),
    end: moment(yearDate, 'YYYY-MM-DD').month(8).endOf('month'),
  });
  quarters.push({
    label: 'Quarter #4',
    start: moment(yearDate, 'YYYY-MM-DD').month(9).startOf('month'),
    end: moment(yearDate, 'YYYY-MM-DD').month(11).endOf('month'),
  });

  return quarters;
};

const buildDatasFromDaily = (periodDatas, groupByFormat = 'YYYY-MM') => {
  const clone = cloneDeep(periodDatas);
  const dailyDatasToMonth =
    find(clone, (d) => d.type === 'daily_report') || null;
  if (!dailyDatasToMonth) return {};

  const dailyDatasWithInjectedDate = { ...dailyDatasToMonth.values };
  Object.keys(dailyDatasWithInjectedDate).forEach((d) => {
    dailyDatasWithInjectedDate[d].date = d;
  });

  // group by month and reduce
  const grouppedByMonth = mapValues(
    groupBy(dailyDatasToMonth.values, (ob) =>
      moment(ob.date, 'YYYY-MM-DD').format(groupByFormat)
    ),
    (v) =>
      v.reduce(
        (acc, current) => ({
          ...current,
          total: acc.total + current.total,
          count: acc.count + current.count,
        }),
        { total: 0, count: 0 }
      )
  );

  dailyDatasToMonth.values = grouppedByMonth;

  return dailyDatasToMonth;
};

const buildQuarterDatas = (periodDatas) => {
  const clone = cloneDeep(periodDatas);
  const dailyDatas = find(clone, (d) => d.type === 'daily_report') || null;
  if (!dailyDatas) return {};
  const year = moment(
    Object.keys(dailyDatas.values).pop(),
    'YYYY-MM-DD'
  ).format('YYYY');
  const quartersDates = getQuartersFor(year);
  const quarterDatas = { values: {} };
  const getQValuesFor = (dailyValues, qData) =>
    Object.entries(dailyValues).reduce(
      (acc, [d, v]) => {
        const cDate = moment(d, 'YYYY-MM-DD');
        const dateisInQuarter =
          cDate.isSameOrAfter(qData.start, 'day') &&
          cDate.isSameOrBefore(qData.end, 'day');

        if (dateisInQuarter) {
          return { total: acc.total + v.total, count: acc.count + v.count };
        }

        return acc;
      },
      { total: 0, count: 0 }
    );

  quartersDates.forEach((data) => {
    quarterDatas.values[data.label] = getQValuesFor(dailyDatas.values, data);
  });

  return quarterDatas;
};

const fillMissingWeeks = (datas, fillinData = { total: 0, count: 0 }) => {
  // Find the missing week numbers across all datas
  const allWeekNumbers = [];
  datas.forEach((d) => {
    Object.keys(d.values || {}).forEach((weekNumber) =>
      allWeekNumbers.push(weekNumber)
    );
  });
  const uniqueWN = new Set([...allWeekNumbers]);
  const clonedDatas = cloneDeep(datas);

  // replace each missing data
  datas.map((d, index) => {
    uniqueWN.forEach((wn) => {
      if (!has(d.values, wn)) {
        set(clonedDatas, [index, 'values', wn], fillinData);
      }
    });
  });

  return clonedDatas;
};

const buildDatas = (
  allDatas,
  periods,
  dataTypeName = 'totalt_no_mva',
  aggr = 'month'
) => {
  const finalDatas = { datasets: [] };
  const isAntall = dataTypeName === 'antall';
  const dataType = isAntall ? 'totalt_no_mva' : dataTypeName;
  let labelFormat = 'MMMM';
  let labelParsingFormat = 'YYYY-MM';
  let concerningDatas = periods.map((p) => {
    const datasForThisTypeAndPeriod = filter(
      allDatas,
      (d) => d.title === `${moment(p.start).format('YYYY')}_${dataType}`
    );
    switch (aggr) {
      case 'month':
        labelFormat = 'MMMM';
        labelParsingFormat = 'YYYY-MM-DD';
        return buildDatasFromDaily(datasForThisTypeAndPeriod);

      case 'day':
        labelFormat = 'Do MMM';
        labelParsingFormat = 'YYYY-MM-DD';
        return (
          find(datasForThisTypeAndPeriod, (d) => d.type === 'daily_report') ||
          null
        );

      case 'week':
        return buildDatasFromDaily(datasForThisTypeAndPeriod, 'W');

      case 'quarter':
        return buildQuarterDatas(datasForThisTypeAndPeriod);

      case 'year':
        labelFormat = 'YYYY';
        labelParsingFormat = 'YYYY';
        return buildDatasFromDaily(datasForThisTypeAndPeriod, 'YYYY');

      default:
        labelParsingFormat = 'YYYY-MM';
        labelFormat = 'MMMM';
        return (
          find(datasForThisTypeAndPeriod, (d) => d.type === 'monthly_report') ||
          null
        );
    }
  });

  // Fill missing weeks datas with zero datas
  concerningDatas =
    aggr === 'week' ? fillMissingWeeks(concerningDatas) : concerningDatas;

  periods.map((period, i) => {
    const objectToSet = { borderWidth: 1 };
    let datas = [];
    datas = Object.values(get(concerningDatas, [i, 'values'], {})).map((d) => {
      if (isAntall) return d.count || 0;

      return d.total || 0;
    });

    set(objectToSet, 'data', datas);
    set(
      objectToSet,
      'label',
      `${Strings.capitalize(dataType)} from ${moment(period.start).format(
        'YYYY'
      )}`
    );
    set(
      objectToSet,
      'backgroundColor',
      get(period, 'color', 'rgba(38, 177, 147, 0.2)')
    );
    set(
      objectToSet,
      'borderColor',
      get(period, 'color', 'rgba(38, 177, 147, 1)')
    );
    set(
      objectToSet,
      'hoverBackgroundColor',
      get(period, 'color', 'rgba(38, 177, 147, 1)')
    );
    set(
      objectToSet,
      'hoverBorderColor',
      get(period, 'color', 'rgba(38, 177, 147, 1)')
    );

    finalDatas.datasets.push(objectToSet);
  });

  if (aggr === 'year') {
    finalDatas.labels = ['YEARLY'];
  } else if (aggr === 'quarter') {
    finalDatas.labels = Object.keys(get(concerningDatas, [0, 'values'], {}));
  } else if (aggr === 'month') {
    finalDatas.labels = Object.keys(
      get(concerningDatas, [0, 'values'], {})
    ).map((k) => moment(k, labelParsingFormat).format(labelFormat));
    // Get unique months
    finalDatas.labels = [...new Set(finalDatas.labels)];
  } else {
    finalDatas.labels = Object.keys(
      get(concerningDatas, [0, 'values'], {})
    ).map((k) =>
      aggr === 'week'
        ? `#${k}`
        : moment(k, labelParsingFormat).format(labelFormat)
    );
  }

  return finalDatas;
};

const periodsAreLoading = (periods) => {
  const data = periods || [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].loading) {
      return true;
    }
  }

  return false;
};

const buildCompareDatas = (periods) => {
  const dataRange = Math.max(
    ...periods.map((period) => Object.entries(period.datas || {}).length)
  );
  const finalDatas = [];

  for (let i = 0; i < dataRange; i++) {
    const objectValues = {};
    periods.map((period, index) => {
      set(objectValues, `period-${index}`, {
        value: get(Object.values(period.datas || {}), `[${i}].total`, 0),
        date: get(Object.keys(period.datas || {}), `[${i}]`, '-'),
        start: moment(get(period, 'start', undefined)).format('Do MMM YYYY'),
        end: moment(get(period, 'end', undefined)).format('Do MMM YYYY'),
      });
    });

    set(objectValues, 'uid', `uid-${i}`);

    finalDatas.push(objectValues);
  }
  return finalDatas;
};

const buildCompareTableColumns = (compareDatas, dataTypeName) => {
  const exampleLine = compareDatas[0] || {};
  const periodKeys = Object.keys(exampleLine).filter((k) => k !== 'uid');

  return flatten(
    periodKeys.map((key) => {
      const dateColumn = {
        title: 'Date',
        dataIndex: key,
        key: `${key}-date`,
        render: (entry) =>
          entry.date === '-' ? '-' : moment(entry.date).format('Do MMM YYYY'),
      };

      const valueColumn = {
        title: `${dataTypeName} from ${compareDatas[0][key].start} to ${compareDatas[0][key].end}`,
        dataIndex: key,
        key: `${key}-value`,
        render: (entry) => `${entry.value.toFixed(2)} NOK`,
      };

      return [dateColumn, valueColumn];
    })
  );
};

const timeScaleBoundaries = (periods, aggregation) => {
  if (!Array.isArray(periods) || periods.length < 1) {
    return {
      min: 0,
      max: 1,
    };
  }

  const minDate = moment.min(...periods.map((period) => moment(period.start)));
  const maxDate = moment.max(...periods.map((period) => moment(period.end)));

  return {
    min: minDate.subtract(1, aggregation).toDate(),
    max: maxDate.add(1, aggregation).toDate(),
  };
};

const buildChartOptions = (periods, aggregation) => ({
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    // xAxes: [{
    //   type: 'time',
    //   ticks: timeScaleBoundaries(periods, aggregation),
    //   time: {
    //     unit: aggregation,
    //     displayFormats: {
    //       day: 'Do MMM YY',
    //       month: 'MMMM YYYY',
    //     },
    //   },
    // }],
    yAxes: [
      {
        ticks: {
          callback(value) {
            return `NOK ${value}`;
          },
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
});

/**
 * Filter out datas so that it only contains relevant datas for specified date range
 */
const filterForRange = (statistics, [start, end]) => {
  const stats = cloneDeep(statistics);

  return stats.map((stat) => {
    Object.keys(stat.values).forEach((d) => {
      const dFormat = stat.type === 'monthly_report' ? 'YYYY-MM' : 'YYYY-MM-DD';
      const reportDate = moment(d, dFormat);
      const reportYear = reportDate.format('YYYY');
      const endForYear = moment(end).year(reportYear);
      const startForYear = moment(start).year(reportYear);
      const dateIsInRange =
        reportDate.isSameOrBefore(endForYear) &&
        reportDate.isSameOrAfter(startForYear);

      // Remove from object if not in range for designated year
      if (!dateIsInRange) delete stat.values[d];
    });

    return stat;
  });
};

export default {
  buildDatas,
  periodsAreLoading,
  buildCompareDatas,
  buildCompareTableColumns,
  timeScaleBoundaries,
  buildChartOptions,
  filterForRange,
};
