/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import PropTypes from 'prop-types';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Icon from 'antd/es/icon';

import { ADMIN_ROLE, LAGER_ROLE } from '../../constants/defaultValues';
import {
  fetchUsers,
  addUser,
  toggleModal,
  deleteUser,
  updateUser,
} from '../../redux/ActionsAndReducers/Brukere';
import './style.css';

const columns = [
  {
    title: 'Navn',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Epost',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Access',
    key: 'roles',
    dataIndex: 'roles',
    render: (values) =>
      (values || []).map((v) => (
        <Tag color={v === 'Admin' ? 'teal' : 'grey'}>{v}</Tag>
      )),
  },
];

const allRoles = ['Admin', 'Lager'];

const ModalBrukere = ({
  visible,
  user,
  form,
  addNewUser,
  loading,
  deleteUserLoading,
  _deleteUser,
  _updateUser,
  ...props
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((errors, values) => {
      if (!errors) {
        const _val = {
          name: values.name,
          email: values.email,
          role: values.role === 'Admin' ? ADMIN_ROLE : LAGER_ROLE,
        };
        if (get(user, 'name', false) === false) {
          addNewUser(_val);
        } else {
          _updateUser(user._id, _val);
        }
      }
    });
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      destroyOnClose
      className="normal-modal"
      {...props}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label="Navn">
          {form.getFieldDecorator('name', {
            initialValue: get(user, 'name', ''),
          })(<Input type="text" />)}
        </Form.Item>
        <Form.Item label="Epost">
          {form.getFieldDecorator('email', {
            initialValue: get(user, 'email', ''),
          })(<Input type="text" />)}
        </Form.Item>
        <Form.Item label="Access">
          {form.getFieldDecorator('role', {
            initialValue: get(user, 'roles[0]', null),
          })(
            <Select>
              {allRoles.map((o) => (
                <Select.Option value={o}>{o}</Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          {get(user, 'name', '___nouser___') !== 'manager' &&
          get(user, 'name', false) !== false ? (
            <Popconfirm
              title="Are you sure you want to delete this user ?"
              onConfirm={() => _deleteUser(user._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  marginRight: '1rem',
                }}
                loading={deleteUserLoading}
              >
                Delete
              </Button>
            </Popconfirm>
          ) : null}
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const BrukereScreen = ({
  users,
  loading,
  fetch,
  form,
  _addUser,
  modalLoading,
  modalOpened,
  _toggleModal,
  deleteUserLoading,
  _deleteUser,
  _updateUser,
}) => {
  useEffect(() => {
    fetch();
  }, []);

  const [user, setUser] = useState(null);

  return (
    <>
      <ModalBrukere
        visible={modalOpened}
        onCancel={() => _toggleModal()}
        user={user}
        form={form}
        addNewUser={_addUser}
        loading={modalLoading}
        deleteUserLoading={deleteUserLoading}
        _deleteUser={_deleteUser}
        _updateUser={_updateUser}
      />
      <Spin spinning={loading}>
        <Table
          dataSource={users}
          columns={columns}
          className="brukere-table"
          footer={() => (
            <Button
              type="primary"
              shape="circle"
              style={{ boxShadow: '#00000069 2px 3px 4px' }}
              onClick={() => {
                setUser({});
                _toggleModal();
              }}
            >
              <Icon type="plus" />
            </Button>
          )}
          onRow={(record) => ({
            onClick: () => {
              _toggleModal();
              setUser(record);
            },
          })}
        />
      </Spin>
    </>
  );
};

BrukereScreen.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  _addUser: PropTypes.func.isRequired,
  _toggleModal: PropTypes.func.isRequired,
  modalLoading: PropTypes.bool.isRequired,
  modalOpened: PropTypes.bool.isRequired,
  deleteUserLoading: PropTypes.bool.isRequired,
  _deleteUser: PropTypes.func.isRequired,
  _updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Brukere }) => ({
  ...Brukere,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchUsers()),
  _addUser: (payload) => dispatch(addUser(payload)),
  _toggleModal: () => dispatch(toggleModal()),
  _deleteUser: (id) => dispatch(deleteUser(id)),
  _updateUser: (id, payload) => dispatch(updateUser(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'brukereform' })(BrukereScreen));
