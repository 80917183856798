import update from 'immutability-helper';
import { findKey } from 'lodash-es';

export default class Invoice {
  constructor() {
    this.invoices = [];
    this.isLoading = false;
    this.invoiceModalShow = false;
    this.invoiceInModal = null;
    this.total = 0;
    this.pagination = {
      limit: 50,
      offset: 0,
    };
  }

  setPagination(data) {
    return update(this, {
      pagination: {
        $set: data,
      },
    });
  }


  setCurrentInvoices(invoicesList, total) {
    return update(this, {
      invoices: {
        $set: invoicesList,
      },
      total: {
        $set: total,
      },
    });
  }

  toggleLoading() {
    return update(this, {
      isLoading: {
        $set: !this.isLoading,
      },
    });
  }

  showDetails(id) {
    return update(this, {
      invoiceModalShow: {
        $set: true,
      },
      invoiceInModal: {
        $set: id,
      },
    });
  }

  hideDetails() {
    return update(this, {
      invoiceModalShow: {
        $set: false,
      },
      invoiceInModal: {
        $set: null,
      },
    });
  }

  updateStatus({action}) {
    const keyOfInvoice = findKey(this.invoices, (i) => i.id === action.id);
    
    if(!action.value || keyOfInvoice === -1) {
      return this;
    }

    const keyOfCustomerInvoice = findKey(this.invoices[keyOfInvoice].values.customers.open_invoices || [], (c) => c._id === action.id);
    
    const u = {
      invoices: {
        [keyOfInvoice] : {
          values : { 
            status: { $set: action.value },
            customers: {
              open_invoices: {
                [keyOfCustomerInvoice] : {
                  status: { $set: action.value }
                }
              }
            }
          }
        }
      }
    }

    return update(this, u);
  }
}
