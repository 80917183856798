const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const truncate = (input, length) =>
  input.length > length ? `${input.substring(0, length)}...` : input;

const urlJoin = (parts) => {
  if (parts.length < 1) {
    return '';
  }

  const finalArrayParts = parts.map((val, index) => {
    if (index === 0) {
      if (val.charAt(val.length - 1) === '/') {
        return val.slice(0, val.length - 1);
      }
    }
    return val.charAt(0) === '/' ? val.slice(1, val.length - 1) : val;
  });

  return finalArrayParts.join('/');
};

const pathJoin = (parts, sep) => {
  const separator = sep || '/';
  const replace = new RegExp(`${separator}{1,}`, 'g');
  return parts.join(separator).replace(replace, separator);
};

const htmlDecode = (input) => {
  const e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

const removeDoubleSlash = (url) => url.replace(/([^:]\/)\/+/g, '$1');

const castToNumber = (val) => {
  if (typeof val === 'string') {
    return parseFloat(val);
  }

  return val;
};

const removeParenthezedValues = (text) => {
  if (!text) {
    return '';
  }
  return (
    text
      .replace(/\(\w+\)/gi, '')
      // also remove double line break
      .replace(/<br\s?\/>\s?<br\s?\/>/gi, '<br/>')
      .replace(undefined, '-')
  );
};

/* eslint-disable import/prefer-default-export */
const decodeHtmlStr = (str) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser();
  return parser.parseFromString(`<!doctype html><body>${str}`, 'text/html').body
    .textContent;
};

const countLinesAndChars = (text) => ({
  lines: text.split(/\r\n|\r|\n/).length,
  chars: text.length,
});

export default {
  capitalize,
  truncate,
  pathJoin,
  urlJoin,
  htmlDecode,
  removeDoubleSlash,
  castToNumber,
  removeParenthezedValues,
  decodeHtmlStr,
  countLinesAndChars,
};
