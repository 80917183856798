import React from 'react';
// import 'antd/dist/antd.css';
import * as Moment from 'moment-timezone';
import 'moment/locale/nb';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './helpers/history';
import GuardedRouter from './components/GuardedRouter';
import './custom-theme.css';
import './App.css';
import configureStore from './redux/configureStore';
import routeGuards from './routes/guards';
import mainRouteMaps from './routes/routes.index';
import renderRoutesFromMap from './helpers/Routes/renderRoutesFromMap';

import { setupClientAuth as setupHttpClientAuth } from './helpers/HttpClient';

// Bootstrap the application here and render routes
function App() {
  // set the default timezone used throughout the whole app
  Moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/Oslo');

  // set moment locale
  Moment.locale('nb');

  // add auth bearer from localstorage if exists
  // to http client used throughout the app
  setupHttpClientAuth();

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        <GuardedRouter.GuardedProvider guards={routeGuards}>
          {renderRoutesFromMap(mainRouteMaps)}
        </GuardedRouter.GuardedProvider>
      </Router>
    </Provider>
  );
}

export default App;
