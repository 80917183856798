/* eslint-disable camelcase */
export const APP_TOGGLE_CUSTOMER_FILTER_STATE =
  '@app/toggle.customer.filter.state';
export const APP_TOGGLE_INVOICE_FILTER_STATE =
  '@app/toggle.invoice.filter.state';
export const APP_TOGGLE_PRODUCTS_FILTER_STATE =
  '@app/toggle.products.filter.state';
export const APP_MODALS_CUSTOMER_TOGGLE = '@app/modals/customer/toggle';
export const APP_SAVE_FILTERS = '@app/filters/save';

export const APP_MODALS_INVENTORY_TOGGLE = '@stage/show';

export const toggle_customer_filter_state_action = (val) => ({
  type: APP_TOGGLE_CUSTOMER_FILTER_STATE,
  val,
});

export const toggle_products_filter_state_action = (val) => ({
  type: APP_TOGGLE_PRODUCTS_FILTER_STATE,
  val,
});

export const toggle_create_customer_modal_action = () => ({
  type: APP_MODALS_CUSTOMER_TOGGLE,
});

export const saveFilterAction = (domain, key, filter) => ({
  type: APP_SAVE_FILTERS,
  domain,
  key,
  filter,
});

export const toggle_invoice_filter_state_action = (val) => ({
  type: APP_TOGGLE_CUSTOMER_FILTER_STATE,
  val,
});

export const toggle_inventory_modal_action = () => ({
  type: APP_MODALS_INVENTORY_TOGGLE,
});