import update from 'immutability-helper';

export const BRUKERE_FETCH_START = '@Brukere/fetch_start';
export const BRUKERE_FETCH_END = '@Brukere/fetch_end';

export const BRUKERE_ADD_USER_START = '@Brukere/add_user_start;';
export const BRUKERE_ADD_USER_END = '@Brukere/add_user_send';

export const BRUKERE_TOGGLE_MODAL = '@Brukere/toggle_modal';

export const BRUKERE_DELETE_USER = '@Brukere/delete_user_start';
export const BRUKERE_DELETE_USER_END = '@Brukere/delete_user_end';

export const BRUKERE_UPDATE_USER_START = '@Brukere/update_user_start';
export const BRUKERE_UPDATE_USER_END = '@Brukere/update_user_end';

const defaultState = {
  users: [],
  loading: false,
  modalLoading: false,
  modalOpened: false,
  deleteUserLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BRUKERE_FETCH_START:
      return update(state, {
        loading: { $set: true },
      });

    case BRUKERE_FETCH_END:
      return update(state, {
        loading: { $set: false },
        users: { $set: action.payload },
      });

    case BRUKERE_ADD_USER_START:
      return update(state, {
        modalLoading: { $set: true },
      });

    case BRUKERE_ADD_USER_END:
      return update(state, {
        modalLoading: { $set: false },
      });

    case BRUKERE_TOGGLE_MODAL:
      return update(state, {
        modalOpened: { $set: !state.modalOpened },
      });

    case BRUKERE_DELETE_USER:
      return update(state, {
        deleteUserLoading: { $set: true },
      });
    case BRUKERE_DELETE_USER_END:
      return update(state, {
        deleteUserLoading: { $set: false },
      });

    case BRUKERE_UPDATE_USER_START:
      return update(state, {
        modalLoading: { $set: true },
      });

    case BRUKERE_UPDATE_USER_END:
      return update(state, {
        modalLoading: { $set: false },
      });

    default:
      return state;
  }
};

export const fetchUsers = () => ({
  type: BRUKERE_FETCH_START,
});

export const addUser = (payload) => ({
  type: BRUKERE_ADD_USER_START,
  payload,
});

export const toggleModal = () => ({
  type: BRUKERE_TOGGLE_MODAL,
});

export const deleteUser = (id) => ({
  type: BRUKERE_DELETE_USER,
  id,
});

export const updateUser = (id, payload) => ({
  type: BRUKERE_UPDATE_USER_START,
  id,
  payload,
});
