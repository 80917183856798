/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Modal from 'antd/es/modal';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Badge from 'antd/es/badge';

import StringHelper from '../../helpers/Strings';

const { TextArea } = Input;

/**
 * Modify text so that it abids with max chars per line
 * @param {string} text
 * @param {int} maxCharPerLine
 * @returns a new value, doesnt mutate parameters
 */
const applyMaxCharPerLine = (text, maxCharPerLine) => {
  const lines = text.split('\n');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lines.length; i++) {
    // eslint-disable-next-line no-continue
    if (lines[i].length <= maxCharPerLine) continue;
    let j = 0;
    let space = maxCharPerLine;
    while (j++ <= maxCharPerLine) {
      if (lines[i].charAt(j) === ' ') space = j;
    }
    lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || '');
    lines[i] = lines[i].substring(0, space);
  }
  return lines.slice(0, 10).join('\n');
};

/**
 * Check if message exceeds limits
 * @param {string} message
 * @param {object} limits
 */
const exceedsLimits = (message, limits = { lines: 7, chars: 245 }) => {
  const messageLength = StringHelper.countLinesAndChars(message || '');

  return (
    messageLength.lines > limits.lines || messageLength.chars > limits.chars
  );
};

/**
 * Renders a message row with gift image and message textArea
 * Will abids to limits and will set value from param 'setValue' func
 * @param {int} index
 * @param {string} value
 * @param {func} setValue
 * @param {object} limits
 * @returns
 */
const renderMessageRow = (index = 0, value, setValue, limits) => {
  const messageLengths = StringHelper.countLinesAndChars(value || '');

  return (
    <Row gutter={8} key={index}>
      <Col xs={8}>
        <p className="bold-text content-text">Innpakking - kr 59,00</p>
        <img
          alt="Giftwrap"
          style={{ display: 'block', width: '100%' }}
          src="https://staging-gledesgaver.temp927.kinsta.cloud/wp-content/uploads/2020/10/inpakking.png"
        />
      </Col>
      <Col xs={16}>
        <p className="content-text">
          Ønsker du merkelapp på gaven? Skriv din melding.
        </p>
        <TextArea
          autoSize={{ minRows: 7, maxRows: 7 }}
          value={value}
          onChange={(evt) => {
            // limit characters per line and set the value
            setValue(applyMaxCharPerLine(evt.currentTarget.value, 35), index);
          }}
        />
        <p
          style={{
            fontSize: '0.7rem',
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {messageLengths.chars} / {limits.chars} ({messageLengths.lines} /
          {limits.lines})
        </p>
      </Col>
    </Row>
  );
};

/**
 * Handle gift wrapping modal UI/UX
 * @param {props} params
 * @returns
 */
const GiftWrapping = ({
  visible,
  order,
  updateOrder,
  wrapFor,
  onSave,
  onCancel,
  ...props
}) => {
  const [messages, setMessages] = useState([]);
  const limits = { lines: 7, chars: 245 };
  const productName = (wrapFor || { navn: 'No product' }).navn;
  const productAmount = (wrapFor || { amount: 0 }).amount;

  const assignMessage = (mess, index) => {
    // only save state if message is within the limits
    if (!exceedsLimits(mess, limits)) {
      const newMessages = [...messages];
      newMessages[index] = mess;
      setMessages(newMessages);
    }
  };
  const handleClose = () => {
    setMessages([]);
    onCancel();
  };

  useEffect(() => {
    const defaultMessages = (wrapFor || { messages: [] }).messages;
    if (defaultMessages && defaultMessages.length > 0) {
      setMessages(defaultMessages);
    }
  }, [wrapFor]);

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      className="gw-modal"
      destroyOnClose
      onCancel={handleClose}
      {...props}
    >
      <Row>
        <Col xs={24} style={{ textAlign: 'center' }}>
          <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
            <Badge count={productAmount}>
              <Button icon="gift" />
            </Badge>
            {'    '}
            {productName}
          </h3>
        </Col>
      </Row>
      {
        // Render as many message rows as product amount
        Array.from(Array(productAmount)).map((v, i) =>
          renderMessageRow(i, messages[i], assignMessage, limits)
        )
      }
      <Row style={{ marginTop: '1rem' }}>
        <Col xs={12}>
          <Button block type="danger" onClick={handleClose}>
            Avbryt
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            block
            type="primary"
            onClick={() => {
              onSave(messages);
              handleClose();
            }}
          >
            Oppdater
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default GiftWrapping;
