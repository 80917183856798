import { find, get } from 'lodash-es';

import Api from '../../api/Methods';
import { UPDATE_ORDER_LINE_END, ORDER_LINE_DELETE_END } from './Ui';
import OrderModel from '../DataModels/Order';
import computeOrderIds from '../../helpers/computeOrderIds';
import patchValues from '../../helpers/patchValues';
// ///////////////
// Constants
// ///////////////
export const LOADING_ORDERS = 'Orders/loading_orders';
export const ORDERS_LOADED_SUCCESS = 'Orders/loaded_with_success';
export const ORDERS_NOT_LOADED_BUT_HAS_ERROR = 'Orders/not_loaded_has_error';
export const ORDERS_LOADED_COLUMNS = 'Orders/loaded_columns';

export const REQUEST_ORDER_FIELD_UPDATE =
  'Orders/request_an_order_field_update';
export const ORDER_FIELD_UPDATE_SUCCESS = 'Orders/field_update_success';
export const ORDER_FIELD_UPDATE_FAILED = 'Orders/field_update_failed';
export const ORDER_FILES_ADDED = 'Orders/file_added';
export const ORDER_FILE_REMOVED = 'Orders/file_removed';
export const ORDER_SET_IN_MODAL = 'Orders/set_in_modal';
export const ORDER_SET = 'Orders/set';

export const ORDER_WHOLE_SAVE_START = 'Orders/whole/save_start';
export const ORDER_WHOLE_SAVE_SUCCESS = 'Orders/whole/save_success';
export const ORDER_WHOLE_SAVE_FAILED = 'Orders/whole/save_failed';

export const ORDER_KPI_REQUEST_START = 'Orders/kpis/request-start';
export const ORDER_KPI_REQUEST_SUCCESS = 'Orders/kpis/request-success';
export const ORDER_KPI_REQUEST_FAILED = 'Orders/kpis/request-failed';

export const ORDER_FORM_ADD_CHANGES = 'Orders/form/add-changes';
export const ORDER_FORM_RESET_CHANGES = 'Orders/form/reset-changes';

export const ORDER_REALTIME_NEW_ORDER = 'Orders/realtime/new-order';
export const ORDER_REALTIME_ORDER_UPDATED = 'Orders/realtime/order-updated';

export const ATOMIC_UPDATE_START = '@Orders/atomic-update/start';
export const ATOMIC_UPDATE_END = '@Orders/atomic-update/end';

export const ORDER_STATS_START = '@Orders/stats/request-start';
export const ORDER_STATS_END = '@Orders/stats/request-end';

// ///////////////
// Reducers
// ///////////////
const orderDM = new OrderModel();
const Orders = (state = orderDM, action) => {
  switch (action.type) {
    // Order loading related
    case LOADING_ORDERS:
      return state.toggleLoading().setPagination(action.opts);
    case ORDERS_LOADED_SUCCESS:
      return state.setColumnsAndOrders(
        action.columns,
        action.orders,
        action.total
      );
    case ORDERS_LOADED_COLUMNS:
      return state.setColumns(action.columns);
    case ORDERS_NOT_LOADED_BUT_HAS_ERROR:
      return state.setError(action.error);

    // Order update related
    case REQUEST_ORDER_FIELD_UPDATE:
      return state.toggleFieldLoading(action.key);
    case ORDER_FIELD_UPDATE_SUCCESS:
      return state
        .toggleFieldLoading(action.key)
        .replaceOrder(action.id, action.value);
    case ORDER_FIELD_UPDATE_FAILED:
      return state.toggleFieldLoading(action.key).setError(action.error);
    case ORDER_FILES_ADDED:
      return state.addFile(action.id, action.action, action.value);
    case ORDER_FILE_REMOVED:
      return state.removeFile(action.id, action.fileId);
    case ORDER_SET_IN_MODAL:
      return state.setOrderShowingInModal(action.order);

    case ORDER_SET:
      return state.replaceOrder(
        action.id,
        patchValues(action.value, get(state, 'columns', {}))
      );

    case ORDER_WHOLE_SAVE_START:
      return state.detailsSaving(true);

    case ORDER_WHOLE_SAVE_SUCCESS:
    case ORDER_WHOLE_SAVE_FAILED:
      return state.detailsSaving(false);

    // KPIrelated
    case ORDER_KPI_REQUEST_START:
      return state.setKpiLoading(true);
    case ORDER_KPI_REQUEST_SUCCESS:
      return state.setKpi(action.payload).setKpiLoading(false);
    case ORDER_KPI_REQUEST_FAILED:
      return state.setKpiLoading(false);

    case ORDER_FORM_ADD_CHANGES:
      return state.addChanges(action.changes);
    case ORDER_FORM_RESET_CHANGES:
      return state.resetChanges();

    // related to realtime
    case ORDER_REALTIME_NEW_ORDER:
      return state.pushNewOrder(action.payload);
    case ORDER_REALTIME_ORDER_UPDATED:
      return state.replaceOrder(action.id, action.payload);

    case ATOMIC_UPDATE_START:
      return state.setAtomicUpdates({
        ...action,
        loading: true,
        // id: action.id, key: action.key, loading: true, value: action.value,
      });

    case ATOMIC_UPDATE_END:
      return state.setAtomicUpdates({
        ...action,
        loading: false,
        // id: action.id, key: action.key, loading: false, value: action.value,
      });

    case ORDER_STATS_START:
      return state.toggleStatsLoading();

    case ORDER_STATS_END:
      return state.setStats(action.payload).toggleStatsLoading();

    case UPDATE_ORDER_LINE_END:
    case ORDER_LINE_DELETE_END:
      if (action.newOrder === null) {
        return state;
      }

      return state.replaceOrder(action.newOrder._id, action.newOrder);

    default:
      return state;
  }
};

export default Orders;

// ///////////////
// Actions
// ///////////////
export const loadOrders = (opts = { limit: 50, offset: 0 }) => (dispatch) => {
  dispatch({ type: LOADING_ORDERS, opts });

  Api._getOrdersAndColumns((error, orders, columns) => {
    if (error) {
      return dispatch({ type: ORDERS_NOT_LOADED_BUT_HAS_ERROR, error });
    }

    const orderWithIds = computeOrderIds(orders.data.items || []);
    const columnsToUse = columns.length === 0 ? [] : columns;

    return dispatch({
      type: ORDERS_LOADED_SUCCESS,
      orders: orderWithIds.map((order) => patchValues(order, columnsToUse)),
      columns: columnsToUse,
      total: orders.data.total,
    });
  }, opts);
};

export const updateOrder = (id, key, value) => (dispatch, getState) => {
  dispatch({ type: REQUEST_ORDER_FIELD_UPDATE, key });

  // send the correct new order
  const currentOrders = getState().Orders;
  const newOrders = currentOrders.setOneOrder(id, key, value);
  const correctOrder = find(newOrders.orders, (order) => order.id === id);

  if (!correctOrder) {
    return dispatch({
      type: ORDER_FIELD_UPDATE_FAILED,
      key,
      error: new Error('Order not found in redux store'),
    });
  }

  // if accountManager is set, update the status to lead
  if (key === 'accountManager' && correctOrder.values.accountManager) {
    // if it never been in won status ever, make the change
    if (correctOrder.values.status !== 'Won') {
      correctOrder.values.status = 'Lead';
    }
  }

  return Api.updateOrder(id, correctOrder, (err, datas) => {
    if (err) {
      return dispatch({ type: ORDER_FIELD_UPDATE_FAILED, key, error: err });
    }

    return dispatch({
      type: ORDER_FIELD_UPDATE_SUCCESS,
      value: datas,
      id,
      key,
    });
  });
};

export const setOrderInModal = (order) => ({
  type: ORDER_SET_IN_MODAL,
  order,
});

export const appendFile = (orderId, fileObj) => (dispatch, getState) => {
  dispatch({
    type: ORDER_FILES_ADDED,
    action: 'add',
    id: orderId,
    value: fileObj,
  });

  const currentOrders = getState().Orders;
  const orderThatShouldBeInModal = find(
    currentOrders.orders,
    (order) => order._id === currentOrders.orderShowingInModal._id
  );

  dispatch(setOrderInModal(orderThatShouldBeInModal));
};

export const removeFile = (orderId, fileId) => (dispatch) => {
  dispatch({
    type: ORDER_FILE_REMOVED,
    id: orderId,
    fileId,
  });
};

export const saveOrder = (orderId, payload, pagination, wcUpdate = false, recalculate = false) => ({
  type: ORDER_WHOLE_SAVE_START,
  payload,
  id: orderId,
  pagination,
  wcUpdate,
  recalculate
});

export const loadKpis = () => ({
  type: ORDER_KPI_REQUEST_START,
});

export const resetChanges = () => ({
  type: ORDER_FORM_RESET_CHANGES,
});

export const addChanges = (changes) => ({
  type: ORDER_FORM_ADD_CHANGES,
  changes,
});

export const realtimeAddOrder = (order) => ({
  type: ORDER_REALTIME_NEW_ORDER,
  payload: order,
});

export const realtimeUpdateOrder = (id, order) => ({
  type: ORDER_REALTIME_ORDER_UPDATED,
  payload: order,
  id,
});

export const atomicUpdate = (args) => ({
  type: ATOMIC_UPDATE_START,
  ...args,
});

export const fetchOrderStats = (dateRef, dateEnd, monthStart) => ({
  type: ORDER_STATS_START,
  dateRef,
  dateEnd,
  monthStart,
});
