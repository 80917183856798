import CommentsModel from '../DataModels/Comment';
import { notifyError } from '../../helpers/Notify';
import Api from '../../api/Methods';

// ///////////////
// Constants
// ///////////////
export const COMMENTS_LOADING = 'Comments/loading';
export const COMMENTS_LOADED = 'Comments/loaded';
export const COMMENTS_LOADED_ERROR = 'Comments/loading-errored';

export const SENDING_COMMENT = 'Comments/sending-a-comment';
export const SENDING_COMMENT_ERRORED = 'Comments/sending-a-comment-has-errored';
export const SENT_COMMENT = 'Comments/comment-has-been-sent';

export const CLEAR_COMMENTS = 'Comments/comments-cleared';

// ///////////////
// Reducer
// ///////////////
const commentDM = new CommentsModel();
export default (state = commentDM, action) => {
  switch (action.type) {
    case COMMENTS_LOADING:
      return state.toggleLoading();
    case COMMENTS_LOADED:
      return state.setComments(action.comments).toggleLoading();
    case COMMENTS_LOADED_ERROR:
      notifyError('Error', 'There was an error loading comments');
      return state.toggleLoading();

    case SENDING_COMMENT:
    case SENT_COMMENT:
      return state.toggleSendingLoading();
    case SENDING_COMMENT_ERRORED:
      notifyError('Error', 'There was an error sending your comment');
      return state.toggleSendingLoading();

    case CLEAR_COMMENTS:
      return state.clearComments();

    default:
      return state;
  }
};

// ///////////////
// Actions
// ///////////////
export const loadCommentsForOrder = (orderId) => (dispatch) => {
  dispatch({ type: COMMENTS_LOADING });

  Api._getCommentsForOrder(orderId)
    .then(({ data }) =>
      dispatch({
        type: COMMENTS_LOADED,
        comments: data,
      })
    )
    .catch(() => {
      dispatch({ type: COMMENTS_LOADED_ERROR });
    });
};

export const sendComment = (comment, userId, orderId) => (dispatch) => {
  dispatch({ type: SENDING_COMMENT });

  Api._sendComment({
    comment,
    user: userId,
    order: orderId,
  })
    .then(() => {
      dispatch({ type: SENT_COMMENT });
      dispatch(loadCommentsForOrder(orderId));
    })
    .catch(() => {
      dispatch({ type: SENDING_COMMENT_ERRORED });
    });
};

export const clearAll = () => ({
  type: CLEAR_COMMENTS,
});
