/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash-es';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Kpis from '.';
import RangePicker2 from '../RangePicker2';
import NumbersHelper from '../../helpers/General/Numbers';

import {
  actionFetch,
  selectKpiDatas,
  setRangeAction,
} from '../../redux/ActionsAndReducers/Statistics';

import './styles.css';
import '../Card/style.css';

const { guardDivisor } = NumbersHelper;

const cardStyles = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 8,
};

const containerStyles = {
  lg: 24,
  xl: 12,
};

const bigCardStyle = {
  xs: 24,
  sm: 24,
  md: 16,
  lg: 16,
  xl: 16,
};

const headerStyle = {
  fontWeight: 'bolder',
  fontSize: '.9rem',
  color: 'rgba(0,0,0,.60)',
  marginBottom: 0,
};

const AllKpis = ({
  blank,
  fetchStats,
  loading,
  statistics,
  rangeDate,
  setRange,
  size,
  desktopView,
}) => {
  useEffect(() => {
    if (statistics.daily_totalt_no_mva === 0 && !blank) {
      fetchStats(process.env.REACT_APP_TIMEZONE);
    }
  }, []);
  const kpiSize = size || 'default';

  return (
    <div
      className={['kpi-outter-head', 'kpi-small']
        .filter(
          (cl) =>
            cl === 'kpi-outter-head' ||
            (cl === 'kpi-small' && kpiSize === 'small')
        )
        .join(' ')}
    >
      <Row gutter={[8, 8]}>
        {/* <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            loading={blank ? false : !customers.statsLoaded}
            title="Antall kunder"
            value={blank ? 0 : customers.stats.count}
            valueStyle={{ color: '#3f8600' }}
          />
        </Col>
        <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            loading={blank ? false : !customers.statsLoaded}
            title="Gj. antall ordre pr kunde"
            value={blank ? 0 : (
              customers.stats.count > 0 && customers.statsLoaded
                ? (Math.round(100 * customers.stats.avgOrdersNum) / 100)
                : 0)}
            valueStyle={{ color: '#3f8600' }}
          />
        </Col>
        <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            loading={blank ? false : !customers.statsLoaded}
            title="Gj. ordrevolum pr kunde"
            value={
                  blank ? 0 : (
                    customers.stats.count > 0 && customers.statsLoaded
                      ? (Math.round(100 * customers.stats.avgAmount) / 100)
                      : 0)
                }
            valueStyle={{ color: '#3f8600' }}
          />
        </Col>
        <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            loading={blank ? false : !customers.statsLoaded}
            title="Returning customers"
            value={blank ? 0 : Math.round(customers.stats.returnRate)}
            valueStyle={{ color: '#3f8600' }}
            formatter={(value) => `${value} %`}
          />
        </Col> */}

        {/* <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            title="Purchase Value"
            loading={blank ? false : lager.loading}
            value={blank ? 0 : lager.stats.purchases}
            suffix="NOK"
            valueStyle={{ color: '#3f8600' }}
          />
        </Col>
        <Col className="gutter-row" {...cardStyles}>
          <Card.Indicator
            loading={blank ? false : lager.loading}
            title="Sales Value"
            value={blank ? 0 : lager.stats.sales}
            suffix="NOK"
            valueStyle={{ color: '#3f8600' }}
          />
        </Col> */}
        <Col {...containerStyles}>
          <Col xs={24}>
            <h4 style={{ ...headerStyle, lineHeight: '32px', height: '32px' }}>
              <Kpis.RangeLabel range={rangeDate} />
              <RangePicker2
                style={{ float: 'left', marginRight: '10px' }}
                defaultValue={rangeDate}
                onChange={setRange}
              />
            </h4>
          </Col>
          <Kpis.Compare
            loading={blank ? false : loading}
            {...cardStyles}
            title={blank ? '' : 'Antall ordre'}
            current={
              blank ? 0 : get(statistics, 'daily_totalt_no_mva.count', 0)
            }
            compare={
              blank
                ? 0
                : get(statistics, 'yesterday_daily_totalt_no_mva.count', 0)
            }
          />
          <Kpis.Compare
            loading={blank ? false : loading}
            {...cardStyles}
            title={blank ? '' : 'Ordrevolum'}
            current={
              blank ? 0 : get(statistics, 'daily_totalt_no_mva.total', 0)
            }
            compare={
              blank
                ? 0
                : get(statistics, 'yesterday_daily_totalt_no_mva.total', 0)
            }
          />
          <Kpis.Compare
            {...cardStyles}
            loading={blank ? false : loading}
            title={blank ? '' : 'Gj. snitt ordrevolum'}
            current={
              blank
                ? 0
                : (get(statistics, 'daily_totalt_no_mva.total', 0) || 0) /
                  guardDivisor(get(statistics, 'daily_totalt_no_mva.count', 1))
            }
            compare={
              blank
                ? 0
                : (get(statistics, 'yesterday_daily_totalt_no_mva.total', 0) ||
                    0) /
                  guardDivisor(
                    get(statistics, 'yesterday_daily_totalt_no_mva.count', 1)
                  )
            }
          />
        </Col>

        <Col {...containerStyles}>
          {desktopView && (
            <Col xs={24}>
              <h4 style={{ ...headerStyle, height: '32px' }}>-</h4>
            </Col>
          )}
          <Kpis.Pie
            {...bigCardStyle}
            loading={loading}
            datas={statistics.daily_totalt_per_category || {}}
            otherDatas={{ frakt: statistics.range_frakt || {} }}
            compareDatas={statistics.yesterday_daily_totalt_per_category || {}}
            compareOtherDatas={{
              frakt: statistics.yesterday_range_frakt || {},
            }}
            size={kpiSize}
          />
          {desktopView && <Kpis.Blank {...cardStyles} />}
        </Col>
      </Row>
    </div>
  );
};

AllKpis.defaultProps = {
  desktopView: true,
};

export default connect(
  ({ Statistics }) => ({
    rangeDate: Statistics.range,
    statistics: selectKpiDatas(Statistics),
    loading: Statistics.loading,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchStats: actionFetch,
        setRange: setRangeAction,
      },
      dispatch
    )
)(AllKpis);
