/* eslint-disable import/prefer-default-export */
import notification from 'antd/es/notification';

export const notifyInfo = (title, message) => {
  notification.open({
    message: title,
    description: message,
    placement: 'bottomRight',
  });
};

export const notifyError = (title, message) => {
  notification.error({
    message: title,
    description: message,
  });
};

export const notifySuccess = (title, message) => {
  notification.success({
    message: title,
    description: message,
  });
};
