/* eslint-disable no-restricted-properties */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Row from 'antd/es/row';
import { merge, omit, get, has } from 'lodash-es';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import Form from 'antd/es/form';
import Popconfirm from 'antd/es/popconfirm';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Typography from 'antd/es/typography';

import Activities from '../Activities/Activities';
import { renderMediaFileLink } from '../../helpers/OrderSections/configs';
import {
  saveOrder,
  addChanges,
  resetChanges,
} from '../../redux/ActionsAndReducers/Orders';
import {
  requestPrint,
  showEditOrderModalAction,
  editOrderLineAction,
  deleteOrderLineAction,
} from '../../redux/ActionsAndReducers/Ui';
import Api from '../../api/Methods';
import EditOrderModal from './EditOrderModal';
import StringHelper from '../../helpers/Strings';
import RelatedOrders from './RelatedOrders';
import { showSiderAction } from '../../redux/sider/actions';
import OrderHelper from '../../helpers/Orders';

import { computeConfigToFields, buildTableValues } from '../../helpers/Fields';

const normalTextStyle = {
  color: '#000',
  fontSize: '.8rem',
  fontWeight: 'normal',
};

const customerConfigs = [
  {
    type: 'richtext',
    valueKey: ['values.leveringsAdresse'],
    label: 'Leveringsadresse',
    lg: 24,
    style: { ...normalTextStyle },
  },
];

const orderInformationsConfigs = [
  {
    type: 'text',
    valueKey: ['values.vekt'],
    label: 'Vekt',
    xs: 24,
    style: { marginTop: 5 },
  },
  {
    type: 'text',
    valueKey: ['values.resultat', '[calculated]'],
    label: 'Resultat',
    editable: false,
    xs: 24,
    style: { marginTop: 5 },
  },
  {
    type: 'sporingsnummer',
    valueKey: ['values.sporingsnummer'],
    label: 'Sporingsnummer',
    editable: true,
    xs: 24,
    style: { marginTop: 5 },
  },
];

const kommentarConfig = [
  {
    type: 'richtext',
    valueKey: ['values.kommentar'],
    label: 'Kommentar',
    lg: 24,
    style: { ...normalTextStyle, textAlign: 'center' },
  },
];

const upperLineTitleStyle = { marginBottom: 0 };
const lowerLineTitleStyle = {
  marginBottom: 0,
  fontWeight: 'normal',
  fontSize: '.6rem',
};

export const tableColumns = (
  _updateOrderLine,
  order,
  _deleteOrderLine = () => {},
  handleFraktUpdate,
  isEditable = true
) => {
  const columns = [
    {
      title: 'Artikkel',
      dataIndex: 'artikkel',
      key: 'artikkel',
      fixed: 'left',
      width: 75,
    },
    {
      title: 'Tittel',
      dataIndex: 'tittel',
      key: 'tittel',
      width: 120,
      render: (v, r) => {
        const noteValues = r.notes;
        const hasNotes =
          has(noteValues, 'notes') && (noteValues.notes || []).length > 0;
        if (!hasNotes) return v;

        // Render notes frame
        const messages = (noteValues.notes || [])
          // Decode html so &amp; etc are rendered correctly
          .map((msg) => ({
            ...msg,
            // to: StringHelper.decodeHtmlStr(msg.to),
            message: StringHelper.decodeHtmlStr(msg.message),
            // from: StringHelper.decodeHtmlStr(msg.from),
          }))
          .map((msg) => (
            <div
              style={{
                backgroundColor: '#eeeeee78',
                borderRadius: 5,
                padding: 10,
                marginTop: 2,
              }}
            >
              {/* <small>
              <strong>Til: </strong>
            </small>
            <small>{msg.to}</small>
            <br /> */}
              <small>
                <strong>Melding: </strong>
              </small>
              <small>{msg.message}</small>
              {/* <br />
            <small>
              <strong>Fra: </strong>
            </small>
            <small>{msg.from}</small> */}
            </div>
          ));

        return (
          <Typography.Paragraph>
            {v}
            <br />
            <small>
              <strong>For: </strong>
            </small>
            <small>{noteValues.for}</small>
            <br />
            {messages}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Bestilt',
      dataIndex: 'antall',
      key: 'antall',
      width: 70,
      render: (v, r) =>
        r.artikkel ? (
          <Typography.Paragraph
            style={{ marginBottom: '0' }}
            editable={
              isEditable
                ? {
                    onChange: (newVal) => {
                      _updateOrderLine({
                        payload: {
                          id: order._id || order.id,
                          datas: {
                            ordered: {
                              [r.artikkel]: newVal || 0,
                            },
                            delivered: {
                              [r.artikkel]: r.delivered || 0,
                            },
                          },
                        },
                      });
                    },
                  }
                : null
            }
          >
            {v}
          </Typography.Paragraph>
        ) : null,
    },
    {
      title: 'Levert',
      dataIndex: 'delivered',
      key: 'delivered',
      width: 70,
      render: (v, r) =>
        r.artikkel ? (
          <Typography.Paragraph
            style={{ marginBottom: '0' }}
            editable={
              isEditable
                ? {
                    onChange: (c) => {
                      _updateOrderLine({
                        payload: {
                          id: order._id || order.id,
                          datas: {
                            delivered: {
                              [r.artikkel]: c || 0,
                            },
                            ordered: {
                              [r.artikkel]: r.antall || 0,
                            },
                          },
                        },
                      });
                    },
                  }
                : null
            }
          >
            {v}
          </Typography.Paragraph>
        ) : null,
    },
    {
      title: (
        <>
          <p style={upperLineTitleStyle}>Enhetspris</p>
          <p style={lowerLineTitleStyle}>(eks mva)</p>
        </>
      ),
      dataIndex: 'enhetspris_eks_mva',
      key: 'enhetspris_eks_mva',
      width: 100,
    },
    {
      title: (
        <>
          <p style={upperLineTitleStyle}>Belop</p>
          <p style={lowerLineTitleStyle}>(eks mva)</p>
        </>
      ),
      dataIndex: 'belop_eks_mva',
      key: 'belop_eks_mva',
      width: 80,
      render: (v, r) => {
        if(!r.editFrakt) {
          return v;
        }
         return r.editFrakt ? (
          <Typography.Paragraph
            style={{ marginBottom: '0' }}
            editable={
              isEditable
                ? {
                    onChange: (c) => handleFraktUpdate(c)
                  }
                : null
            }
          >
            {v}
          </Typography.Paragraph>
        ) : null;
      }
    },
    {
      title: (
        <>
          <p style={upperLineTitleStyle}>Mva</p>
          <p style={lowerLineTitleStyle}>(%)</p>
        </>
      ),
      dataIndex: 'mva',
      key: 'mva',
      width: 80,
    },
    {
      title: (
        <>
          <p style={upperLineTitleStyle}>Beløp</p>
          <p style={lowerLineTitleStyle}>(inkl mva)</p>
        </>
      ),
      dataIndex: 'belop_inkl_mva',
      key: 'belop_inkl_mva',
      width: 80,
    },
  ];

  if (isEditable) {
    columns.push({
      title: 'Action',
      dataIndex: 'mva',
      key: 'action',
      width: 80,
      render: (v, record) =>
        record.artikkel ? (
          <Popconfirm
            placement="top"
            title="Are you sure you want to delete this line ?"
            onConfirm={() =>
              _deleteOrderLine({
                id: order.id || order._id,
                sku: record.artikkel,
              })
            }
          >
            <Button type="danger" ghost size="small">
              Delete
            </Button>
          </Popconfirm>
        ) : null,
    });
  }

  return columns;
};

const PdfView = ({
  order,
  form,
  _saveOrder,
  isLoading,
  currentPagination,
  _resetChanges,
  isPrinting,
  _print,
  changes,
  showEditOrderModal,
  updateOrderLine,
  deleteOrderLine,
  showSider,
  orderLineIsLoading,
}) => {
  const hasLabelAndPacking =
    get(order, 'values.files', []).filter((l) =>
      l.match(/(bring)|(packinglist)/gi)
    ).length >= 2;
  const isKaffekruset =
    get(order, 'values.leveringstype', '') === 'Kaffekruset';
  const formLayout = {};
  const [windowSize, setWSize] = useState(window.innerWidth);
  const [orderState, setOrder] = useState(order);
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();

    form.validateFields((error, values) => {
      if (!error) {
        const statusHasBeenChanged = get(changes, 'values.status', false);
        // do not push kunde as it is not updatable for now
        const valuesToPush = merge(
          { values: order.values },
          omit(values, ['values.kunde'])
        );
        // only update woocommerce if status has been changed
        _saveOrder(
          get(order, '_id', get(order, 'id', 0)),
          valuesToPush,
          currentPagination,
          !!statusHasBeenChanged
        );
      }
    });
  };

  const handleFraktUpdate = (v) => {
    if(v === undefined || v === '' || v === null) return;
    const valuesToPush = {...omit(order.values), frakt: v, updateFrakt: true};
    const id = get(order, '_id', get(order, 'id', 0));
    setLoading(true);
    _saveOrder(
      id,
      {values: valuesToPush},
      currentPagination,
      false,
      true
    );
    setTimeout(() => {
      setLoading(false);
      setOrder(id)
    }, 1000)
  }

  useEffect(() => {
    // Must reset the form values when switching btw order
    // Fix GU-220
    // https://wausolutions.atlassian.net/secure/RapidBoard.jspa?rapidView=9&projectKey=GU&modal=detail&selectedIssue=GU-220
    form.resetFields();
    setOrder(order);
    _resetChanges();
  }, [order]);

  useEffect(() => {
    setWSize(window.innerWidth);
  });

  if (typeof orderState === 'string' && !loading) {
    setLoading(true);
    Api.findOneOrder(orderState)
      .then((result) => {
        // setOrder(result.data);
        showSider('order', result.data);
        setLoading(false);
        // console.log('RESULT ORDERS', result);
      })
      .catch((e) => console.log(e));
  }

  const orderLinesTableValues =
    !orderState || !orderState.values
      ? []
      : buildTableValues(orderState.values);

  return orderState && typeof orderState !== 'string' ? (
    <Form {...formLayout} onSubmit={(e) => handleFormSubmit(e)}>
      <EditOrderModal orderId={orderState.id || orderState._id} />
      <Row>
        <Col xs={24} className=" order-container">
          <Row gutter={16} style={{ marginRight: -'5px' }}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 19 }}
            >
              <Row type="flex" align="bottom">
                <Col xs={{ span: 24 }} md={{ span: 9 }}>
                  {computeConfigToFields(customerConfigs, {}, orderState, form)}
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 15 }}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Row
                    type="flex"
                    justify="right"
                    style={{ maxWidth: '400px', minWidth: '300px' }}
                  >
                    <Col xs={12}>
                      {orderInformationsConfigs.map((o, index) => (
                        <p
                          style={{
                            fontWeight: 'bold',
                            height: '27px',
                            margin: '0px',
                            lineHeight: '33px',
                          }}
                          key={`label-${index}`}
                        >
                          {o.label} :
                        </p>
                      ))}
                    </Col>
                    <Col xs={{ span: 12 }}>
                      {computeConfigToFields(
                        orderInformationsConfigs,
                        {},
                        orderState,
                        form
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  {computeConfigToFields(kommentarConfig, {}, orderState, form)}
                </Col>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <Col xs={24}>
                  <Button
                    type="primary"
                    size="small"
                    icon="plus"
                    style={{ marginBottom: '.5rem' }}
                    onClick={() => showEditOrderModal()}
                  >
                    Add new line
                  </Button>
                  <Table
                    className="table-field"
                    scroll={{ x: windowSize > 420 ? 700 : 500 }}
                    dataSource={orderLinesTableValues}
                    columns={tableColumns(
                      updateOrderLine,
                      orderState,
                      deleteOrderLine,
                      handleFraktUpdate
                    )}
                    pagination={false}
                    size="small"
                    bordered={false}
                    loading={orderLineIsLoading}
                  />
                </Col>
                <Col xs={24} md={19} style={{ marginTop: '1rem' }}>
                  {OrderHelper.mediaLinks(order).map((link, index) =>
                    renderMediaFileLink(
                      link,
                      `media_files-${index}`,
                      'default',
                      orderState
                    )
                  )}
                  {isKaffekruset
                    ? renderMediaFileLink(
                        StringHelper.urlJoin([
                          process.env.REACT_APP_BACKEND_URL,
                          `orders/kaffelabel/${get(
                            order,
                            '_id',
                            '0'
                          )}?mode=pdf`,
                        ]),
                        'kaffelabel'
                      )
                    : null}
                  {hasLabelAndPacking ? (
                    <Popconfirm
                      title="Are you sure you want to re-print the documents ?"
                      icon={
                        <Icon
                          type="question-circle-o"
                          style={{ color: 'red' }}
                        />
                      }
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        _print({
                          id: get(
                            orderState,
                            '_id',
                            get(orderState, 'id', '0')
                          ),
                        })
                      }
                    >
                      <Button
                        loading={isPrinting}
                        type="danger"
                        shape="circle"
                        size="large"
                        style={{ margin: '.2rem .5rem' }}
                      >
                        {isPrinting ? null : <Icon type="printer" />}
                      </Button>
                    </Popconfirm>
                  ) : null}
                </Col>
                <Col
                  xs={24}
                  md={5}
                  style={{ marginTop: '1rem', textAlign: 'right' }}
                >
                  <Button
                    disabled={!form.isFieldsTouched()}
                    type="primary"
                    style={{ margin: '.2rem .5rem' }}
                    loading={isLoading}
                    htmlType="submit"
                  >
                    Save changes
                  </Button>
                </Col>
              </Row>
              <RelatedOrders order={orderState} />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 5 }}
            >
              <Activities order={orderState} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <Spin />
    </div>
  );
};

const mapStateToProps = ({ Orders, Ui }) => ({
  contentToShow: Orders.orderShowingInModal,
  columns: Orders.columns,
  isLoading: Orders.detailsIsSaving,
  currentPagination: Orders.pagination,
  changes: Orders.changes || [],
  isPrinting: Ui.printing,
  orderLineIsLoading: Ui.editOrderLine.loading,
});

const mapDispatchToProps = (dispatch) => ({
  _saveOrder: (id, datas, pagination, wcUpdate, recalculate) =>
    dispatch(saveOrder(id, datas, pagination, wcUpdate, recalculate)),
  _addChanges: (changes) => dispatch(addChanges(changes)),
  _resetChanges: () => dispatch(resetChanges()),
  _print: (payload) => dispatch(requestPrint(payload)),
  ...bindActionCreators(
    {
      showEditOrderModal: showEditOrderModalAction,
      updateOrderLine: editOrderLineAction,
      deleteOrderLine: deleteOrderLineAction,
      showSider: showSiderAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'order_details',
    onFieldsChange: (props, changeValues) => {
      props._addChanges(changeValues);
    },
  })(PdfView)
);
