import CustomerModel from '../DataModels/Customer';
import { notifyError } from '../../helpers/Notify';
import Api from '../../api/Methods';
import { ORDERS_LOADED_COLUMNS } from './Orders';

// ///////////////
// Constants
// ///////////////
export const CUSTOMERS_LOADING = 'Customers/loading';
export const CUSTOMERS_LOADED = 'Customers/loaded';
export const CUSTOMERS_LOADING_ERROR = 'Customers/loadingError';
export const CUSTOMERS_SHOW_DETAILS = 'Customers/showDetails';
export const CUSTOMERS_HIDE_DETAILS = 'Customers/hideDetails';

export const CUSTOMERS_STATS_START = 'Customers/stats/start';
export const CUSTOMERS_STATS_DONE = 'Customers/stats/done';

export const CUSTOMERS_LOAD_ONE = 'Customers/load_one';

// ///////////////
// Reducers
// ///////////////
const customerDM = new CustomerModel();
export default (state = customerDM, action) => {
  switch (action.type) {
    case CUSTOMERS_LOADING:
      return state.toggleLoading();
    case CUSTOMERS_LOADED:
      return state
        .setCurrentCustomers(action.customers, action.total)
        .setCurrentColumns(action.columns)
        .setPagination(action.pagination)
        .toggleLoading();
    case CUSTOMERS_LOADING_ERROR:
      notifyError('Error', 'There was an error loading customers list');
      return state.toggleLoading();

    case CUSTOMERS_SHOW_DETAILS:
      return state.showDetails(action.id);

    case CUSTOMERS_HIDE_DETAILS:
      return state.hideDetails();

    case CUSTOMERS_STATS_DONE:
      return state.setStatsValues(action.data);
    default:
      return state;
  }
};

// ///////////////
// Actions
// ///////////////
export const loadCustomersStats = () => (dispatch) => {
  Api.getCustomersStats()
    .then((r) => r.data)
    .then((data) =>
      dispatch({
        type: CUSTOMERS_STATS_DONE,
        data,
      })
    );
};

export const loadCustomers = (opts = { limit: 50, offset: 0 }) => (
  dispatch,
  getState
) => {
  dispatch({ type: CUSTOMERS_LOADING });

  Api._getCustomersAndColumns((err, customers, columns) => {
    if (err) {
      return dispatch({ type: CUSTOMERS_LOADING_ERROR });
    }

    // check if stats has been already loaded or not
    // if not, just dispatch its fetch
    // if (!getState().Customers?.stats?.loaded) {
    //   dispatch(
    //     loadCustomersStats(),
    //   );
    // }

    // work the customer id and also put the datas in values key
    // so it ressemble to the orders scheme
    const customersToSave = customers.data.items.map((customer) => ({
      values: {
        ...customer,
        type: 'customer',
        customerId: `${customer.identifier} - ${customer.name}`,
      },
      files: [],
      id: customer._id,
    }));

    // if orders columns are empty, add them also
    if (getState().Orders.columns.length < 1) {
      dispatch({
        type: ORDERS_LOADED_COLUMNS,
        columns: columns.length === 0 ? [] : columns,
      });
    }

    return dispatch({
      type: CUSTOMERS_LOADED,
      customers: customersToSave,
      columns: columns.length === 0 ? [] : columns,
      total: customers.data.total,
      pagination: opts,
    });
  }, opts);
};

export const showDetails = (id) => (dispatch) => {
  dispatch({ type: CUSTOMERS_SHOW_DETAILS, id });
};

export const hideDetails = () => (dispatch) => {
  dispatch({ type: CUSTOMERS_HIDE_DETAILS });
};

export const loadOneCustomer = (id) => ({ type: CUSTOMERS_LOAD_ONE, id });
