import { get } from 'lodash-es';

const getAsArray = (ob, path) => {
  const val = get(ob, path, []);

  return Array.isArray(val) ? val : [];
};

export default {
  getAsArray,
};
