import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { set } from 'lodash-es';
import Moment from 'moment-timezone';

import StatisticsHelper from '../../helpers/Statistics';
import DatesHelper from '../../helpers/dates';

export const STATISTICS_FETCH_START = '@Statistics/fetch/start';
export const STATISTICS_FETCH_END = '@Statistics/fetch/end';
export const STATISTICS_SET_RANGE = '@Statistics/setrange';

const defaultState = {
  loading: false,
  datas: [],
  range: [Moment().startOf('month'), Moment()],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STATISTICS_FETCH_START:
      return update(state, {
        loading: { $set: true },
        // range: { $set: action.range },
      });

    case STATISTICS_FETCH_END:
      return update(state, {
        loading: { $set: false },
        datas: { $set: action.datas || [] },
      });

    case STATISTICS_SET_RANGE:
      return update(state, {
        range: { $set: action.range },
      });

    default:
      return state;
  }
};

export const setRangeAction = (range) => ({
  type: STATISTICS_SET_RANGE,
  range,
});

export const actionFetch = (
  tz = process.env.REACT_APP_TIMEZONE,
  range = [Moment().startOf('day'), Moment().endOf('day')]
) => ({
  type: STATISTICS_FETCH_START,
  tz,
  range,
});

export const selectKpiDatas = createSelector(
  [(state) => state],
  ({ datas, range }) => {
    const d = {};

    const baseDatas =
      datas.length > 0
        ? StatisticsHelper.getStatsForDateRange(
            datas,
            'totalt_no_mva',
            range[0],
            range[1]
          )
        : 0;
    // const rangeIsToday = Moment(range[0]).isSame(Moment(), 'day') && Moment(range[1]).isSame(Moment(), 'day');
    const sameRangeLastYear = StatisticsHelper.getSameRangeLastYear(range);
    // compare to yesterday if range is today, compare to same period last year if not
    const compareDatas = DatesHelper.isRangeTodayOnly(range)
      ? StatisticsHelper.getStatsForDateRange(
          datas,
          'totalt_no_mva',
          Moment(range[0]).subtract(1, 'day'),
          Moment(range[1]).subtract(1, 'day')
        )
      : StatisticsHelper.getStatsForDateRange(
          datas,
          'totalt_no_mva',
          Moment(sameRangeLastYear[0]),
          Moment(sameRangeLastYear[1])
        );
    const compareDatasPerCateg = DatesHelper.isRangeTodayOnly(range)
      ? StatisticsHelper.getStatsForDateRange(
          datas,
          'totalt_no_mva',
          Moment(range[0]).subtract(1, 'day'),
          Moment(range[1]).subtract(1, 'day'),
          StatisticsHelper.STATS_TYPE_CATEGORY
        )
      : StatisticsHelper.getStatsForDateRange(
          datas,
          'totalt_no_mva',
          Moment(sameRangeLastYear[0]),
          Moment(sameRangeLastYear[1]),
          StatisticsHelper.STATS_TYPE_CATEGORY
        );
    const fraktCompareDatas = DatesHelper.isRangeTodayOnly(range)
      ? StatisticsHelper.getStatsForDateRange(
          datas,
          'frakt',
          Moment(range[0]).subtract(1, 'day'),
          Moment(range[1]).subtract(1, 'day'),
          StatisticsHelper.STATS_TYPE_TOTAL
        )
      : StatisticsHelper.getStatsForDateRange(
          datas,
          'frakt',
          Moment(sameRangeLastYear[0]),
          Moment(sameRangeLastYear[1]),
          StatisticsHelper.STATS_TYPE_TOTAL
        );

    set(d, 'daily_totalt_no_mva', baseDatas);
    set(d, 'yesterday_daily_totalt_no_mva', compareDatas);
    set(
      d,
      'daily_totalt_per_category',
      StatisticsHelper.getStatsForDateRange(
        datas,
        'totalt_no_mva',
        range[0],
        range[1],
        StatisticsHelper.STATS_TYPE_CATEGORY
      )
    );
    set(d, 'yesterday_daily_totalt_per_category', compareDatasPerCateg);
    set(d, 'yesterday_range_frakt', fraktCompareDatas);
    set(
      d,
      'range_frakt',
      StatisticsHelper.getStatsForDateRange(
        datas,
        'frakt',
        range[0],
        range[1],
        StatisticsHelper.STATS_TYPE_TOTAL
      )
    );
    return d;
  }
);
