/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { get, sumBy } from 'lodash-es';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Tag from 'antd/es/tag';
import Button from 'antd/es/button';
import moment from 'moment-timezone';
import TimeAgo from 'react-timeago';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';

import DateUtils from '../../helpers/dates';
import { DATE_FORMAT } from '../../constants/defaultValues';
import getters from '../../helpers/General/getters';
import OrderHelper from '../../helpers/Orders';
import { ReactComponent as MoneyIcon } from './money.svg';
import { ReactComponent as OscarIcon } from './OscarIconSvg.svg';
import { ReactComponent as WeightIcon } from './WeightIconSvg.svg';

import './style.css';

const renderMenu = (entries, action = () => {}) => (
  <Menu
    onClick={({ item, domEvent }) => {
      domEvent.stopPropagation();
      action(item.props.children);
    }}
  >
    {entries.map((entry, index) => (
      <Menu.Item key={`menu-item-${index}`}>{entry}</Menu.Item>
    ))}
  </Menu>
);

const renderDate = (_date) => {
  if (!_date) {
    return '-';
  }

  const refDate = moment.utc(_date).tz(process.env.REACT_APP_TIMEZONE);
  const isToday = DateUtils.isToday(refDate);
  const isYesterday = DateUtils.isYesterday(refDate);

  return isToday ? (
    <TimeAgo
      date={refDate.toDate()}
      title={refDate.format('DD.MM.YYYY HH:mm:ss')}
    />
  ) : isYesterday ? (
    'Yesterday'
  ) : (
    refDate.format(DATE_FORMAT)
  );
};

export default ({
  order,
  active,
  atomicUpdate,
  atomicUpdateState,
  size,
  ...props
}) => {
  const statusIsLoading = get(
    atomicUpdateState,
    `${order._id}.status.loading`,
    false
  );
  /* const metodeIsLoading = get(
    atomicUpdateState,
    `${order._id}.leveringstype.loading`,
    false
  ); */
  const hasRest = getters.getAsArray(order, ['values', 'rest']).length > 0;
  const cardSize = size || 'default';

  return (
    <Card
      className={['order-card', 'active', 'card-small'].filter(
        (c) =>
          c === 'order-card' ||
          (c === 'active' && active) ||
          (c === 'card-small' && cardSize === 'small')
      )}
      {...props}
    >
      <Row>
        {hasRest && <div className="rest-dot-marker" />}
        <Col xs={17}>
          <Col xs={24} className="custName">
            {get(OrderHelper.customer(order, { name: '-' }), 'name', '-')}
            {OrderHelper.hasGiftWrappings(order) && (
              <Icon type="gift" style={{ marginLeft: '.2rem' }} />
            )}
          </Col>
          <Col xs={24}>
            <Tag>{get(order, 'values.number', '-')}</Tag>
            <span className="date">
              {renderDate(get(order, 'values.ordreDato', undefined))}
            </span>
          </Col>
          <Col xs={24} className="status-row" style={{ marginTop: '.5rem' }}>
            <Dropdown
              className="cliquable"
              overlay={renderMenu(
                [
                  'Ny',
                  'Pakkes',
                  'Pakket',
                  'Sendt',
                  'Levert',
                  'Kanselert',
                  'Rest',
                  'Snooze',
                ],
                (status) => {
                  const shouldTriggerEmailFlow =
                    status === 'Levert' &&
                    get(order, 'values.leveringstype', 'RFID') ===
                      'Kaffekruset';
                  atomicUpdate({
                    id: get(order, '_id', 0),
                    key: 'status',
                    value: status,
                    updateWc: true,
                    // Trigger kaffekruset flow
                    // Send email and set order as completed
                    emailFlow: shouldTriggerEmailFlow,
                    recalculateOrder: true,
                    setSendtDato: status === 'Sendt',
                    sku: get(order, 'values.number', false),
                  });
                }
              )}
              placement="bottomCenter"
              trigger="click"
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                size="small"
                loading={statusIsLoading}
                style={{
                  background: '#db8556',
                  borderColor: '#db8556',
                  color: '#fff',
                  marginRight: '8px',
                  fontSize: '.7rem',
                }}
              >
                {statusIsLoading
                  ? 'Saving...'
                  : get(order, 'values.status', '-')}
              </Button>
            </Dropdown>

            {/* <Dropdown
              className="cliquable"
              overlay={renderMenu(
                ['RFID', 'Sporingspakke', 'Kaffekruset'],
                (metode) => {
                  atomicUpdate({
                    id: get(order, '_id', 0),
                    key: 'leveringstype',
                    value: metode,
                    updateWc: false,
                    sku: get(order, 'values.number', false),
                  });
                }
              )}
              placement="bottomCenter"
              trigger="click"
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                size="small"
                loading={metodeIsLoading}
                style={{
                  background: '#263645',
                  borderColor: '#263645',
                  color: '#fff',
                  marginRight: '8px',
                  fontSize: '.7rem',
                }}
              >
                {metodeIsLoading
                  ? 'Saving...'
                  : get(order, 'values.leveringstype', '-')}
              </Button>
                </Dropdown> */}
          </Col>
        </Col>
        <div style={{ float: 'right' }}>
          <Col xs={24}>
            <div className="weight">
              <WeightIcon className="weight-icon" />
              <div className="weight-value">
                ~
                {(parseFloat(get(order, 'values.vekt', '0') || '0')).toFixed(2) / 1000}
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div className="weight">
              <OscarIcon className="weight-icon" />
              <div className="weight-value">
                x{' '}
                {sumBy(
                  Object.values(get(order, 'values.varelinjer', { test: 0 })),
                  // eslint-disable-next-line eqeqeq
                  (o) => parseInt((get(order, 'values.status', '') !== 'Ny' && o.delivered != null && o.delivered != undefined) ? o.delivered : o.amount, 10)
                )}
              </div>
              <div style={{ clear: 'both' }} />
            </div>
            <div className="weight">
              <MoneyIcon className="weight-icon" />
              <div className="weight-value">
                {parseFloat(get(order, 'values.totalt', '0.00')).toFixed(2)}
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          </Col>
        </div>
      </Row>
    </Card>
  );
};
