/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import {
  all,
  put,
  takeLatest,
  fork,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { get, isEmpty } from 'lodash-es';
import moment from 'moment';

import handleError from './handleError';

import {
  ORDER_WHOLE_SAVE_START,
  ORDER_WHOLE_SAVE_SUCCESS,
  loadOrders,
  ORDER_KPI_REQUEST_START,
  ORDER_KPI_REQUEST_SUCCESS,
  ORDER_KPI_REQUEST_FAILED,
  ATOMIC_UPDATE_START,
  ATOMIC_UPDATE_END,
  ORDER_STATS_START,
  ORDER_STATS_END,
  ORDER_SET,
} from '../ActionsAndReducers/Orders';
import { ACTIVITIES_FETCH_START } from '../ActionsAndReducers/Activities';
import { STATISTICS_FETCH_START } from '../ActionsAndReducers/Statistics';
import Api from '../../api/Methods';
import { formatActivtyFromChange } from '../../helpers/Activities';

function sanitizeDates(_payload) {
  const payload = { ..._payload };
  Object.keys(payload).map((key) => {
    if (moment.isMoment(payload[key])) {
      payload[key] = payload[key].format('Y-M-D');
    }
  });

  return payload;
}

// function makeDotNotation(payload) {
//   const _payload = {};
//
//   Object.keys(payload).map((key) => {
//     _payload[`values.${key}`] = payload[key];
//   });
//
//   return _payload;
// }

function* saveDatas() {
  yield takeLatest(
    ORDER_WHOLE_SAVE_START,
    function* ({ payload, id, pagination, wcUpdate, recalculate }) {
      const valuesToSave = sanitizeDates(payload);
      const results = yield call(
        Api.updateOrderNoCb,
        id,
        valuesToSave,
        wcUpdate,
        false,
        recalculate
      );
      yield put({ type: ORDER_WHOLE_SAVE_SUCCESS, results });
      // refetch the current datas
      yield put(loadOrders(pagination));
    }
  );
}

function* loadKpis() {
  yield takeLatest(ORDER_KPI_REQUEST_START, function* () {
    try {
      const results = yield all([
        Api.getOrderKpis({
          type: 'volum',
          compare: 'yesterday',
          start: moment().format('Y-MM-DD'),
          end: moment().format('Y-MM-DD'),
        }),
        Api.getOrderKpis({
          type: 'antall',
          compare: 'yesterday',
          start: moment().format('Y-MM-DD'),
          end: moment().format('Y-MM-DD'),
        }),
        Api.getOrderKpis({
          type: 'volum',
          compare: 'last_year',
          start: moment().startOf('month').format('Y-MM-DD'),
          end: moment().endOf('day').format('Y-MM-DD'),
        }),
        Api.getOrderKpis({
          type: 'antall',
          compare: 'last_year',
          start: moment().startOf('month').format('Y-MM-DD'),
          end: moment().endOf('day').format('Y-MM-DD'),
        }),
      ]);

      yield put({
        type: ORDER_KPI_REQUEST_SUCCESS,
        payload: {
          daily: {
            current_volum: get(results, '[0].data.current', 0),
            last_volum: get(results, '[0].data.compare', 0),
            current_antall: get(results, '[1].data.current', 0),
            last_antall: get(results, '[1].data.compare', 0),
          },
          monthly: {
            current_volum: get(results, '[2].data.current', 0),
            last_volum: get(results, '[2].data.compare', 0),
            current_antall: get(results, '[3].data.current', 0),
            last_antall: get(results, '[3].data.compare', 0),
          },
        },
      });
    } catch (e) {
      handleError(e);
      yield put({
        type: ORDER_KPI_REQUEST_FAILED,
      });
    }
  });
}

function* handleAtomicUpdates() {
  yield takeEvery(ATOMIC_UPDATE_START, function* ({ type, ...props }) {
    try {
      const payload = {
        $set: {
          [`values.${props.key}`]: props.value,
        },
      };
      // handle sendt dato
      if (get(props, ['setSendtDato'], false)) {
        payload.$set['values.sendtDato'] = moment().utc().toISOString();
      }

      const newOrder = yield Api.updateOrderNoCb(
        props.id,
        payload,
        props.updateWc,
        get(props, 'emailFlow', false),
        get(props, ['recalculateOrder'], false)
      );

      yield put({
        type: ORDER_SET,
        id: newOrder.data._id,
        value: newOrder.data,
      });

      const sku = get(props, 'sku', false);
      if (sku !== false) {
        const username = get(
          JSON.parse(localStorage.getItem('user_object')),
          'user.username',
          ''
        );
        const activity = {
          creator: username,
          date: moment().utc().toISOString(),
          order_sku: sku,
          activity: formatActivtyFromChange(`values.${props.key}`, props.value),
        };
        yield Api.createActivity(activity);
        // refetch the activities for this order
        yield put({ type: ACTIVITIES_FETCH_START, sku });
      }

      // create activity also
    } catch (e) {
      handleError(e);
    } finally {
      yield put({ type: STATISTICS_FETCH_START });
      yield put({ type: ATOMIC_UPDATE_END, ...props });
    }
  });
}

function* handleStats() {
  yield takeLatest(
    ORDER_STATS_START,
    function* ({ dateRef, dateEnd, monthStart }) {
      try {
        const _r = yield Api.getOrdersStats(dateRef, dateEnd, monthStart);
        const results = _r.data[0] || {};
        yield put({
          type: ORDER_STATS_END,
          payload: isEmpty(results)
            ? {
                idag: {},
                mnd: {},
              }
            : results,
        });
      } catch (e) {
        handleError(e);
        yield put({ type: ORDER_STATS_END, payload: {} });
      }
    }
  );
}

export default function* orderSaga() {
  yield all([
    fork(saveDatas),
    fork(loadKpis),
    fork(handleAtomicUpdates),
    fork(handleStats),
  ]);
}
