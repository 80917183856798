import React from 'react';
import { MOBILE_SCREEN_BREAKPOINT } from '../../constants/defaultValues';

class ResponsiveLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({ width: window.innerWidth });
  }

  isMobile() {
    const { width } = this.state;
    return width <= MOBILE_SCREEN_BREAKPOINT;
  }
}

export default ResponsiveLayout;