/* eslint-disable func-names */
import { all, put, takeLatest, fork } from 'redux-saga/effects';
import { get } from 'lodash-es';

import Api from '../../api/Methods';
import {
  TR_REQUEST_START,
  TR_REQUEST_END,
} from '../ActionsAndReducers/TableReports';
import handleError from './handleError';

function* handleRequest() {
  yield takeLatest(TR_REQUEST_START, function* ({ type, ...props }) {
    try {
      const results = yield all(
        get(props, 'periods', []).map((p) =>
          Api.httpGetTableReports(p.start, p.end)
        )
      );

      yield put({
        type: TR_REQUEST_END,
        payload: results.map((r) => r.data),
      });
    } catch (e) {
      handleError(e);
      yield put({ type: TR_REQUEST_END, payload: null });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(handleRequest)]);
}
