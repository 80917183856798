/* eslint-disable no-console,import/prefer-default-export */
import { get } from 'lodash-es';
import { notifyInfo } from '../helpers/Notify';
import realtimeClient from './client';

// reload customers, orders and statistics depending on realtime events type
const _reloadDatas = (reloadCustomers, reloadOrders, reloadStatistics) => ({
  type,
  data,
}) => {
  switch (type) {
    case 'create':
      notifyInfo(
        'New Order !',
        `${get(data, 'values.number', 'Order')} has been created`
      );
      reloadOrders();
      break;

    case 'update':
      // only reload if not on the orders page
      if (!window.location.href.includes('/dashboard/main')) {
        reloadOrders();
      }
      break;
    default:
      console.log(`Unhandled realtime event [${type}]`);
      break;
  }

  reloadCustomers();
  reloadStatistics(process.env.REACT_APP_TIMEZONE);
};

const reloadOrderAfterUpdate = (
  updateOrderFunc,
  checkOrderExistsFunc,
  loadStatsFunc
) => ({ type, data, id }) => {
  const orderExistsInRedux = checkOrderExistsFunc(id);

  switch (type) {
    case 'update':
      if (orderExistsInRedux) {
        updateOrderFunc(id, data);
        notifyInfo(
          'Update',
          `${get(data, 'values.number', 'Order')} has been updated`
        );
      }
      if (typeof loadStatsFunc === 'function')
        loadStatsFunc(process.env.REACT_APP_TIMEZONE);
      break;

    default:
      if (type !== 'create') {
        console.log(`[EVENT_NOT_HANDLED]:(${type})`, data);
      }
      break;
  }
};

const handleGeneralFlowEffect = (
  loadCustomers,
  loadOrders,
  loadStatistics,
) => () => {
  realtimeClient.addListener({
    name: 'global_realtime',
    channel: '/orders',
    action: _reloadDatas(loadCustomers, loadOrders, loadStatistics),
  });

  return () => {
    console.log('REMOVE GENERAL EFFECT');
    realtimeClient.removeListener('global_realtime');
  };
};

const handleInvoiceRealtime = (
  loadInvoices
) => () => {
  realtimeClient.addListener({
    name: 'invoices_realtime',
    channel: '/invoices',
    action: ({type, data}) => {
      if(type === "create") {
        notifyInfo(
          'New Invoice!',
          `Order invoices ${get(data, 'fakturanummer', '')} has been created`
        );
        loadInvoices();
      }
    }
  });
  return () => {
    console.log('REMOVE GENERAL EFFECT');
    realtimeClient.removeListener('invoices_realtime');
  };
}

export default {
  handleGeneralFlowEffect,
  reloadOrderAfterUpdate,
  removeListener: (key) => realtimeClient.removeListener(key),
  handleInvoiceRealtime,
};
