/* eslint-disable react/jsx-props-no-spreading,max-len */
import React, { useState } from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import {
  RadialChart,
  makeVisFlexible,
  Hint,
  DiscreteColorLegend,
} from 'react-vis';
import { isEmpty } from 'lodash-es';
import '../../../node_modules/react-vis/dist/style.css';
import NumbersHelper from '../../helpers/General/Numbers';

const FlexRadialChart = makeVisFlexible(RadialChart);
const getFullLabelName = (shortName) => {
  switch (shortName) {
    case 'wt':
      return 'Willow Tree';
    case 'design':
      return 'Design Letters';
    case 'sogne':
      return 'Søgne';
    case 'innpakking':
      return 'Innpakking';
    case 'amundsen':
      return 'Amundsen';
    case 'nvrlnd':
      return 'NVRLND';
    default:
      return shortName;
  }
};
const noDatas = [
  {
    angle: 1,
    label: 'no datas',
    value: 0,
    color: 'gray',
    opacity: 0.2,
  },
];
const noLegend = [
  {
    title: 'Empty',
    color: 'gray',
    val: 1,
  },
];
const pStyle = { paddingBottom: 0, display: 'block' };
const getColorForData = (label) => {
  switch (label) {
    case 'wt':
      return '#e8ccbd';
    case 'design':
      return '#263644';
    case 'sogne':
      return '#1b8efa';
    case 'innpakking':
      return '#46870c';
    case 'amundsen':
      return '#5357b2';
    case 'nvrlnd':
      return '#ed4532';
    case 'no datas':
      return 'gray';
    default:
      return '#db8556';
  }
};

const getLegendDatas = (datas, otherDatas, compareDatas, compareOtherDatas) => {
  let res = Array.from([]);
  const d = { ...datas };
  const o = { ...otherDatas };
  const cd = { ...compareDatas };
  const cod = { ...compareOtherDatas };
  const getDiffPercentage = (from, to) => {
    const _from = parseFloat(from);
    const _to = parseFloat(to);
    const diff = _from - _to;

    return {
      value: Math.abs((diff / _to) * 100).toFixed(0),
      positive: diff < 0,
    };
  };
  const renderDiffText = (diff) => (
    <span style={{ color: diff.positive ? 'green' : 'red' }}>
      {diff.positive ? '+' : '-'}
      {diff.value}%
    </span>
  );

  res = isEmpty(d)
    ? Array.from(noLegend)
    : Object.entries(d).map(([key, val]) => {
        const current = val.toFixed(0);
        const before = (cd[key] || 0).toFixed(0);
        const diff = getDiffPercentage(before, current);

        return {
          title: (
            <span>
              {getFullLabelName(key)} {current} {renderDiffText(diff)}
            </span>
          ),
          color: getColorForData(key),
          val,
        };
      });

  const currentFrakt = (o.frakt.total || 0).toFixed(0);
  const beforeFrakt = (cod.frakt.total || 0).toFixed(0);
  const diffFrakt = getDiffPercentage(beforeFrakt, currentFrakt);

  res.push({
    title: (
      <span>
        Frakt {currentFrakt} {renderDiffText(diffFrakt)}
      </span>
    ),
    color: getColorForData('Frakt'),
    val: o.frakt.total || 0,
  });
  const cleanResults = res
    .filter((v) => v.val > 0)
    .sort((a, b) => b.val - a.val);

  return cleanResults;
};

const Pie = ({
  loading,
  datas,
  otherDatas,
  size,
  compareDatas,
  compareOtherDatas,
  ...props
}) => {
  const [hintValue, setHintValue] = useState(false);

  const getRenderableDatas = () => {
    const results = isEmpty(datas)
      ? noDatas
      : Object.entries(datas).map(([key, val]) => ({
          angle: val,
          label: key,
          value: val,
          color: getColorForData(key),
        }));

    // add frakt to datas
    results.push({
      angle: otherDatas.frakt.total || 0,
      label: 'Frakt',
      value: otherDatas.frakt.total || 0,
      color: getColorForData('Frakt'),
    });

    return results.filter((val) => val.angle > 0);
  };
  const getTotalSold = () => {
    const renderableDatas = getRenderableDatas();
    return renderableDatas.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.value || 0),
      0
    );
  };

  const getPercentaged = (val) =>
    (((val || 0) / NumbersHelper.guardDivisor(getTotalSold())) * 100).toFixed(
      2
    );

  const legendDatas = getLegendDatas(
    datas,
    otherDatas,
    compareDatas,
    compareOtherDatas
  );
  const legendProps = { items: legendDatas };
  if (size !== 'small') {
    legendProps.height = '125px';
  }

  return (
    <Col {...props}>
      <Card
        style={{
          width: '100%',
          height: 143,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={['kpi-item', loading ? 'loading' : false, 'responsive-body']
          .filter((c) => Boolean(c))
          .join(' ')}
      >
        <Row style={{ height: '100%' }}>
          <Col xs={12} style={{ height: '100%' }}>
            <div
              style={{
                height:
                  legendDatas.length < 3 && size === 'small' ? '143px' : '100%',
                width: '100%',
              }}
              className="pie-container"
            >
              <FlexRadialChart
                innerRadius={40}
                radius={65}
                animation
                colorType="literal"
                data={getRenderableDatas()}
                onValueMouseOver={(v) =>
                  v.label === 'no datas' ? false : setHintValue(v)
                }
                onSeriesMouseOut={() =>
                  hintValue !== false ? setHintValue(false) : false
                }
              >
                {hintValue !== false && (
                  <Hint
                    style={{ zIndex: 15 }}
                    value={{
                      Products: hintValue.label,
                      amount: hintValue.value,
                    }}
                  >
                    <div
                      style={{
                        background: 'black',
                        opacity: '.7',
                        padding: '5px 20px',
                        color: 'white',
                        borderRadius: '5px',
                        textAlign: 'left',
                      }}
                    >
                      <span style={{ ...pStyle }}>
                        <strong>{getFullLabelName(hintValue.label)}</strong>
                      </span>
                      <span style={{ ...pStyle }}>
                        Beløp kr{' '}
                        {Math.round(hintValue.value || 0).toLocaleString()}
                      </span>
                      <span style={{ ...pStyle }}>
                        {/* eslint-disable-next-line no-mixed-operators */}
                        {getPercentaged(hintValue.value)}
                        {'%'}
                      </span>
                    </div>
                  </Hint>
                )}
              </FlexRadialChart>
            </div>
          </Col>
          <Col xs={12}>
            <DiscreteColorLegend {...legendProps} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

Pie.defaultProps = {
  datas: [],
  loading: false,
  otherDatas: {},
};

export default Pie;
