/* eslint-disable import/prefer-default-export,camelcase */
import Axios from 'axios';
import Moment from 'moment';
import QueryHelper from '../helpers/query';
import client from '../helpers/HttpClient';
import { _getColumnsPromiseOnly } from './customers';

export const _getOrdersPromiseOnly = (
  opts = {
    limit: 50,
    offset: 0,
    filters: {},
    sorters: {},
  }
) => {
  const query =
    `api/orders_optimized?_limit=${opts.limit}` +
    `&_skip=${opts.offset}` +
    `${QueryHelper.buildFilters(opts.filters)}` +
    `${QueryHelper.buildSorters(opts.sorters)}`;

  return client.get(query);
};

export const _getOrders = (cb) =>
  _getOrdersPromiseOnly()
    .then(({ data }) => {
      if (cb && typeof cb === 'function') {
        cb(null, data);
      }
    })
    .catch(cb);

export const _getOrdersAndColumns = (cb, opts = { limit: 50, offset: 0 }) =>
  Axios.all([_getOrdersPromiseOnly(opts), _getColumnsPromiseOnly()])
    .then(
      Axios.spread((orders, columns) => {
        cb(null, orders, columns);
      })
    )
    .catch(cb);

export const updateOrder = (id, datas, cb) =>
  client
    .put(`api/orders/${id}`, datas)
    .then(({ data }) => {
      cb(null, data);
    })
    .catch(cb);

export const updateOrderNoCb = (
  id,
  datas,
  woocommerce_update = false,
  emailflow = false,
  recalculate = false
) => {
  client.setDefaultHeader('X-Realtime-client', window.rt_clientId || '');
  return client.put(
    `api/orders/${id}?woocommerce_update=${
      woocommerce_update ? 1 : 0
    }&email_flow=${emailflow ? 1 : 0}&recalculate=${recalculate ? 1 : 0}`,
    datas
  );
};

export const getOrderKpis = ({ type, start, end, compare }) => {
  const utcStart = Moment(start).startOf('day').utc().toISOString();
  const utcEnd = Moment(end).endOf('day').utc().toISOString();

  return client.get(
    `api/report/kpi?start=${utcStart}&end=${utcEnd}&type=${type}&compare=${compare}`
  );
};

export const printDocuments = (id) =>
  client.post(`api/orders/print_documents/${id}?new=0`);

export const findOneOrder = (id) => client.get(`api/orders/${id}`);

export const getOrdersStats = (dateRef, dateEnd, monthStart) =>
  client.get(
    `api/orders/stats?start=${dateRef}&end=${dateEnd}&month_start=${monthStart}`
  );

export const httpUpdateAmounts = (id, body) =>
  client.post(`api/orders/amounts/${id}`, body);

export const httpEditOrderLines = (payload) =>
  client.post('api/orders/editOrderLines', payload);

export const httpDeleteOrderLine = (payload) =>
  client.post('api/orders/editOrderLines?delete=1', payload);

export const httpCreateOrder = (payload) =>
  client.post('api/orders/create-from-payload', payload);

export const httpFetchOrderStatuses = () =>
  client.get('api/orders/countstatus?all=true');
