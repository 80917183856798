/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React from 'react';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import Pagination from 'antd/es/pagination';
import Tag from 'antd/es/tag';
import { get } from 'lodash-es';
import moment from 'moment';

import MultiSearch from '../components/MultiSearch';
import { ReactComponent as BoxIcon } from '../components/CardLayout/box.svg';
import { ReactComponent as MoneyIcon } from '../components/OrderCard/money.svg';
import CardTile from '../components/CardLayout/CardTile';
import CustomerStats from '../components/CustomerStats/CustomerStats';

const renderMixin = (Base) =>
  class extends Base {
    renderKpisAndDetails(items) {
      const { configFilters, saveFilter, isLoading, _loadCustomers, pagination } = this.props;
      const { startAt, endAt } = configFilters;
      const rangeDate = [startAt ? moment(startAt) : moment().subtract(1, 'months').startOf('month'), endAt ? moment(endAt) : moment().subtract(1, 'months').endOf('month')];
      const setRange = (range) => {
        const [_startAt, _endAt] = range;
        saveFilter('customers', 'startAt', _startAt.format('MM-DD-YYYY'));
        saveFilter('customers', 'endAt', _endAt.format('MM-DD-YYYY'));
        _loadCustomers({
          limit: pagination.limit,
          offset: 0,
          filters: { ...pagination.filters, startAt: _startAt.format('MM-DD-YYYY'), endAt: _endAt.format('MM-DD-YYYY') },
          sorters: {
            ...(configFilters.sorters || {}),
          },
        });
      }
      const dataSource = items.map((item) => ({
        key: item.id,
        customer: get(item, 'values.name', ''),
        orderAmounts: parseFloat(get(item, 'values.ordersEksMvaAmount', "0")).toFixed(2)
      }))
      return (
        <>
          <CustomerStats
            rangeDate={rangeDate}
            setRange={setRange}
            isLoading={isLoading}
            dataSource={dataSource}
            desktopView={!this.isMobile()}
          />
        </>);
    }

    renderPaginationCard() {
      const {
        isLoading,
        onExport,
        _requestExportData,
        pagination,
        total,
        _loadCustomers,
        configFilters,
      } = this.props;

      return (
        <Card
          className="card-pagination"
          style={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
            zIndex: 9,
            boxShadow: '#ccc 0px 3px 3px',
          }}
        >
          <Pagination
            defaultCurrent={7}
            total={total || 0}
            pageSize={(pagination || { limit: 50 }).limit}
            // size="small"
            current={pagination.offset / pagination.limit + 1}
            onChange={(page, pageSize) => {
              _loadCustomers({
                filters: { _search: configFilters.search, startAt: configFilters.startAt, endAt: configFilters.endAt },
                sorters: { ...configFilters.sorters },
                limit: pageSize,
                offset: (page - 1) * pageSize,
              });
            }}
          />
          {!isLoading ? (
            <Button
              type="default"
              shape="circle"
              icon={onExport ? 'loading' : 'download'}
              style={{ boxShadow: '#00000069 2px 3px 4px' }}
              onClick={() => _requestExportData('customers', { 
                ...(configFilters.startAt ? { startAt: configFilters.startAt } : {}),
                ...(configFilters.endAt ? { endAt: configFilters.endAt } : {}),
              })}
              disabled={onExport}
            />
          ) : null}
        </Card>
      );
    }

    handleCustomerSearch(val, sorters = null) {
      const {
        sider2IsVisible,
        hideSider2,
        pagination,
        _loadCustomers,
        saveFilter,
        configFilters,
      } = this.props;
      // Close sider if revealed
      if (sider2IsVisible) hideSider2();

      saveFilter('customers', 'search', val);
      // Do not persist in redux if sorter is null (it means that the search is concerning the omni search)
      if (sorters !== null) saveFilter('customers', 'sorters', sorters);

      _loadCustomers({
        limit: pagination.limit,
        offset: 0,
        filters: {
          ...pagination.filters,
          _search: encodeURIComponent(val),
        },
        sorters: {
          // If sorter is null (search from omnisearch), take the sorters saved in redux so result is correct
          // because the frontend will always show sorters from redux
          ...(sorters === null ? configFilters.sorters : {}),
          // Add the sorter from param when the value is not null because it means that user changes the sorting
          ...(sorters || {}),
        },
      });
    }

    /* renderDateFilter() {
      const { isLoading, configFilters, _loadCustomers, pagination, saveFilter } = this.props;

      const currentYear = new Date().getFullYear();

      const pastYears = Array.from({ length: 3 }, (_, index) => currentYear - index - 1).sort((a, b) => a - b);
      const futureYears = Array.from({ length: 1 }, (_, index) => currentYear + index + 1);

      const yearsList = [
        ...pastYears.map(year => ({ value: year, label: year.toString() })),
        { value: currentYear, label: currentYear },
        ...futureYears.map(year => ({ value: year, label: year.toString(), disabled: true }))
      ];

      return (
        <Col xs={24}>
          <Select
            id="filterDateValue"
            disabled={isLoading}
            style={{ width: '100%', marginTop: '2%' }}
            placeholder="Select a year"
            allowClear
            onSelect={(year) => {
              saveFilter('customers', 'year', year);
              _loadCustomers({
                limit: pagination.limit,
                offset: 0,
                filters: { ...pagination.filters, _year: year },
                sorters: {
                  ...(configFilters.sorters || {}),
                },
              });
            }}
          >
            {yearsList.map((y) => (<Select.Option key={y.value} value={y.value} disabled={y.disabled}>{y.label}</Select.Option>))}
          </Select>
        </Col>
      );
    } */

    renderSorters() {
      const { isLoading, configFilters } = this.props;

      return (
        <>
          <Col xs={24}>
            <Select
              id="sortValue"
              style={{ width: '100%' }}
              defaultValue={get(configFilters, ['sorters', 'order'], null)}
              disabled={isLoading}
              onSelect={(val) => {
                // const searchValue = document.getElementById('searchValue').value;
                const sorters =
                  val !== null
                    ? {
                      field: 'ordersAmount',
                      order: val,
                    }
                    : {};
                this.handleCustomerSearch(
                  get(configFilters, ['search'], null),
                  sorters
                );
              }}
            >
              <Select.Option value={null}>Sort by date created</Select.Option>
              <Select.Option value="ascend">
                Sort by Order Volum &#9650;
              </Select.Option>
              <Select.Option value="descend">
                Sort by Order Volum &#9660;
              </Select.Option>
            </Select>
          </Col>
        </>
      );
    }

    renderSideHeaderCard() {
      const {
        isLoading,
        filtersShown,
        showCreateModal,
        configFilters,
      } = this.props;

      return (
        <MultiSearch
          renderActions={() => (
            <Button
              onClick={showCreateModal}
              icon="plus"
              type="primary"
              block
              style={{ marginBottom: '.5rem' }}
            >
              Add new customer
            </Button>
          )}
          handleOnEnterPress={(e) => {
            e.preventDefault();
            const searchValue = document.getElementById('searchValue').value;
            this.handleCustomerSearch(searchValue);
          }}
          handleOnSearch={this.handleCustomerSearch.bind(this)}
          isMobile={this.isMobile()}
          filtersShown={filtersShown}
          loading={isLoading}
          placeholder="Navn | Epost | Kunde Id | Adresse"
          renderExtended={this.renderSorters.bind(this)}
          defaultSearchValue={get(configFilters, ['search'], '')}
        />
      );
    }

    renderCardMain(item) {
      return (
        <>
          <Col xs={24}>
            <Tag>{get(item, 'values.ordersLevel', '-')}</Tag>
          </Col>
          <Col
            xs={24}
            style={{ color: '#db8556', paddingTop: '6px', fontWeight: 'bold' }}
          >
            {get(item, 'values.poststed', '-')}
          </Col>
        </>
      );
    }

    renderCardSecondary(item) {
      return (
        <Col xs={24}>
          <div className="weight">
            <BoxIcon className="weight-icon" />
            <div className="weight-value">
              x {get(item, 'values.ordersNumber', 0)}
            </div>
          </div>
          <div className="weight">
            <MoneyIcon className="weight-icon" />
            <div className="weight-value">
              {parseFloat(get(item, 'values.ordersAmount', "0")).toFixed(2)}
            </div>
          </div>
        </Col>
      );
    }

    renderCards(_items) {
      const { showSider2, siderData, sider2IsVisible, hideSider2 } = this.props;
      const orderIsVisibleInSider = (item) =>
        get(siderData, '_id', 0) === item.id && sider2IsVisible;

      return (
        <>
          {_items.map((item, index) => (
            <CardTile
              item={item}
              active={orderIsVisibleInSider(item)}
              key={`customer-tile-${index}`}
              title={get(item, 'values.name', 'UNKNOWN')}
              renderMain={this.renderCardMain.bind(this)}
              renderSecondary={this.renderCardSecondary.bind(this)}
              onClick={() => {
                if (orderIsVisibleInSider(item)) {
                  return hideSider2();
                }
                return showSider2('customer', { _id: item.id, ...item });
              }}
            />
          ))}
        </>
      );
    }
  };

export default renderMixin;
