/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import history from '../../helpers/history';

export default ({ icon, label, path, ...props }) => {
  const url = window.location.href;
  const _class = ['podio-menu-item-container'];
  if (url.includes(path)) {
    _class.push('active');
  }

  return (
    <div
      className={_class.join(' ')}
      onClick={() => history.push(path)}
      {...props}
    >
      <div className="podio-menu-item-icon">{icon}</div>
      <div className="podio-menu-item-label">{label}</div>
    </div>
  );
};
