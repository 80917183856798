/* eslint-disable import/prefer-default-export */
import Empty from 'antd/es/empty';
import React from 'react';
import OrderTitleSider from './titles/Order.title.sider';
import InvoiceTitleSider from './titles/Invoice.title.sider';
import MobileView from '../OrderDetails/MobileView';
import PdfView from '../OrderDetails/PdfView';
import CustomerForm from '../Forms/CustomerForm';
import ProductsModal from '../ProductsModal';
import { MOBILE_SCREEN_BREAKPOINT } from '../../constants/defaultValues';
import InvoiceContent from '../Invoices/InvoiceContent/InvoiceContent';

const isMobile = () => window.innerWidth <= MOBILE_SCREEN_BREAKPOINT;

export const resolveDrawerCntnt = (props) => {
  let title;
  let content;
  let extraDrawerProps = {};

  switch (props.type) {
    case 'order':
      title = <OrderTitleSider order={props.data} openSider={props.show} />;
      content = isMobile() ? (
        <MobileView order={props.data} />
      ) : (
        <PdfView order={props.data} />
      );
      extraDrawerProps = { width: '100%' };
      break;

    case 'customer':
      title = (
        <span style={{ color: '#db8556', fontWeight: 'bold' }}>
          Kundeinformasjon
        </span>
      );
      content = <CustomerForm datas={props.data} />;
      extraDrawerProps = { width: '100%' };
      break;

    case 'product':
      title = (
        <span style={{ color: '#db8556', fontWeight: 'bold' }}>Produkt</span>
      );
      content = (
        <ProductsModal
          product={props.data}
          noModal
          save={(...args) => {
            props.saveProduct(...args, {
              tableOptions: {...(props.products_table_options || {}) },
              range: props.products_table_range || null,
            });
          }}
        />
      );
      extraDrawerProps = { width: '100%' };
      break;

    case 'invoice':
      title = (
        <InvoiceTitleSider
          invoice={props.data}
          openSider={props.show}
          statusBtnAction={props.data.updateStatus}
        />
      );
      content = <InvoiceContent invoice={props.data} />;
      extraDrawerProps = { width: '100%' };
      break;

    default:
      title = <span>-</span>;
      content = <Empty />;
      break;
  }

  return { title, content, extraDrawerProps };
};
