import update from 'immutability-helper';
import {
  SHOW_STAGE,
  HIDE_STAGE,
  SEARCH_CUSTOMER_START,
  SEARCH_CUSTOMER_END,
  SEARCH_CLEAR,
  SEARCH_PRODUCT_END,
  CREATE_ORDER_START,
  CREATE_ORDER_END,
  SEARCH_PRODUCT_CLEAR,
  PRODUCT_LIST_CLEAR,
  PRODUCT_LIST_SET_PRODUCT,
  PRODUCT_LIST_SET_RESOLVED_PRODUCT,
  PRODUCT_LIST_REMOVE_PRODUCT,
  PRODUCT_LIST_REMOVE_MANY_PRODUCTS,
  UPDATE_INVENTORY_START,
  UPDATE_INVENTORY_END,
} from './actions';
import { defaultState } from './constants';

const staging = (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_STAGE:
      return update(state, {
        visible: { $set: true },
      });

    case HIDE_STAGE:
      return update(state, {
        visible: { $set: false },
        datas: { $set: null },
        products: { $set: [] },
      });

    case SEARCH_CUSTOMER_START:
      return update(state, {
        searchingCustomer: { $set: true },
      });

    case SEARCH_CUSTOMER_END:
      return update(state, {
        searchingCustomer: { $set: false },
        csutomerSearchResults: {
          $set: action.customers.filter((c) => c.email && c.email !== ''),
        },
      });

    case SEARCH_CLEAR:
      return update(state, {
        csutomerSearchResults: { $set: [] },
      });

    case SEARCH_PRODUCT_END:
      return update(state, {
        productsSearchResults: { $set: action.products },
      });

    case SEARCH_PRODUCT_CLEAR:
      return update(state, {
        productsSearchResults: {
          $set:
            action.replaceBy !== ''
              ? [{ navn: action.replaceBy, sku: action.replaceBy }]
              : [],
        },
      });

    case CREATE_ORDER_START:
      return update(state, {
        creatingOrder: { $set: true },
      });

    case CREATE_ORDER_END:
      return update(state, {
        creatingOrder: { $set: false },
      });

    case PRODUCT_LIST_CLEAR:
      return update(state, {
        products: { $set: [] },
      });

    case PRODUCT_LIST_SET_PRODUCT:
    case PRODUCT_LIST_SET_RESOLVED_PRODUCT:
      return update(state, {
        products: {
          [action.index]: { $set: action.line },
        },
      });

    case PRODUCT_LIST_REMOVE_PRODUCT:
      return update(state, {
        products: {
          $splice: [[action.index, 1]],
        },
      });

    case PRODUCT_LIST_REMOVE_MANY_PRODUCTS:
      return update(state, {
        products: {
          $set: state.products.filter((p) => {
            const fKey = Object.keys(action.filter).pop();
            return p.product[fKey] !== action.filter[fKey];
          }),
        },
      });

    case UPDATE_INVENTORY_START:
        return update(state, {
          updatingInventory: { $set: true },
        });
  
    case UPDATE_INVENTORY_END:
        return update(state, {
          updatingInventory: { $set: false },
        });

    default:
      return state;
  }
};

export default {
  staging,
};
