/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'antd/es/card';
import Icon from 'antd/es/icon';
// import Spin from 'antd/es/spin';
import Col from 'antd/es/col';

const Compare = ({ loading, title, current, compare, ...props }) => {
  const negativeResult = parseFloat(current) < parseFloat(compare);
  const constantResult = parseFloat(current) === parseFloat(compare);
  const caretIcon = negativeResult ? (
    <Icon type="caret-down" style={{ color: 'red' }} />
  ) : constantResult ? (
    <Icon type="minus" style={{ color: 'grey' }} />
  ) : (
    <Icon type="caret-up" style={{ color: 'green' }} />
  );

  return (
    <Col {...props}>
      {/* <Spin spinning={loading}> */}
      <Card
        style={{
          width: '100%',
          height: 143,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={['kpi-item', loading ? 'loading' : false]
          .filter((c) => Boolean(c))
          .join(' ')}
      >
        <h4
          style={{
            textAlign: 'center',
            fontWeight: 'bolder',
            fontSize: 12,
            color: 'rgba(0,0,0,.45)',
            // height: '50px',
          }}
        >
          {title}
        </h4>
        <span
          className="big-number"
          style={{
            textAlign: 'center',
            // color: '#3f8600',
            color: '#5a5958',
            fontWeight: 'bolder',
            fontSize: 31,
            marginBottom: 0,
            display: 'block',
          }}
        >
          {Math.round(current).toLocaleString()}
        </span>
        <span style={{ display: 'block' }}>{caretIcon}</span>
        <span
          className="small-number"
          style={{
            textAlign: 'center',
            // color: '#3f8600',
            color: '#5a5958',
            fontSize: 16,
            marginBottom: '3px',
            display: 'block',
          }}
        >
          ({Math.round(compare).toLocaleString()})
        </span>
      </Card>
      {/* </Spin> */}
    </Col>
  );
};

Compare.defaultProps = {
  loading: false,
  title: 'KPI',
  current: 0,
  compare: 0,
};

Compare.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  current: PropTypes.number,
  compare: PropTypes.number,
};

export default Compare;
