import { notification } from 'antd';

export default (error) => {
  let message = '';
  if (error instanceof Error) {
    message = error.message;
  }

  if (typeof error === 'string') {
    message = error;
  }

  notification.error({
    message: 'Error',
    description: message,
    placement: 'bottomRight',
  });
};
