import update from 'immutability-helper';

export const ACTIVITIES_CREATE_START = '@activities/create_start';
export const ACTIVITIES_CREATE_END = '@activities/create_end';
export const ACTIVITIES_FETCH_START = '@activities/fetch_start';
export const ACTIVITIES_FETCH_END = '@activities/fetch_end';

const defaultState = {
  loading: false,
  items: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ACTIVITIES_FETCH_START:
      return update(state, {
        loading: { $set: true },
      });

    case ACTIVITIES_FETCH_END:
      return update(state, {
        loading: { $set: false },
        items: { $set: action.payload },
      });

    default:
      return state;
  }
};

export const reduxFetchActivities = (sku) => ({
  type: ACTIVITIES_FETCH_START,
  sku,
});

export const createActivity = (payload) => ({
  type: ACTIVITIES_CREATE_START,
  payload,
});
