/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Card from 'antd/es/card';
import Col from 'antd/es/col';

const Blank = ({ ...props }) => (
  <Col {...props}>
    <Card
      style={{
        width: '100%',
        height: 143,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="kpi-item"
    />
  </Col>
);

export default Blank;
