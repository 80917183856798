/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { has } from 'lodash-es';

import { updateInventoryAction } from '../../redux/staging/actions';

const noUnresolvedProduct = (product) => has(product.product, '_id');

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateInventories: updateInventoryAction,
    },
    dispatch
  ),
});

const mapStateToProps = ({ staging }) => ({
  loading: staging.creatingOrder,
  products: staging.products || [],
});

const withInventoryUpdate = (Component) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ updateInventories, products, resetAllAndClose, allStates, ...props }) => (
    <Component
      resetAllAndClose={resetAllAndClose}
      {...props}
      updateInventories={() => {
        const payload = (products || []).filter(noUnresolvedProduct).reduce((result, item) => {
          // eslint-disable-next-line no-param-reassign
          result[item.product.sku] = item.amount;
          return result;
        }, {});
        updateInventories({ inventories: { ...payload } }, resetAllAndClose);
      }}
    />
  ));

export default withInventoryUpdate;
