import client from '../helpers/HttpClient';

export const _uploadFile = (datas, progressFunc) =>
  client.post('upload', datas, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressFunc,
  });

export const _removeFile = (fileId) => client.delete(`upload/files/${fileId}`);
