/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal';

import CustomerForm from '../Forms/CustomerForm';
import { toggle_create_customer_modal_action } from '../../redux/app/actions';

const CreateCustomer = ({ visible, toggleModal }) => (
  <Modal
    title="Create Customer"
    visible={visible}
    footer={null}
    destroyOnClose
    width={670}
    onCancel={toggleModal}
  >
    <CustomerForm datas={{}} afterCreate={toggleModal} />
  </Modal>
);

CreateCustomer.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default connect(
  ({ app }) => ({
    visible: app.modals.createCustomer.visible,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        toggleModal: toggle_create_customer_modal_action,
      },
      dispatch
    )
)(CreateCustomer);
